import React, { useState, useEffect } from 'react'
import moment from 'moment-timezone'
import Container from '..'
import GraphicStats from '../../../components/Affiliates/GraphicStats'
import Spinner from '../../../components/Spinner'
import Tops from '../../../components/Tops'
import { connect } from 'react-redux'
import reserveActionTypes from '../../../actions/reserveActionTypes'
import { useSelector } from 'react-redux'

const ComparativeAffiliate = (props) => {
  const { fetchComparatives, user: { token, affiliate: { _id: affiliateId } }  } = props
  const { comparatives: { myTopSales, myTopDestinies, topSales, topDestinies, myConversionRate, conversionRate }, loading } = useSelector(state => state.reservations)
  const [ month, setMonth ] = useState(moment().format('yyyy-MM'))

  const _handleMonth = async (e) => {
    setMonth(e)
    fetchComparatives({ affiliateId, month: e, token })
  }

  useEffect(()=>{
    fetchComparatives({ affiliateId, month, token })
  }, [ month, token, affiliateId, fetchComparatives ])

  return (
    <Container>
      <div className='main-content'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Análisis</h4>
              </div>
            </div>
          </div>
          { loading ?
            <Spinner />          :
            (<div>
              <div className='date-filter row mb-3'>
                <div className='col-md-3'>
                  <div>
                    <input
                      className='form-control'
                      defaultValue={month}
                      max={moment().format('yyyy-MM')}
                      min={moment().format('2020-07')}
                      onChange={({ target: { value } }) => _handleMonth(value)}
                      type='month' />
                  </div>
                </div>
                {/* <div className='col-md-8 text-right'>
                  <div>
                    <button className='btn btn-bg-white w-lg waves-effect waves-light'><i className='fa fa-download text-soft-green' /> Exportar datos a excel</button>
                  </div>
                </div> */}
              </div>
              <div className='row'>
                <div className='col-xl-12'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4 className='card-title mb-4'>Comparativas</h4>
                      <div className='row extra-info'>
                        <div className='col-xl-2'>
                          <span className='graph-info font-weight-bolder'>{myConversionRate}%</span>
                          <p>Tu porcentaje de conversion</p>
                        </div>
                        <div className='col-xl-2'>
                          <span className='graph-info font-weight-bolder'>{conversionRate}%</span>
                          <p>Media de conversion afiliados</p>
                        </div>
                      </div>
                      <GraphicStats month={month} type={'month'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <Tops
                  mainClass={'col-xl-6'} mainTitle={'Mi top ventas'} topsData={myTopSales}
                  topsType={'quantity'} />
                <Tops
                  mainClass={'col-xl-6'} mainTitle={'Top ventas vipealo'} topsData={topSales}
                  topsShowValues={false} topsType={'quantity'} />
              </div>
              <div className='row'>
                <Tops
                  mainClass={'col-xl-6'} mainTitle={'Mi top destinos'} topsData={myTopDestinies}
                  topsType={'clicks'} />
                <Tops
                  mainClass={'col-xl-6'} mainTitle={'Top destinos vipealo'} topsData={topDestinies}
                  topsDataType={'activity'} topsShowValues={false} topsType={'clicks'} />
              </div>
            </div>)
          }
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  reservations: state.reservations,
  user        : state.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    fetchComparatives: (data) => dispatch({ data, type: reserveActionTypes.FETCH_COMPARATIVES })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComparativeAffiliate)
