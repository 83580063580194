import React, { useRef, useState, useEffect } from 'react'
import Container from '..'
import { connect } from 'react-redux'
import actionTypes from '../../../actions/actionTypes'
import Dropzone from '../../../components/Dropzone'
import { api } from '../../../utils'
import Spinner from '../../../components/Spinner'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'
import { hasData } from '../../../utils'
import { validateEmail } from '../../../utils'

const UserPayment = ({ user, ...props }) => {
  const [ status, setStatus ] = useState(user.status)
  const token = user.token
  const [ loading, setLoading ] = useState(false)
  const [ countries, setCountries ] = useState([])
  const [ affiliate, setAffiliate ] = useState(user.affiliate ? user.affiliate : {})
  const [ error, setError ] = useState({ message: '', status: false })
  const [ errorUpload, setErrorUpload ] = useState({ message: '', status: false })
  const [ company, setCompany ] = useState(affiliate.bill || {
    address          : '',
    autoBilling      : false,
    city             : '',
    country          : '',
    currency         : 'EUR',
    documentType     : '',
    documentValue    : '',
    fiscalDocument   : '',
    irpf             : '',
    name             : '',
    newsletter       : false,
    nif              : '',
    paymentMethod    : '',
    paymentMethodData: {
      bank      : '',
      bankHolder: '',
      bankNumber: '',
      email     : ''
    },
    phone       : '',
    region      : '',
    typeBussines: 'company',
    zipcode     : ''
  })

  const { newsletter, autoBilling } = company
  const refSelfEmploye = useRef(null)
  const refOther = useRef(null)
  const refCompany = useRef(null)

  const refCountry = useRef(null)
  const refRegion = useRef(null)
  const refCurrency = useRef(null)

  const refCompanyName = useRef(null)
  const refCompanyCity = useRef(null)
  const refCompanyNif = useRef(null)
  const refCompanyAddress = useRef(null)
  const refCompanyPaymentMethod = useRef(null)
  const refCompanyPhone = useRef(null)
  const refCompanyZipcode = useRef(null)

  const refSelfEmployeName = useRef(null)
  const refSelfEmployeCity = useRef(null)
  const refSelfEmployeNif = useRef(null)
  const refSelfEmployeIrpf = useRef(null)
  const refSelfEmployeAddress = useRef(null)
  const refSelfEmployeZipcode = useRef(null)
  const refSelfEmployePhone = useRef(null)
  const refSelfEmployePaymenthMethod = useRef(null)

  const refDocumentType = useRef(null)
  const refDocumentValue = useRef(null)

  const companyFields = [ refCountry,  refCurrency, refCompanyName,  refCompanyCity, refCompanyNif, refCompanyAddress, refCompanyPaymentMethod,
    refCompanyPhone, refCompanyZipcode, refDocumentType, refDocumentValue ]

  const selfemployeFields = [ refCountry,  refSelfEmployeName, refSelfEmployeCity, refSelfEmployeNif, refSelfEmployeIrpf, refSelfEmployeAddress,
    refSelfEmployeZipcode, refSelfEmployePhone, refSelfEmployePaymenthMethod, refDocumentType, refDocumentValue ]

  const othersFields = [ refCountry ]

  const showMessage = () => {
    return  status === 'UPDATE_USER_SUCCESS' ? <h4 className='text-success text-center'>Usuario actualizado</h4> : null
  }

  useEffect(() => {
    setStatus(user.status)
  }, [ user ])

  // disable msg on first load in case validation is true
  useEffect(() => {
    if(status === 'UPDATE_USER_SUCCESS') setStatus('UPDATE_USER')
    // eslint-disable-next-line
  }, [])

  // event handlers

  const _handleUpdate = async (event) => {
    setError({
      message: '',
      status : false
    })
    event.preventDefault()
    if(validateFields()) {
      console.log('***_handleUpdate paso')
      const updateAffiliate = affiliate
      updateAffiliate.bill = company
      setAffiliate({ ...updateAffiliate, ...affiliate })
      await props.updateUser({ _id: user._id, affiliate, role: user.role, token: user.token })
    }
  }

  const _handleTypeBussines = (type) => {
    setCompany({
      ...company,
      typeBussines: type
    })
    changeForm(type)
  }

  const _handleDocumentCompany = (url) => {
    setCompany({
      ...company,
      fiscalDocument: url
    })
  }
  const _handleChangeCompany = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value
    })
  }

  const _handleFocus = (e) => {
    let elem = e.target.parentNode
    elem.classList.add('active')
  }

  const _handleBlur = (e) => {
    if(e.target.value === '') {
      let elem = e.target.parentNode
      elem.classList.remove('active')
    }
  }

  const _handleChangeNewsletter = () => {
    setCompany({
      ...company,
      newsletter: !newsletter
    })
  }

  const _handleAutoBilling = () => {
    setCompany({
      ...company,
      autoBilling: !autoBilling
    })
  }

  const _handleChangeIRPF = (e) => {
    setCompany({
      ...company,
      irpf: e.target.value
    })
  }

  const _handleChangePaymenMethod = (e) => {
    setCompany({
      ...company,
      paymentMethod: e.target.value
    })
  }

  const _handleChangePaymenData = (e, type) => {
    let paymentMethodData = company.paymentMethodData || {
      bank       : '',
      bankCountry: '',
      bankHolder : '',
      bankNumber : '',
      bankSwift  : '',
      email      : ''
    }
    paymentMethodData[type] = e.target.value
    setCompany({
      ...company,
      paymentMethodData
    })
  }

  // validators

  const verifyInputs = (inputs) => {
    let result = true
    inputs.forEach(input => {
      if(input.current)
        if(input.current.value.trim() !== '') {
          input.current.classList.add('b-blue')
        }
        else {
          input.current.classList.add('b-red')
          result = false
        }
    })

    return result
  }

  const validateFields = () => {
    let result = true
    if((company.typeBussines === 'company' || company.typeBussines === 'selfemploye') && company.paymentMethod === 'paypal')
      if(!validateEmail(company.paymentMethodData.email))
        setError({
          message: 'Ups, debe introducir un email válido',
          status : true
        })

    if(company.typeBussines === 'company') {
      cleanInputs(companyFields)
      result = verifyInputs(companyFields)
      if(!result)
        setError({
          message: 'Ups, los campos en rojo son requeridos',
          status : true
        })
    } else if(company.typeBussines === 'selfemploye') {
      cleanInputs(selfemployeFields)
      result = verifyInputs(selfemployeFields)
      if(!result)
        setError({
          message: 'Ups, los campos en rojo son requeridos',
          status : true
        })
    } else if(company.typeBussines === 'other') {
      cleanInputs(othersFields)
      result = verifyInputs(othersFields)
      if(!result)
        setError({
          message: 'Ups, los campos en rojo son requeridos',
          status : true
        })
    }

    return result
  }

  const cleanInputs = () => {
    companyFields.forEach(input => {
      if(input && input.current) {
        input.current.classList.remove('b-blue')
        input.current.classList.remove('b-red')}
    })
    selfemployeFields.forEach(input => {
      if(input && input.current) {
        input.current.classList.remove('b-blue')
        input.current.classList.remove('b-red')
      }
    })
  }

  useEffect(() => {
    const { typeBussines } = company
    if(typeBussines)
      changeForm(typeBussines)
    // eslint-disable-next-line
  }, [])

  const changeForm = (type) => {
    cleanInputs()
    setError({
      message: '',
      status : false
    })
    if(type === 'company') {
      refCompany.current.classList.remove('d-none')
      refOther.current.classList.add('d-none')
      refSelfEmploye.current.classList.add('d-none')
    }
    else if(type === 'selfemploye') {
      refCompany.current.classList.add('d-none')
      refOther.current.classList.add('d-none')
      refSelfEmploye.current.classList.remove('d-none')
    }
    else  if(type === 'other') {
      refCompany.current.classList.add('d-none')
      refOther.current.classList.remove('d-none')
      refSelfEmploye.current.classList.add('d-none')
    }
  }

  useEffect(() => {
    let inputs = document.querySelectorAll('input[type="text"]')

    inputs.forEach(input => {
      if(input.value !== '')
        input.parentNode.classList.add('active')
    })
  }, [ company.typeBussines ])

  useEffect(() => {
    getCountries()
    // eslint-disable-next-line
  }, [ ])

  const getCountries = async () => {
    setLoading(true)
    const data = await api('get', 'countries', 'list', {}, token)
    if(data.success)
      setCountries(data.countries)
    setLoading(false)
  }

  const renderPaymentType = (refPaymentMethod) => (
    <>
      <h1 className='mb-4'><i className='card-icon mr-2' /> Forma de pago</h1>
      <div className='mb-4'>
        <div className='dropdown act-rt'>
          <select
            className='btn btn-bg-white dropdown-toggle text-light-gray'
            name='paymentMethod'
            onChange={(e)=>{_handleChangePaymenMethod(e)}}
            ref={refPaymentMethod}
            value={company.paymentMethod} >
            <option className='dropdown-item' value=''>Selecciona tu forma de pago</option>
            <option className='dropdown-item' value='bank-transfer'>
              Transferencia bancaria
            </option>
            <option className='dropdown-item' value='paypal'>Paypal</option>
          </select>
        </div>
      </div>
    </>
  )

  const renderPayment = () => {
    if(company.paymentMethod && company.paymentMethod === 'paypal')
      return (<div className={`ci-wrapper mb-3 check-field ${hasData(company.paymentMethodData.email) ? 'active' : ''}`}>
        <label htmlFor='paymentEmail'>Email asociado</label>
        <input
          defaultValue={company.paymentMethodData && company.paymentMethodData.email ? company.paymentMethodData.email : ''}
          id='paymentEmail'
          name='paymentEmail'
          onChange={(e)=>{_handleChangePaymenData(e, 'email')}}
          onFocus={(e)=>{_handleFocus(e)}}
          type='text' />
      </div>)
    else if(company.paymentMethod && company.paymentMethod === 'bank-transfer')
      return (<>
        <div className='ci-wrapper mb-3 check-field'>
          <label htmlFor='bankCountry'>País de tu banco</label>
          <input
            defaultValue={company.paymentMethodData && company.paymentMethodData.bankCountry ? company.paymentMethodData.bankCountry : ''}
            id='bankCountry'
            name='bankCountry'
            onChange={(e)=>{_handleChangePaymenData(e, 'bankCountry')}}
            onFocus={(e)=>{_handleFocus(e)}}
            type='text' />
        </div>
        <div className='ci-wrapper mb-3 check-field'>
          <label htmlFor='bankCountry'>Banco</label>
          <input
            defaultValue={company.paymentMethodData && company.paymentMethodData.bank ? company.paymentMethodData.bank : ''}
            id='bank'
            name='bank'
            onChange={(e)=>{_handleChangePaymenData(e, 'bank')}}
            onFocus={(e)=>{_handleFocus(e)}}
            type='text' />
        </div>
        <div className='ci-wrapper mb-3 check-field'>
          <label htmlFor='bankHolder'>Nombre titular cuenta</label>
          <input
            defaultValue={company.paymentMethodData && company.paymentMethodData.bankHolder ? company.paymentMethodData.bankHolder : ''}
            id='bankHolder'
            name='bankHolder'
            onChange={(e)=>{_handleChangePaymenData(e, 'bankHolder')}}
            onFocus={(e)=>{_handleFocus(e)}}
            type='text' />
        </div>
        <div className='ci-wrapper mb-3 check-field'>
          <label htmlFor='bankNumber'>IBAN</label>
          <input
            defaultValue={company.paymentMethodData && company.paymentMethodData.bankNumber ? company.paymentMethodData.bankNumber : ''}
            id='bankNumber'
            name='bankNumber'
            onChange={(e)=>{_handleChangePaymenData(e, 'bankNumber')}}
            onFocus={(e)=>{_handleFocus(e)}}
            type='text' />
        </div>
        <div className='ci-wrapper mb-3 check-field'>
          <label htmlFor='bankSwift'>Swift</label>
          <input
            defaultValue={company.paymentMethodData && company.paymentMethodData.bankSwift ? company.paymentMethodData.bankSwift : ''}
            id='bankSwift'
            name='bankSwift'
            onChange={(e)=>{_handleChangePaymenData(e, 'bankSwift')}}
            onFocus={(e)=>{_handleFocus(e)}}
            type='text' />
        </div>
      </>)
    else
      return null
  }

  const renderTypeDocument = (refData) => {
    if(company.country && company.country === 'España')
      return (
        <div className='ci-wrapper mb-3 check-field'>
          <label htmlFor='company_nif'>NIF español</label>
          <input
            defaultValue={company.nif}
            id='company_nif'
            name='nif'
            onBlur={(e)=>{_handleBlur(e)}}
            onChange={(e)=>_handleChangeCompany(e)}
            onFocus={(e)=>{_handleFocus(e)}}
            ref={refData}
            type='text' />
        </div>
      )
    else
      return (
        <>
          <div className='mb-2'>
            <div className='dropdown act-rt'>
              <select
                className='btn btn-bg-white dropdown-toggle text-light-gray'
                id='dropdownDocumentType'
                name='documentType'
                onChange={(e)=>_handleChangeCompany(e)}
                ref={refDocumentType}
                value={company.documentType}>
                <option className='dropdown-item' value='vat'>Seleccione tipo de documento</option>
                <option className='dropdown-item' value='vat'>Número VAT intracomunitario</option>
                <option className='dropdown-item' value='passport'>Pasaporte</option>
                <option className='dropdown-item' value='document_country'>Documento oficial país de residencia</option>
                <option className='dropdown-item' value='other'>Otro documento</option>
              </select>
            </div>
          </div>
          <div className='ci-wrapper mb-3 check-field'>
            <label htmlFor='companyDocumentValue'>
              {`Documento ${company.documentType === 'vat' ? 'VAT' :
                company.documentType === 'document_country' ? 'país de residencia' :
                  company.documentType === 'passport' ? 'Pasaporte' :
                    ''}`}
            </label>
            <input
              defaultValue={company.documentValue}
              id='companyDocumentValue'
              name='documentValue'
              onBlur={(e)=>{_handleBlur(e)}}
              onChange={(e)=>_handleChangeCompany(e)}
              onFocus={(e)=>{_handleFocus(e)}}
              ref={refDocumentValue}
              type='text' />
          </div>
        </>
      )
  }

  const _handlePhone = (_, __, ___, p) => {
    const phoneArr = p.split(' ')

    const prefix = phoneArr[0]
    const phone = phoneArr.splice(1, phoneArr.length).join('')
    setCompany({
      ...company,
      phone: `${prefix} ${phone}`
    })
  }

  const renderUserPhone = () => (
    <div className='ci-wrapper mb-3 check-field active'>
      <label htmlFor='phone'>Introduce tu número de teléfono</label>
      <PhoneInput
        countryCodeEditable={false}
        disableSearchIcon
        enableSearch
        inputClass={'react-tel-input user-phone '}
        localization={es}
        onChange={_handlePhone}
        placeholder='Telefono'
        searchPlaceholder='País'
        value={`${company.phone}`} />
    </div>
  )

  return (
    <Container>
      <div className='main-content my-billing-data'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Mis datos de pago</h4>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='wrapper'>
              <form action='/' method='POST'>
                <h1 className='mb-4'><i className='icon-c bill-icon mr-2' /> Datos de facturación</h1>
                <div className='biling-data'>
                  {loading ? <Spinner /> :
                    <div className='mb-2'>
                      <div className='dropdown act-rt'>
                        <select
                          className='btn btn-bg-white dropdown-toggle text-light-gray'
                          id='dropdownCountryMenu'
                          name='country'
                          onChange={(e)=>_handleChangeCompany(e)}
                          ref={refCountry}
                          value={company.country}>
                          <option className='dropdown-item' value=''>Selecciona tu país</option>
                          {countries.map((country) => {
                            if(country.name && !country.deleted)
                              return (<option className='dropdown-item' key={country._id} value={country.name}>{country.name}</option>)
                            else
                              return null
                          })}
                        </select>
                      </div>
                    </div>
                  }
                  {company.country && company.country === 'España' ?
                    <div className='mb-4'>
                      <div className='dropdown act-rt'>
                        <select
                          className='btn btn-bg-white dropdown-toggle text-light-gray'
                          id='dropdownRegionMenu'
                          name='region'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>{_handleChangeCompany(e)}}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refRegion}
                          value={company.region} >
                          <option className='dropdown-item' value=''>Selecciona tu región</option>
                          <option className='dropdown-item' value='Comunidad de Madrid'>Comunidad de Madrid</option>
                          <option className='dropdown-item' value='Comunidad valenciana'>Comunidad valenciana</option>
                          <option className='dropdown-item' value='Comunidad de Andalucía'>Comunidad de Andalucía</option>
                          <option className='dropdown-item' value='Península y Baleares'>Península y Baleares</option>
                          <option className='dropdown-item' value='Islas Canareas'>Islas Canareas</option>
                        </select>
                      </div>
                    </div> :
                    null }
                  <div className='mb-4'>
                    <div className='dropdown act-rt'>
                      <select
                        className='btn btn-bg-white dropdown-toggle text-light-gray'
                        id='dropdownCurrencyMenu'
                        name='currency'
                        onBlur={(e)=>{_handleBlur(e)}}
                        onChange={(e)=>{_handleChangeCompany(e)}}
                        onFocus={(e)=>{_handleFocus(e)}}
                        ref={refCurrency}
                        value={company.currency} >
                        <option className='dropdown-item' value=''>Selecciona moneda de pago</option>
                        <option className='dropdown-item' value='EUR'>Euro (EUR)</option>
                        <option className='dropdown-item' value='USD'>Dólares (USD)</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mb-4'>
                    <div className='col-md-4 radios-wrapper'>
                      <div className='cr-wrapper'>
                        <label className='cr-label'>
                          <input
                            data-ref='company' defaultChecked={company.typeBussines === 'company' ? true : false}
                            hidden name='activity'
                            onChange={()=> _handleTypeBussines('company')}
                            type='radio' />
                          <span className='custom-radio' />
                          <span className='text'>Empresa</span>
                        </label>
                      </div>
                    </div>
                    <div className='col-md-4 radios-wrapper'>
                      <div className='cr-wrapper'>
                        <label className='cr-label'>
                          <input
                            data-ref='selfemploye' defaultChecked={company.typeBussines === 'selfemploye' ? true : false}
                            hidden name='activity'
                            onChange={()=> _handleTypeBussines('selfemploye')}
                            type='radio' />
                          <span className='custom-radio' />
                          <span className='text'>Autónomo</span>
                        </label>
                      </div>
                    </div>
                    <div className='col-md-4 radios-wrapper'>
                      <div className='cr-wrapper'>
                        <label className='cr-label'>
                          <input
                            data-ref='other' defaultChecked={company.typeBussines === 'other' ? true : false}
                            hidden name='activity'
                            onChange={()=> _handleTypeBussines('other')}
                            type='radio' />
                          <span className='custom-radio' />
                          <span className='text'>Otros</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='form-fields'>
                    <div id='company' ref={refCompany}>
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='name'>Nombre de la empresa</label>
                        <input
                          id='name'
                          name='name'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>{_handleChangeCompany(e)}}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refCompanyName}
                          type='text'
                          value={company.name} />
                      </div>
                      {renderTypeDocument(refCompanyNif)}
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='city'>Introduce tu ciudad</label>
                        <input
                          id='city'
                          name='city'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>_handleChangeCompany(e)}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refCompanyCity}
                          type='text'
                          value={company.city} />
                      </div>
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='address'>Introduce tu dirección</label>
                        <input
                          id='address'
                          name='address'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>_handleChangeCompany(e)}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refCompanyAddress}
                          type='text'
                          value={company.address} />
                      </div>
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='zipcode'>Introduce tu código postal</label>
                        <input
                          id='zipcode'
                          name='zipcode'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>_handleChangeCompany(e)}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refCompanyZipcode}
                          type='text'
                          value={company.zipcode} />
                      </div>
                      {renderUserPhone()}
                      <label className={`cb-label mt-3 mb-4 ${newsletter ? 'checked' : ''}`} htmlFor='want_offers'>
                        <span className='custom-checkbox'  />
                        <input
                          id='want_offers'
                          onChange={_handleChangeNewsletter}
                          type='checkbox' />
                        Recibir ofertas y novedades de Vipealo
                      </label>
                      {renderPaymentType(refCompanyPaymentMethod)}
                      {renderPayment()}
                    </div>
                    <div className='d-none' id='selfemploye' ref={refSelfEmploye}>
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='name_'>Persona física: Nombre de empresa / del autónomo</label>
                        <input
                          id='name_'
                          name='name'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e) => _handleChangeCompany(e)}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refSelfEmployeName}
                          type='text'
                          value={company.name} />
                      </div>
                      {renderTypeDocument(refSelfEmployeNif)}
                      <div className='ci-wrapper mb-3 check-field'>
                        <div className='dropdown act-rt'>
                          <select
                            className='btn btn-bg-white dropdown-toggle text-light-gray'
                            name='irpf'
                            onChange={(e)=>{_handleChangeIRPF(e)}}
                            ref={refSelfEmployeIrpf}
                            value={company.irpf}>
                            <option className='dropdown-item' value=''>Seleccionar IRPF</option>
                            <option className='dropdown-item' value='15'>15%</option>
                            <option className='dropdown-item' value='7'>7%</option>
                            <option className='dropdown-item' value='0'>0%</option>
                          </select>
                        </div>
                      </div>
                      <p className='cglb-e'>Por favor, anexa un documento  que acredite tu régimen fiscal para la validación.</p>
                      {
                        errorUpload.status ? (<h4 className='text-red'>{errorUpload.message}</h4>) : null
                      }
                      <Dropzone
                        doc={company.fiscalDocument ? company.fiscalDocument : ''}
                        setDoc={_handleDocumentCompany}
                        setError={setErrorUpload} />

                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='city_'>Introduce tu ciudad</label>
                        <input
                          id='city_'
                          name='city'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>{_handleChangeCompany(e)}}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refSelfEmployeCity}
                          type='text'
                          value={company.city} />
                      </div>
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='address_'>Introduce tu dirección</label>
                        <input
                          id='address_'
                          name='address'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>{_handleChangeCompany(e)}}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refSelfEmployeAddress}
                          type='text'
                          value={company.address} />
                      </div>
                      <div className='ci-wrapper mb-3 check-field'>
                        <label htmlFor='zipcode_'>Introduce tu código postal</label>
                        <input
                          id='zipcode_'
                          name='zipcode'
                          onBlur={(e)=>{_handleBlur(e)}}
                          onChange={(e)=>{_handleChangeCompany(e)}}
                          onFocus={(e)=>{_handleFocus(e)}}
                          ref={refSelfEmployeZipcode}
                          type='text'
                          value={company.zipcode} />
                      </div>
                      {renderUserPhone()}
                      <label className={`cb-label cglb-e mt-3 mb-4 ${autoBilling ? 'checked' : ''}`} htmlFor='auto_billing'>
                        <span className='custom-checkbox' />
                        <input
                          id='auto_billing'
                          name='autoBilling'
                          onChange={_handleAutoBilling}
                          type='checkbox'
                          value={autoBilling} />
                        Autofactura
                      </label>
                      {renderPaymentType(refSelfEmployePaymenthMethod)}
                      {renderPayment()}
                    </div>
                    <div className='d-none' id='other' ref={refOther}>
                      <p>¿No eres autónomo ni empresa? No te preocupes, puedes ir acumulando comisiones y utilizarlas para reservar nuestras actividades.</p>
                      <p>Si en algún momento deseas cobrar las comisiones acumuladas, tendrás que darte de alta como autónomo o
                          empresa, para poder emitir factura. El importe acumulado nunca caduca.</p>
                    </div>
                  </div>
                  {error.status ? (<h4 className='text-red'>{error.message}</h4>) : null}
                  { showMessage() }
                  <div className='separator mt-4' />
                  <div className='form-actions pt-3'>
                    <div className='row ml-0 mt-4'>
                      <label className='cb-label mr-2 col-md-6' />
                      <button
                        className='btn btn-primary font-weight-bolder col-md-5'
                        disabled={status === 'UPDATE_USER_REQUEST' ? true : false}
                        onClick={(e)=>{ _handleUpdate(e) }}>
                        {status === 'UPDATE_USER_REQUEST' ? 'Enviando...' : 'Guardar'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch({ type: actionTypes.UPDATE_USER_REQUEST, user })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserPayment)
