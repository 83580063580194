import React, { useState, useEffect } from 'react'
import { api } from '../../utils'
import Body1 from './Body1'
import Body2 from './Body2'
import Body3 from './Body3'
import Body4 from './Body4'
import Lateral1 from './Lateral1'
import Lateral2 from './Lateral2'

const Widget = ({ location: { search: query } }) => {
  const [ listActivities, setListActivities ] = useState([])

  const params = new URLSearchParams(query)
  const cmp = params.get('cmp') || ''
  const aid = params.get('aid') || 1
  const quant = params.get('quant') || 6
  const format = params.get('format') || 'body-140x210'
  const destination = params.get('destination') || undefined
  const country = params.get('country') || undefined
  const hiddeBtn = params.get('hiddeBtn')
  const centerAct = params.get('centerAct')
  const noBgColor = params.get('noBgColor')
  const paramAct = params.get('activities')
  let activities = []
  if(paramAct)
    activities = paramAct.split(',')

  const getActivities = async () => {
    if(activities.length) {
      const params = new URLSearchParams({ activities: activities.join(','), quant })
      const response = await api('get', 'activities/actids', `?${params}`, {}, '')

      if(response.success)
        setListActivities(response.activities)
    }
    else {
      let query = { quant }
      if(destination && country)
        query = { ...query, city: destination, country }
      else
      if(country)
        query = { ...query, country }

      const params = new URLSearchParams(query)
      const response = await api('get', 'activities/most-populars', `?${params}`, {}, '')
      if(response.success)
        setListActivities(response.activities)
    }
  }

  useEffect(() => {
    getActivities()
    // eslint-disable-next-line
  }, [])

  return (
    <div className='designs-popup'>
      <div className='p-4 popup-wrapper'>
        <div className='popup-body'>
          <div className='text-center body-wrapper pl-3' data-mode='desktop'>
            <div className='popup-act ' data-act={`act${format.split('-')[1] || '140x210'}`}>
              <div className={`row ${centerAct ? 'justify-content-center' : ''}`}>
                {
                  listActivities.map(activity =>(
                    format === 'body-140x210' ?
                      <Body1
                        activity={activity}
                        aid={aid}
                        cmp={cmp}
                        key={activity._id} />  :

                      format === 'body-153x105' ?
                        <Body2
                          activity={activity}
                          aid={aid}
                          cmp={cmp}
                          key={activity._id}
                          noBgColor={noBgColor} /> :

                        format === 'body-103x86' ?

                          <Body3
                            activity={activity}
                            aid={aid}
                            cmp={cmp}
                            key={activity._id}
                            noBgColor={noBgColor} /> :

                          format === 'body-141x43' ?
                            <Body4
                              activity={activity}
                              aid={aid}
                              cmp={cmp}
                              key={activity._id} /> :

                            format === 'lateral-115x97' ?
                              <Lateral1
                                activity={activity}
                                aid={aid}
                                cmp={cmp}
                                key={activity._id} />                                      :
                              <Lateral2
                                activity={activity}
                                aid={aid}
                                cmp={cmp}
                                key={activity._id} />
                  ))
                }
              </div>
              {
                !hiddeBtn &&
                <div className='mt-4 text-center popup-footer'>
                  <a href={`https://vipealo.com/?aid=${aid}&cmp=${cmp}`} rel='noopener noreferrer' target='_blank'>Ver todas las actividades</a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Widget
