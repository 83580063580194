export default {
  user: {
    affiliate: {
      bill: {
        address          : '',
        autoBilling      : false,
        city             : '',
        country          : '',
        currency         : 'EUR',
        documentType     : '',
        documentValue    : '',
        fiscalDocument   : '',
        irpf             : '',
        name             : '',
        newsletter       : false,
        nif              : '',
        paymentMethod    : '',
        paymentMethodData: {
          bank      : '',
          bankHolder: '',
          bankNumber: '',
          email     : ''
        },
        phone       : '',
        region      : '',
        typeBussines: 'company',
        zipcode     : ''
      },
      freetour: 1,
      percent : 15
    },
    birthdate       : null,
    city            : null,
    country         : null,
    default_settings: {
      afiliados          : 15,
      afiliados_free_tour: 1
    },
    firstName    : null,
    lastName     : null,
    notifications: [],
    phone        : null,
    settings     : {
      afiliados          : 15,
      afiliados_free_tour: 1
    },
    status: 'logged out',
    token : null
  }
}
