import React from 'react'
import { slugify, getFirstPrice, getOffer } from '../../utils'

const Lateral2 = ({ activity, aid, cmp, width, col = 4 }) => {
  return (
    <div className={`col-sm-${col} mb-4`}>
      <a
        href={`https://vipealo.com/${slugify(activity.country.name)}/${slugify(activity.city.name)}/${slugify(activity.title)}/?aid=${aid}&cmp=${cmp}`} key={activity._id} rel='noopener noreferrer'
        target='_blank' >
        <article className='text-left item d-flex m-auto' key={activity._id} style={{ width: `${width}px || ''` }}>
          <div className='item-header img-roun' style={{ backgroundImage: `url(${ activity.thumbnail })` }} />
          <div className='pl-2 item-body'>
            <p>{ activity.title }</p>
            <div className>
              <div className='rate'>
                <span className='font-weight-bold'>9,7<i aria-hidden='true' className='icon-c fa fa-star ml-1'> </i></span>
                <span className='font-weight-bold'>{activity.visits} visitas</span>
              </div>
              <div className='mt-2 price'>
                <span className='font-weight-bold'>{ getOffer(activity) ? getOffer(activity) + ' €' : ''  }</span>
                <span className='font-weight-bold'>{`${!getFirstPrice(activity.prices) ? '--- €' : getFirstPrice(activity.prices) === 0 ? 'FREE' : getFirstPrice(activity.prices).toFixed(2) + ' €' }`}</span>
              </div>
            </div>
          </div>
        </article>
      </a>
    </div>
  )
}

export default Lateral2
