import React, { useState, useRef, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { LOGIN_REQUEST, ADMIN_LOGIN_REQUEST } from '../../actions/actionTypes'
import { useHistory, Link } from 'react-router-dom'

const Login = (props) => {
  const { match } = props
  const { status, error } = useSelector(state => state.user)
  const [ password, setPassword ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ errors, setErrors ] = useState({})
  const [ loading, setloading ] = useState(false)
  const [ blockedInputs, setBlockedInputs ] = useState(false)
  const history = useHistory()
  const alertRef = useRef(null)

  const handleSave = (event) => {
    event.preventDefault()
    if(!formIsValid()) return
    setloading(true)
    props.login(email, password, history)
    if(props.user.status === 'Login error')
      _handleAlert('add')

    setloading(false)
  }

  useEffect(() => {
    if(status === 'LOGIN_REQUEST') {
      setloading(true)
    } else if(status === 'LOGIN_ERROR') {
      _handleAlert('add')
      setloading(false)
    } else {
      setloading(false)}
  }, [ status, loading ])

  useEffect(() => {
    const { params: { aid, token } } = match
    if(aid && token) {
      setBlockedInputs(true)
      props.adminLogin(aid, token, history)
    }
    // eslint-disable-next-line
  }, [])

  const formIsValid = () => {
    const errors = {}
    if(!email) errors.email = 'Email requerido'
    if(!password) errors.password = 'Contraseña requerida'
    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const _handleAlert = (action) => {
    if(action === 'add')
      alertRef.current.classList.add('show')
    else alertRef.current.classList.remove('show')
  }

  return (
    <div className='d-flex justify-content-center align-items-center h-100vh'>
      <div className='alert alert-credentials alert-danger alert-dismissible fade' ref={alertRef} role='alert'>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='alert'
          onClick={() => {_handleAlert('remove')}}
          type='button'>
          <span aria-hidden='true'>&times;</span>
        </button>
        { error }
      </div>
      <div className='container b-shadow-all mt-5'>
        <div className='card  top-card m-3 mt-0'>
          <div className='card-body'>
            <div className='media'>
              <div className='media-body card-header p-1 mb-4'>
                <h2 className=' mt-2 text-center text-darki'>Iniciar sesión</h2>
              </div>
            </div>
            <div className='col-md-12'>
              <form onSubmit={(event) => handleSave(event)}>
                <div className='form-group'>
                  <label htmlFor='email'>Email:</label>
                  <input
                    aria-describedby='emailHelp' className='form-control'
                    disabled={blockedInputs} id='email' onChange={(event) => setEmail(event.target.value)}
                    placeholder='usuario@test.com' type='email' />
                </div>
                {errors.email && (
                  <div className='alert alert-danger' role='alert'>
                    {errors.email}
                  </div>
                )}
                <div className='form-group'>
                  <label htmlFor='password'>Contraseña:</label>
                  <input
                    className='form-control' disabled={blockedInputs} id='password'
                    onChange={(event) => setPassword(event.target.value)}
                    placeholder='Contraseña' type='password' />
                </div>
                {errors.password && (
                  <div className='alert alert-danger' role='alert'>
                    {errors.password}
                  </div>
                )}
                <div className='text-center'>
                  <button
                    className='btn btn-primary btn-block' disabled={loading || blockedInputs} style={{ marginRight: '5px' }}
                    type='submit'>
                    {loading ? 'Verificando...' : 'Ingresar'}
                  </button>
                </div>
              </form>
            </div>
            <div className='d-flex justify-content-between mt-3'>
              <div className='link mt-3'>
                ¿No tienes cuenta? <Link to={'/afiliados/registrarse'}><span className='link-create-account'>¡Crea una!</span></Link>
              </div>
              <div className='link mt-3'>
                <Link to={'/afiliados/olvide-contrasena'}><span className='link-create-account'>He olvidado mi contraseña</span></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps =  (dispatch) => {
  return {
    adminLogin: (id, token, history) => dispatch({ history, id, token, type: ADMIN_LOGIN_REQUEST }),
    login     : (user, password, history) => dispatch({ history, password, type: LOGIN_REQUEST, user })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Login)
