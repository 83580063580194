import React from 'react'
import { slugify, getFirstPrice, getOffer } from '../../utils'

const Body2 = ({ activity, aid, cmp, col = 4, noBgColor }) => {
  return (
    <div className={`col-sm-${col}`}>
      <a
        href={`https://vipealo.com/${slugify(activity.country.name)}/${slugify(activity.city.name)}/${slugify(activity.title)}/?aid=${aid}&cmp=${cmp}`} key={activity._id} rel='noopener noreferrer'
        target='_blank' >
        <article className='text-left item allow-show-more' key={activity._id}>
          <a
            className={`${noBgColor ? 'v-hidden' : ''}`}
            href={`https://vipealo.com/${slugify(activity.country.name)}/${slugify(activity.city.name)}/${slugify(activity.title)}/?aid=${aid}&cmp=${cmp}`} key={activity._id} rel='noopener noreferrer'
            target='_blank' >
            <div className='show-more'>
              <p className='pb-1 font-weight-bold sm-title'>{ activity.title }</p>
              <div className='description'>
                <p className='mt-2 mb-4'>{activity.metadescription}</p>
              </div>
            </div>
          </a>
          <div className='item-header' style={{ backgroundImage: `url(${activity.thumbnail})` }}>
            <span className='pl-1 pr-1'><i className='icon-c clock-white' />Imprescindible</span>
          </div>

          <div className='p-2 item-body'>
            <p className='pb-1 font-weight-bold sm-title'>{ activity.title }</p>
            <div>
              <div className='rate'>
                <span className='font-weight-bold'>9,75<i aria-hidden='true' className='icon-c fa fa-star ml-1'> </i></span>
                <span className='font-weight-bold'>{`${getFirstPrice(activity.prices) === 0 ? 'FREE' : getFirstPrice(activity.prices).toFixed(2) + ' €' }`}</span>
              </div>
              <div className='price text-right'>
                <span className='font-weight-bold'>{ getOffer(activity) ? getOffer(activity) + ' €' : ''  }</span>
                <span className='font-weight-bold'>{`${!getFirstPrice(activity.prices) ? '--- €' : getFirstPrice(activity.prices) === 0 ? 'FREE' : getFirstPrice(activity.prices).toFixed(2) + ' €' }`}</span>
              </div>
            </div>
          </div>
        </article>
      </a>
    </div>
  )
}

export default Body2
