export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGGED_IN = 'Logged in'
export const LOGGED_OUT = 'Logged out'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_CANCELLED = 'Login cancelled'
export const STORE_USER_SUCCESS = 'Store user success'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const CREATE_USER_SUCCESS = 'Create user success'
export const CREATE_USER_ERROR = 'Create user error'
export const MARK_NOTIFICATION_ERROR = 'Mark notification error'
export const MARK_NOTIFICATION_SUCCESS = 'Mark notification success'
export const PASSWORD_REQUEST = 'PASSWORD_REQUEST'
export const PASSWORD_REQUEST_SUCCESS = 'PASSWORD_REQUEST_SUCCESS'
export const PASSWORD_REQUEST_ERROR = 'PASSWORD_REQUEST_ERROR'
export const PASSWORD_CONFIRM_CODE = 'PASSWORD_CONFIRM_CODE'
export const PASSWORD_CONFIRM_CODE_ERROR = 'PASSWORD_CONFIRM_CODE_ERROR'
export const PASSWORD_CONFIRM_CODE_SUCCESS = 'PASSWORD_CONFIRM_CODE_SUCCESS'
export const PASSWORD_UPDATE = 'PASSWORD_UPDATE'
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS'
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR'
export const EMAIL_REQUEST = 'EMAIL_REQUEST'
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS'
export const EMAIL_ERROR = 'EMAIL_ERROR'
export const EVALUATE_USER_ERROR = 'EVALUATE_USER_ERROR'
export const EVALUATE_USER_SUCCESS = 'EVALUATE_USER_SUCCESS'
export const DEFAULT_SETTINGS_STORED = 'DEFAULT_SETTINGS_STORED'

export default {
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  DEFAULT_SETTINGS_STORED,
  EMAIL_ERROR,
  EMAIL_REQUEST,
  EMAIL_SUCCESS,
  EVALUATE_USER_ERROR,
  EVALUATE_USER_SUCCESS,
  LOGGED_IN,
  LOGGED_OUT,
  LOGIN_CANCELLED,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  MARK_NOTIFICATION_ERROR,
  MARK_NOTIFICATION_SUCCESS,
  PASSWORD_CONFIRM_CODE,
  PASSWORD_CONFIRM_CODE_ERROR,
  PASSWORD_CONFIRM_CODE_SUCCESS,
  PASSWORD_REQUEST,
  PASSWORD_REQUEST_ERROR,
  PASSWORD_REQUEST_SUCCESS,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_ERROR,
  PASSWORD_UPDATE_SUCCESS,
  STORE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS
}
