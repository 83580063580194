import React, { useEffect, useState } from 'react'
import { useSelector }  from 'react-redux'
import moment from 'moment-timezone'
import ApexCharts from 'apexcharts'
import { api } from '../../../utils'

const GraphicStats = ({ month, type }) => {
  const months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ]
  const [ stats, setStats ] = useState({ clicks: [], reservations: [] })
  const { _id: affiliateId } = useSelector(state => state.user.affiliate)

  const initStatsDay = () => {
    document.querySelector('#line-chart').innerHTML = ''
    let endOfMonth = moment(month).month() === moment().month() ?  moment().date() : moment(month).endOf('month').date()
    let label = [], clics = [], reserves = []
    for (var x = 1; x <= endOfMonth; x++) {
      label.push(x)

      // eslint-disable-next-line
      let res = stats.reservations?.find(s => s._id.day === x)
      let quant = res && res.quantity ? res.quantity : 0
      reserves.push(quant)

      // eslint-disable-next-line
      let clic = stats.clicks?.find(s => s._id.day === x)
      let quantClic = clic && clic.quantity ? clic.quantity : 0
      clics.push(quantClic)
    }
    var options = {
      chart: {
        height : 250,
        toolbar: {
          show: false
        },
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      colors    : [ '#83d0f5', '#009dff' ],
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: [ 1, 0.1 ],
        type   : 'solid'
      },
      legend: {
        horizontalAlign: 'right',
        position       : 'top'
      },
      markers: {
        size: 3
      },
      series: [
        {
          data: clics,
          name: 'Nº Clics',
          type: 'line'
        }, {
          data: reserves,
          name: 'Nº Reservas',
          type: 'area'
        }
      ],
      stroke: {
        curve    : 'smooth',
        dashArray: [ 4, 0 ],
        width    : '3'
      },
      xaxis: {
        categories: label,
        title     : {
          text: moment(month).format('MMMM YYYY')
        }
      }
    }

    var chart = new ApexCharts(document.querySelector('#line-chart'), options)
    chart.render()
  }

  const initStatsMonth = () => {
    let categories = [], reserves = [], clicks = []
    let currentMonth = moment().month()
    let initialMonth = currentMonth - 7
    let pos = 0
    const { reservations, clicks: statsClicks } = stats

    for (let i = initialMonth; i <= currentMonth; i++) {
      categories[pos] = months[i]

      let posReserves = reservations && reservations.find(st => st._id.month === i + 1)
      reserves[pos] = posReserves ? posReserves.quantity : 0

      let posClicks = statsClicks && statsClicks.find(st => st._id.month === i + 1)
      clicks[pos] = posClicks ? posClicks.quantity : 0

      pos++
    }

    document.querySelector('#line-chart').innerHTML = ''
    var options = {
      chart: {
        height : 305,
        toolbar: {
          show: false
        },

        type: 'line',
        zoom: {
          enabled: false
        }
      },
      colors    : [ '#83d0f5', '#009dff' ],
      dataLabels: {
        enabled: false
      },
      fill: {
        opacity: [ 1, 0.1 ],
        type   : 'solid'
      },
      legend: {
        horizontalAlign: 'right',
        position       : 'top'
      },

      markers: {
        size: 3
      },
      series: [
        {
          data: clicks,
          name: 'Nº Clics',
          type: 'line'
        },
        {
          data: reserves,
          name: 'Nº Reservas',
          type: 'area'
        }
      ],

      stroke: {
        curve    : 'smooth',
        dashArray: [ 4, 0 ],
        width    : '3'
      },

      xaxis: {
        categories: categories,
        title     : {
          text: 'Month'
        }
      }
    }

    var chart = new ApexCharts(document.querySelector('#line-chart'), options)
    chart.render()
  }

  const getStats = async () => {
    let query = { date: month, typeStats: type }
    const params = new URLSearchParams(query)

    try {
      const res = await api('get', `affiliates/stats/${affiliateId}`, `?${params}`, {}, '')
      setStats(res.stats)
    }
    catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(!month)
      getStats(moment().format('yyyy-MM'))
    else getStats(month)
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   if(month && type !== 'month')
  //     getStats()
  //   // eslint-disable-next-line
  //   }, [ month ])

  useEffect(() => {
    if(type === 'month') initStatsMonth()
    else if(type === 'day') initStatsDay()
    // eslint-disable-next-line
    }, [stats])

  return (
    <div className='apex-charts' id='line-chart' />
  )
}

export default GraphicStats
