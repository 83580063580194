import axios from 'axios'

export const api = (method, endpoint, operation, data, token) =>
  new Promise((resolve, reject) => {
    const { REACT_APP_API } = process.env

    const op = operation === 'list' ? '' : operation

    axios({
      data,
      headers: {
        authorization: `Bearer ${token}`
      },
      method,
      url: `${REACT_APP_API}/${endpoint}/${op}`
    })
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })

export  const validateEmail = (email) => {
  var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

  return regex.test(String(email).toLowerCase())
}

export const getPeoplePrice = (priceGroups) => {
  let tPrice = 0, tPeople = 0
  for (let pg of priceGroups)
    for (let price of pg.prices) {
      tPrice += price.people * price.price
      tPeople += price.people
    }

  return [ tPeople, tPrice ]
}

export const getTotalFreetour = (reservations) => {
  let totalTemp = 0, freetour = 0

  reservations && reservations.map(({ details }) => {
    const { priceGroups } = details
    let price = getPeoplePrice(priceGroups)[1]
    totalTemp += price
    if(price === 0)
      freetour++

    return 0
  })

  return [ totalTemp, freetour ]
}

export const slugify = (text, separator) => {
  text = text ? text.toString().toLowerCase().trim() : ''

  const sets = [
    { from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]', to: 'a' },
    { from: '[ÇĆĈČ]', to: 'c' },
    { from: '[ÐĎĐÞ]', to: 'd' },
    { from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]', to: 'e' },
    { from: '[ĜĞĢǴ]', to: 'g' },
    { from: '[ĤḦ]', to: 'h' },
    { from: '[ÌÍÎÏĨĪĮİỈỊ]', to: 'i' },
    { from: '[Ĵ]', to: 'j' },
    { from: '[Ĳ]', to: 'ij' },
    { from: '[Ķ]', to: 'k' },
    { from: '[ĹĻĽŁ]', to: 'l' },
    { from: '[Ḿ]', to: 'm' },
    { from: '[ÑŃŅŇ]', to: 'n' },
    { from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]', to: 'o' },
    { from: '[Œ]', to: 'oe' },
    { from: '[ṕ]', to: 'p' },
    { from: '[ŔŖŘ]', to: 'r' },
    { from: '[ßŚŜŞŠ]', to: 's' },
    { from: '[ŢŤ]', to: 't' },
    { from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]', to: 'u' },
    { from: '[ẂŴẀẄ]', to: 'w' },
    { from: '[ẍ]', to: 'x' },
    { from: '[ÝŶŸỲỴỶỸ]', to: 'y' },
    { from: '[ŹŻŽ]', to: 'z' },
    { from: "[·/_,:;']", to: '-' }
  ]

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to)
  })

  text = text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text

  if(typeof separator !== 'undefined' && separator !== '-')
    text = text.replace(/-/g, separator)

  return text
}

export const getFirstPrice = (priceGroups) => {
  let firstPrice
  let defaultPrice

  if(priceGroups.length > 0) {
    priceGroups.forEach((pg) => {
      const { prices } = pg
      defaultPrice = prices.find((price) => price.defaultPrice === true)
    })

    if(defaultPrice) {
      firstPrice = defaultPrice.amount
    } else {
      const [ price ] = priceGroups[0].prices
      firstPrice = price ? price.amount : null
    }
  }

  return firstPrice
}

export const getPriceWithoutOffer = (price, offer) => {
  let result = price
  if(offer && offer.amount && offer.amount > 0 && offer.type)
    if(offer.type ===  '€')
      result = price + offer.amount
    else
    if(offer.type === 'percent')
      result = (price / ((100 - offer.amount) / 100)).toFixed(2)

  return result
}

export const getOffer = (activity) => {
  const price = getFirstPrice(activity.prices)
  if(activity.offer && activity.offer.amount && price !== 0) {
    let oldPrice = getPriceWithoutOffer(price, activity.offer)
    if(oldPrice > price)
      return oldPrice
  }

  return ''
}

export const formatDuration = ({ hours, minutes }) => {
  return hours < 24 ?
    `${hours > 0 ? `${hours}h` : ''} ${minutes > 0 ? `${minutes}m` : ''}` :
    `${hours / 24} días`
}

export const hasData = (data) => {
  if(data) return true
}

export const getRate = (currencies, currency, currentCurrency) => {
  const { rates } = currencies.find(({ code }) => code === (currency ? currency : 'EUR'))
  const curr = rates.find(({ code }) => code === (currentCurrency ? currentCurrency : 'EUR'))

  return curr.rate
}

export const removeAccents = (text) => {
  let r = text.toLowerCase()

  r = r.replace(new RegExp('\\s', 'g'),'')
  r = r.replace(new RegExp('[àáâãäå]', 'g'),'a')
  r = r.replace(new RegExp('æ', 'g'),'ae')
  r = r.replace(new RegExp('ç', 'g'),'c')
  r = r.replace(new RegExp('[èéêë]', 'g'),'e')
  r = r.replace(new RegExp('[ìíîï]', 'g'),'i')
  r = r.replace(new RegExp('ñ', 'g'),'n')
  r = r.replace(new RegExp('[òóôõö]', 'g'),'o')
  r = r.replace(new RegExp('œ', 'g'),'oe')
  r = r.replace(new RegExp('[ùúûü]', 'g'),'u')
  r = r.replace(new RegExp('[ýÿ]', 'g'),'y')
  r = r.replace(new RegExp('\\W', 'g'),'')

  return r
}

export const childCheck = (name) => {
  const check = [ 'bebe', 'nino', 'nina', 'ninos', 'ninas', 'bebes', 'child', 'youth', 'infant', 'jovenes' ]
    .find((t) => removeAccents(name).indexOf(t) >= 0)

  return check ? true : false
}
