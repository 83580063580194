import React, { useState } from 'react'

// Components

import Header from '../../components/Affiliates/Header'
import LeftSidebar from '../../components/Affiliates/LeftSidebar'
import Footer from '../../components/Affiliates/Footer'

const AffiliatesContainer = (props) => {
  const [ openMenu, setOpenMenu ] = useState(false)

  return (
    <div  data-layout='detached' data-topbar='colored'>
      <div className='container-fluid'>
        <div id='layout-wrapper'>
          <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />

          <LeftSidebar openMenu={openMenu} />
          <div className='main-content'>
            { props.children }
            <Footer />
          </div>
        </div>
      </div>
    </div>

  )
}

export default AffiliatesContainer
