import React, { useState, useEffect, useRef } from 'react'
import Container from '..'
import { connect } from 'react-redux'
import actionTypes from '../../../actions/actionTypes'
import Dropzone from '../../../components/Dropzone'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'
import { Link } from 'react-router-dom'

const Account = ({ user, ...props }) => {
  const [ status, setStatus ] = useState(user.status)
  const [ errorUpload, setErrorUpload ] = useState({ message: '', status: false })
  const [ firstName, setFirstName ] = useState(user.firstName || '')
  const [ lastName, setLastName ] = useState(user.lastName || '')
  const [ phone, setPhone ] = useState(user.phone || '')
  const [ urls, setUrls ] = useState(user.affiliate && user.affiliate.urls ? user.affiliate.urls : [  ])
  const [ affiliate, setAffiliate ] = useState(user.affiliate ? user.affiliate : {})
  const [ errors, setErrors ] = useState([])
  const [ avatar, setAvatar ] = useState(user.affiliate.avatar || process.env.REACT_APP_DEFAULT_AVATAR)
  const [ newsletter, setNewsletter ] = useState(user.affiliate && user.affiliate.newsletter ? user.affiliate.newsletter : false)
  // const [ serviceLanguages, setServiceLanguages ] = useState(user.affiliate && user.affiliate.serviceLanguages ? user.affiliate.serviceLanguages : {
  //   english     : false,
  //   french      : false,
  //   italian     : false,
  //   portugueseBR: false,
  //   portuguesePO: false,
  //   spanish     : false
  // })
  // const { english, french, italian, portugueseBR, portuguesePO, spanish  } = serviceLanguages
  // const englishRef = useRef(null)
  // const spanishRef = useRef(null)
  // const frenchRef = useRef(null)
  // const portugueseBRRef = useRef(null)
  // const portuguesePORef = useRef(null)
  // const italianRef = useRef(null)
  const newsletterRef = useRef(null)

  const _handleUpdate = async (event) => {
    setErrors([])
    event.preventDefault()
    if(validateFields()) {
      const updateAffiliate = affiliate
      updateAffiliate.avatar = avatar
      updateAffiliate.urls = urls
      updateAffiliate.newsletter = newsletter
      // updateAffiliate.serviceLanguages = serviceLanguages
      setAffiliate({ ...updateAffiliate, ...affiliate })
      await props.updateUser({ _id: user._id, affiliate, firstName, lastName, phone, role: user.role, token: user.token })

      return
    }
  }

  const showMessage = () => {
    return  status === 'UPDATE_USER_SUCCESS' ? <h4 className='text-success text-center'>Usuario actualizado</h4> : null
  }
  useEffect(() => {
    setStatus(user.status)
  }, [ user ])
  // disable msg on first load in case validation is true
  useEffect(() => {
    if(status === 'UPDATE_USER_SUCCESS') setStatus('UPDATE_USER')
    // eslint-disable-next-line
  }, [])

  const validateFields = () => {
    const inputs = document.querySelectorAll('input[type="text"]')
    let result = true
    inputs.forEach(input => {
      if(input.value.length > 3) {
        input.classList.add('b-blue')
        input.classList.remove('b-red')
      }
      else {
        input.classList.add('b-red')
        input.classList.remove('b-blue')
        result = false
        if(input.value === '')
          setErrors([ { message: 'Ups, los campos en rojo son requeridos' } ])
        else setErrors([ { message: 'Ups, los campos deben poseer al menos 4 letras' } ])
      }
    })

    return result
  }

  const showErrors = () => {
    return (
      <>
        {
          errors.map((err, i) => (
            <h4 className='text-red' key={i}>
              { err.message }
            </h4>
          ))
        }
      </>

    )
  }

  const _handleChangeAvatar = (url) => {
    setAvatar(url)
  }

  const _handleChangeUrl = (url, index) => {
    const newExtras = Array.from(urls)
    newExtras[index] = { url }
    setUrls(newExtras)
  }

  const _handleAddURL = () => {
    const newExtras = Array.from(urls)
    newExtras.push({ url: '' })
    setUrls(newExtras)
  }

  const _handleFocus = (e) => {
    let elem = e.target.parentNode
    elem.classList.add('active')
  }

  const _handleBlur = (e) => {
    if(e.target.value === '') {
      let elem = e.target.parentNode
      elem.classList.remove('active')
    }
  }

  // const _handleChecked = (e) => {
  //   e.target.parentNode.classList.toggle('checked')
  //   setServiceLanguages({
  //     ...serviceLanguages,
  //     [e.target.name]: (e.target.checked)
  //   })
  // }

  const _handleNewsletter = e => {
    e.target.parentNode.classList.toggle('checked')
    setNewsletter(!newsletter)
  }

  const _handleRemoveURL = (i) => {
    const newExtras = urls.filter((_, ind) => ind !== i)
    setUrls(newExtras)
  }

  useEffect(() => {
    if(newsletter) {
      newsletterRef.current.parentNode.classList.add('checked')
      newsletterRef.current.checked = true
    }
    // if(english) {
    //   englishRef.current.parentNode.classList.add('checked')
    //   englishRef.current.checked = true
    // }
    // if(spanish) {
    //   spanishRef.current.parentNode.classList.add('checked')
    //   spanishRef.current.checked = true
    // }
    // if(french) {
    //   frenchRef.current.parentNode.classList.add('checked')
    //   frenchRef.current.checked = true
    // }
    // if(portuguesePO) {
    //   portuguesePORef.current.parentNode.classList.add('checked')
    //   portuguesePORef.current.checked = true
    // }
    // if(portugueseBR) {
    //   portugueseBRRef.current.parentNode.classList.add('checked')
    //   portugueseBRRef.current.checked = true
    // }
    // if(italian) {
    //   italianRef.current.parentNode.classList.add('checked')
    //   italianRef.current.checked = true
    // }

    let inputs = document.querySelectorAll('input[type="email"], input[type="text"]')
    inputs.forEach(input => {
      if(input.value !== '')
        input.parentNode.classList.add('active')
    })
    // eslint-disable-next-line
  }, [])

  const _handlePhone = (_, __, ___, p) => {
    const phoneArr = p.split(' ')
    const prefix = phoneArr[0]
    const phone = phoneArr.splice(1, phoneArr.length).join('')
    setPhone(`${prefix} ${phone}`)
  }

  return (
    <Container>
      <div className='main-content my-account'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Mi cuenta</h4>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='wrapper'>
              <form action='#' method='POST'>
                <h1 className='mb-4'><i className='icon-c single-gray mr-2' /> Datos personales</h1>
                <div className='personal-data'>
                  <div className='ci-wrapper mb-3 check-field'>
                    <label htmlFor='name'>Escribe tu nombre</label>
                    <input
                      defaultValue={firstName}
                      id='firstname'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e) => setFirstName(e.target.value)}
                      onFocus={(e)=>{_handleFocus(e)}}
                      type='text' />
                  </div>
                  <div className='ci-wrapper mb-3 check-field'>
                    <label htmlFor='surname'>Escribe tus apellidos</label>
                    <input
                      defaultValue={lastName}
                      id='surname'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e) => setLastName(e.target.value)}
                      onFocus={(e)=>{_handleFocus(e)}}
                      type='text' />
                  </div>

                  <h5 className='cglb-e mb-3'>Carga tu imagen de perfil:</h5>
                  <div className='ci-wrapper mb-3 check-field'>
                    {
                      errorUpload.status ? (<h4 className='text-red'>{errorUpload.message}</h4>) : null
                    }
                    <Dropzone
                      accept={[ 'jpeg', 'jpg', 'png' ]}
                      doc={avatar}
                      height='75'
                      route='affiliates/uploads/images'
                      setDoc={_handleChangeAvatar}
                      setError={setErrorUpload} />
                  </div>
                  <div className='webs-info'>
                    <h2 className='mt-4 mb-4'>Webs <i className='icon-c info-gray mb-1' id='webs_info' /></h2>
                    <p >Debes contar con una página web o blog activo que trate de viajes o un tema relacionado.
                     Actualmente no colaboramos con personas que sólo cuenten con redes sociales .</p>
                  </div>
                  <div className='web-list mb-4'>
                    <div className='list'>
                      {
                        urls && urls.map((url, i) => (
                          <div className='ci-wrapper mb-3 check-field' key={i}>
                            <label htmlFor='web_1'>Web { i + 1 }</label>
                            <input
                              defaultValue={url.url}
                              id={'web_' + i}
                              onBlur={(e)=>{_handleBlur(e)}}
                              onChange={(e) => _handleChangeUrl(e.target.value, i)}
                              onFocus={(e)=>{_handleFocus(e)}}
                              type='text' />
                            <div className='text-left font-weight-bolder mt-2 text-red'>
                              <span
                                className=' c-pointer'
                                id='more-webs'
                                onClick={() =>{ _handleRemoveURL(i)}} style={{ marginTop: '5px' }}>
                                <i className='fa fa-minus' /> Eliminar
                              </span>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    <div className='text-right font-weight-bolder more-webs'>
                      <span
                        id='more-webs'
                        onClick={() => _handleAddURL()} style={{ marginTop: '5px' }}>
                        <i className='fa fa-plus text-primary' /> Añadir web
                      </span>
                    </div>
                  </div>
                  <div className='ci-wrapper mb-3 check-field active'>
                    <label htmlFor='phone'>Teléfono</label>
                    <PhoneInput
                      countryCodeEditable={false}
                      disableSearchIcon
                      enableSearch
                      inputClass={'react-tel-input user-phone '}
                      localization={es}
                      onChange={_handlePhone}
                      placeholder='Teléfono'
                      searchPlaceholder='País'
                      value={`${phone}`} />
                  </div>
                  { showErrors() }
                  { showMessage() }
                  {/* <h2 className='mt-4 mb-2'>Idiomas servicios</h2> */}
                  {/* <div className='languages-list'>
                    <ul className='row p-0 text-left'>
                      <li className='col-md-4'>
                        <label className='cb-label' htmlFor='lang_es'>
                          <span className='custom-checkbox' />
                          <input
                            id='lang_es'
                            name='spanish'
                            onChange={(e)=>{_handleChecked(e)}}
                            ref={spanishRef}
                            type='checkbox'
                            value={spanish} />Español
                        </label>
                      </li>
                      <li className='col-md-4'>
                        <label className='cb-label' htmlFor='lang_en'>
                          <span className='custom-checkbox' />
                          <input
                            id='lang_en'
                            name='english'
                            onChange={(e)=>{_handleChecked(e)}}
                            ref={englishRef}
                            type='checkbox'
                            value={english} />Inglés
                        </label>
                      </li>
                      <li className='col-md-4'>
                        <label className='cb-label' htmlFor='lang_fr'>
                          <span className='custom-checkbox' />
                          <input
                            id='lang_fr'
                            name='french'
                            onChange={(e)=>{_handleChecked(e)}}
                            ref={frenchRef}
                            type='checkbox'
                            value={french} />Francés
                        </label>
                      </li>
                      <li className='col-md-4'>
                        <label className='cb-label' htmlFor='lang_it'>
                          <span className='custom-checkbox' />
                          <input
                            id='lang_it'
                            name='italian'
                            onChange={(e)=>{_handleChecked(e)}}
                            ref={italianRef}
                            type='checkbox'
                            value={italian} />
                            Italiano
                        </label>
                      </li>
                      <li className='col-md-4'>
                        <label className='cb-label' htmlFor='lang_pt'>
                          <span className='custom-checkbox' />
                          <input
                            id='lang_pt'
                            name='portuguesePO'
                            onChange={(e)=>{_handleChecked(e)}}
                            ref={portuguesePORef}
                            type='checkbox'
                            value={portuguesePO} />Portugués (PT)
                        </label>
                      </li>
                      <li className='col-md-4'>
                        <label className='cb-label' htmlFor='lang_br'>
                          <span className='custom-checkbox' />
                          <input
                            id='lang_br'
                            name='portugueseBR'
                            onChange={(e)=>{_handleChecked(e)}}
                            ref={portugueseBRRef}
                            type='checkbox'
                            value={portugueseBR} />Portugués (BR)
                        </label>
                      </li>
                    </ul>
                  </div> */}
                  <div className='separator' />
                  <div className='form-actions pt-3'>
                    <Link className='user-img' to='/afiliados/condiciones-generales'>
                      Condiciones Generales de Afiliados
                    </Link>
                    <div className='row ml-0 mt-4'>
                      <label className='cb-label mr-2 col-md-6'>
                        <span className='custom-checkbox' />
                        <input
                          name='newsletter'
                          onChange={(e)=>{_handleNewsletter(e)}}
                          ref={newsletterRef}
                          type='checkbox' />
                        Recibir ofertas y novedades de Vipealo
                      </label>
                      <button
                        className='btn btn-primary font-weight-bolder col-md-5'
                        disabled={status === 'UPDATE_USER_REQUEST' ? true : false}
                        onClick={(event) => _handleUpdate(event)} style={{ marginRight: '5px' }}>
                        {status === 'UPDATE_USER_REQUEST' ? 'Enviando...' : 'Guardar'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps =  (dispatch) => {
  return {
    updateUser: (user) => dispatch({ type: actionTypes.UPDATE_USER_REQUEST , user })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Account)

