import React, { useEffect, useState } from 'react'
// import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeMonth, changeStatsType, fetchGroupedReservations } from '../../../reducers/reservations'
import Container from '..'
import moment from 'moment-timezone'
import GraphicStats from '../../../components/Affiliates/GraphicStats'
import Spinner from '../../../components/Spinner'
import Error from '../../../components/Error'
// import { getPeoplePrice } from  '../../../utils'
// import Pagination from '../../../components/Pagination'
import StatsTable from '../../../components/Stats'

const StatsAffiliate = () => {
  const dispatch = useDispatch()

  const {
    // data: { docs: reservations },
    currencies,
    conversionRate,
    defaultFreeTour,
    month,
    loading,
    error,
    tableLoading,
    groupedReservations = [],
    statsType = 'reservation'
  } = useSelector(state => state.reservations)
  const { percent, bill, freetour = 1 } = useSelector(state => state.user.affiliate)
  const [ affiliateCurrency ] = useState(bill && bill.currency ? bill.currency : 'EUR')
  const [ type, setType ] = useState(statsType)
  // const [ totalCommissionPage, setTotalCommissionPage ] = useState(0)

  // events handlers
  const _handleMonth = (month) => {
    dispatch(changeMonth(month))
    dispatch(fetchGroupedReservations(month))
  }

  // useEffects
  useEffect(()=>{
    if(!month)
      _handleMonth(moment().format('yyyy-MM'))
    else _handleMonth(month)
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   let [ subTotal, subFreetour ] = getTotalFreetour(reservations)
  //   let tempTotalComm = (subTotal * percent / 100) + (subFreetour * freetour)
  //   setTotalCommissionPage(Number(tempTotalComm).toFixed(2))
  //   // eslint-disable-next-line
  // }, [ reservations, percent, freetour ])

  const _handleStatsType = (type) => {
    setType(type)
    dispatch(changeStatsType(type))
    dispatch(changeMonth(month))
    dispatch(fetchGroupedReservations(month))
  }

  return (
    <Container>
      <div className='main-content reserves'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Estadísticas</h4>
              </div>
            </div>
          </div>
          { error ? <Error code={500} type={'server error'} /> : loading ?
            <Spinner />          :
            (<div>
              <div className='date-filter row mb-3'>
                <div className='col-md-3'>
                  <input
                    className='form-control'
                    defaultValue={month}
                    max={moment().format('yyyy-MM')}
                    min={moment().format('2020-07')}
                    onChange={({ target: { value } }) => _handleMonth(value)}
                    type='month' />
                </div>
                <div className='col-md-2'>
                  <div className='dropdown act-rt'>
                    <select
                      className='btn btn-bg-white dropdown-toggle text-main-color'
                      id='dropdownDocumentType'
                      name='documentType'
                      onChange={(e)=> _handleStatsType(e.target.value)}
                      value={type}>
                      <option className='dropdown-item' value='reservation'>Fecha reserva</option>
                      <option className='dropdown-item' value='realization'>Fecha realización</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-8 text-right' />
              </div>
              <div>
                <div className='card'>
                  <div className='card-body'>
                    <h4 className='card-title mb-4'>Estadísticas</h4>
                    <GraphicStats month={month} type={'day'} />
                  </div>
                </div>
              </div>
              <div >
                <div className='card'>
                  <div className='card-body'>
                    <div className='table-responsive min-height-250'>
                      { tableLoading ? <Spinner /> :
                        <>
                          <StatsTable
                            affiliateCurrency={affiliateCurrency} conversionRate={conversionRate}
                            currencies={currencies} data={groupedReservations}
                            defaultFreeTour={defaultFreeTour} freetour={freetour} percent={percent}
                            type={type} />
                          {/* { reservations && reservations.length ? <Pagination /> : null } */}
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
    </Container>
  )
}

export default StatsAffiliate
