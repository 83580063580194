import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import registerServiceWorker from './registerServiceWorker'
import configureStore from './store/configureStore'
// Styles
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './assets/css/bootstrap.css'
import './assets/css/app.css'
// Affiliates pages
import DashboardAffiliate from './containers/Affiliates/Content/Dashboard'
import ReservesAffiliate from './containers/Affiliates/Content/Reserves'
import StatsAffiliate from './containers/Affiliates/Content/Stats'
import BillingAffiliate from './containers/Affiliates/Content/Billing'
import ComparativeAffiliate from './containers/Affiliates/Content/Comparative'
import ResourcesAffiliate from './containers/Affiliates/Content/Resources'
import HelpAffiliate from './containers/Affiliates/Content/Help'
import BetterPracticesAffiliate from './containers/Affiliates/Content/BetterPractices'
import Banners from './containers/Affiliates/Content/Banners'
import Links from './containers/Affiliates/Content/Links'
import Widgets from './containers/Affiliates/Content/Widgets'
import Nofitications from './containers/Affiliates/Content/Notifications'
import Contact from './containers/Affiliates/Content/Contact'
import GeneralConditions from './containers/GeneralConditions'

// Widget Embedded
import WidgetEmbedded from './components/Widget'

import Login from './containers/Login'
import Register from './containers/Register'
import PasswordRequest from './containers/PasswordRequest'

import UserAccount from './containers/Affiliates/Account/Account'
import UserAccessData from './containers/Affiliates/Account/AccessData'
import UserPaymentData from './containers/Affiliates/Account/PaymentData'
import UserGeneralConditions from './containers/Affiliates/Account/GeneralConditions'

// authorization
import PrivateRoute from './auth/PrivateRoute'
import PublicRoute from './auth/PublicRoute'

import { PersistGate } from 'redux-persist/integration/react'

const { store, persistor } = configureStore()

function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <Router>
          <Switch>
            <PublicRoute component={Login} exact  path={'/'}  />
            <PublicRoute component={Login} exact  path={'/admin/:aid/:token'}  />
            <PublicRoute component={Register} exact  path={'/afiliados/registrarse'}  />
            <PublicRoute component={GeneralConditions} exact  path={'/afiliados/registrarse/condiciones-generales'}  />
            <PublicRoute component={PasswordRequest} exact path={'/afiliados/olvide-contrasena'} />
            <PrivateRoute component={DashboardAffiliate} exact  path={'/afiliados/panel'}  />
            <PrivateRoute component={ReservesAffiliate} path={'/afiliados/reservas'} />
            <PrivateRoute component={StatsAffiliate} path={'/afiliados/estadisticas'} />
            <PrivateRoute component={BillingAffiliate} path={'/afiliados/facturacion'} />
            <PrivateRoute component={ComparativeAffiliate} path={'/afiliados/comparativas'} />
            <PrivateRoute component={HelpAffiliate} path={'/afiliados/ayuda'} />
            <PrivateRoute component={BetterPracticesAffiliate} path={'/afiliados/mejores-practicas'} />
            <PrivateRoute component={ResourcesAffiliate} exact path={'/afiliados/recursos'} />
            <PrivateRoute component={Banners} path={'/afiliados/recursos/banners'} />
            <PrivateRoute component={Links} path={'/afiliados/recursos/links'} />
            <PrivateRoute component={Widgets} path={'/afiliados/recursos/widgets'} />
            <PrivateRoute component={Nofitications} path={'/afiliados/nofiticaciones'} />
            <PrivateRoute component={Contact} path={'/afiliados/contacto'} />
            {/* Account*/}
            <PrivateRoute component={UserAccount} path={'/afiliados/mi-cuenta'} />
            <PrivateRoute component={UserAccessData} path={'/afiliados/datos-de-acceso'} />
            <PrivateRoute component={UserPaymentData} path={'/afiliados/datos-de-pago'} />
            <PrivateRoute component={UserGeneralConditions} path={'/afiliados/condiciones-generales'} />
            {/* Embebeded Widgets */}
            <Route component={WidgetEmbedded} path={'/afiliados/widgets-actividades'} />
            <Redirect from='/' to={'/afiliados/panel'} />
          </Switch>
        </Router>
      </PersistGate>
    </ReduxProvider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
registerServiceWorker()
