// Duck pattern :D

// initialstate
const initialData = {
  cleaned     : false,
  comparatives: {
    conversionRate  : 0,
    myConversionRate: 0,
    myTopDestinies  : [],
    myTopSales      : [],
    topDestinies    : [],
    topSales        : []
  },
  conversionRate        : 0,
  currencies            : [],
  data                  : [],
  defaultFreeTour       : 0,
  error                 : null,
  groupedReservations   : [],
  lastReservations      : [],
  loading               : false,
  month                 : null,
  myLastPayment         : '0 EUR',
  myLastSales           : [],
  myMonthClicks         : 0,
  quantFreetour         : 0,
  quantFreetourToBilling: 0,
  statsType             : 'reservation',
  tableLoading          : false,
  total                 : 0,
  totalToBilling        : 0
}

// types

export const FETCH_RESERVATIONS         = 'FETCH_RESERVATIONS'
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS'
export const FETCH_RESERVATIONS_ERROR   = 'FETCH_RESERVATIONS_ERROR'
export const CHANGE_MONTH               = 'CHANGE_MONTH'
export const SET_MONTH                  = 'SET_MONTH'
export const CHANGE_STATS_TYPE          = 'CHANGE_STATS_TYPE'
export const SET_STATS_TYPE             = 'SET_STATS_TYPE'
export const SET_STATS_TYPE_ERROR       = 'SET_STATS_TYPE_ERROR'
export const CURRENT_RESERVATIONS       = 'CURRENT_RESERVATIONS'
export const FETCH_COMPARATIVES         = 'FETCH_COMPARATIVES'
export const FETCH_COMPARATIVES_SUCCESS = 'FETCH_COMPARATIVES_SUCCESS'
export const FETCH_COMPARATIVES_ERROR   = 'FETCH_COMPARATIVES_ERROR'
export const DELETE_CONTENT             = 'DELETE_CONTENT'
export const FETCH_PAGE                 = 'FETCH_PAGE'
export const FETCH_PAGE_SUCCESS         = 'FETCH_PAGE_SUCCESS'
export const FETCH_PAGE_ERROR           = 'FETCH_PAGE_ERROR'
export const GET_PAGE                   = 'GET_PAGE'
export const FETCH_GROUPED_RESERVATIONS         = 'FETCH_GROUPED_RESERVATIONS'
export const FETCH_GROUPED_RESERVATIONS_SUCCESS = 'FETCH_GROUPED_RESERVATIONS_SUCCESS'
export const FETCH_GROUPED_RESERVATIONS_ERROR   = 'FETCH_GROUPED_RESERVATIONS_ERROR'

// reducer

export default function(state = initialData, action) {
  switch (action.type) {
    case FETCH_RESERVATIONS:
    case FETCH_GROUPED_RESERVATIONS:
      return {
        ...state,
        loading: true
      }
    case FETCH_RESERVATIONS_SUCCESS:
      return {
        ...state,
        conversionRate         : action.conversionRate,
        currencies             : action.currencies,
        data                   : action.payload,
        defaultFreeTour        : action.defaultFreeTour,
        error                  : null,
        // groupedReservations    : action.groupedReservations,
        loading                : false,
        myLastPayment          : action.myLastPayment,
        myLastSales            : action.myLastSales,
        myMonthClicks          : action.myMonthClicks,
        quantFreeetourToBilling: action.freetourToBilling,
        quantFreetour          : action.freetour,
        subTotal               : action.subTotal,
        total                  : action.total,
        totalToBilling         : action.totalToBilling
      }
    case FETCH_GROUPED_RESERVATIONS_SUCCESS:
      return {
        ...state,
        groupedReservations: action.groupedReservations,
      }
    case FETCH_RESERVATIONS_ERROR:
    case FETCH_GROUPED_RESERVATIONS_ERROR:
      return {
        ...state,
        error  : action.payload,
        loading: false
      }
    case SET_MONTH:
      return {
        ...state,
        month: action.payload
      }
    case SET_STATS_TYPE:
      return {
        ...state,
        statsType: action.payload
      }
    case SET_STATS_TYPE_ERROR :
      return {
        ...state,
        error  : action.payload,
        loading: false
      }
    case CURRENT_RESERVATIONS:
      return {
        ...state,
        loading: false
      }
    case FETCH_COMPARATIVES:
      return {
        ...state,
        error  : null,
        loading: true
      }
    case FETCH_COMPARATIVES_SUCCESS:
      return {
        ...state,
        comparatives: action.payload,
        error       : null,
        loading     : false
      }
    case FETCH_COMPARATIVES_ERROR:
      return {
        ...state,
        error  : action.payload,
        loading: false
      }
    case DELETE_CONTENT:
      return {
        ...state,
        cleaned     : true,
        comparatives: {
          conversionRate  : 0,
          myConversionRate: 0,
          myTopDestinies  : [],
          myTopSales      : [],
          topDestinies    : [],
          topSales        : []
        },
        conversionRate : 0,
        currencies     : [],
        data           : [],
        defaultFreeTour: 0,
        error          : null,
        loading        : false,
        month          : null,
        myLastPayment  : '0 EUR',
        myLastSales    : [],
        myMonthClicks  : 0,
        total          : 0,
        totalToBilling : 0
      }
    case FETCH_PAGE:
      return {
        ...state,
        tableLoading: true
      }
    case FETCH_PAGE_SUCCESS:
      return {
        ...state,
        data        : action.payload.reservations,
        tableLoading: false
      }
    case FETCH_PAGE_ERROR:
      return {
        ...state,
        data        : {},
        error       : action.payload,
        tableLoading: false
      }
    default:
      return state
  }
}

// actions creator

export const changeMonth = (month) => ({
  payload: month,
  type   : CHANGE_MONTH
})

export const getPage = (page) => ({
  payload: page,
  type   : GET_PAGE
})

export const changeStatsType = (type) => ({
  payload: type,
  type   : CHANGE_STATS_TYPE
})

export const fetchGroupedReservations = (data) => ({
  payload: data,
  type   : FETCH_GROUPED_RESERVATIONS
})
