import React from 'react'
import { getRate, childCheck } from '../../utils'
import moment from 'moment-timezone'

const StatsDetails = ({ affiliateCurrency, conversionPerRow = 0, currencies, defaultFreeTour, freetour, onChange, percent, reservations = [], type, typeData }) => {
  let grandTotal = { totalClicks: 0, totalCommission: 0, totalImport: 0, totalPeople: 0 }
  if(typeData === 'reservation')
    grandTotal = reservations.reduce((a,b) => {
      const { userAttended, serviceReservationId } = b.details, { people } = b.summary
      let bTotal = 0
      a.totalPeople = a.totalPeople + b.summary.people
      a.totalClicks = a.totalClicks + b.details.clicks
      a.totalImport = a.totalImport + (b.cancelled ? 0 : (b.total * getRate(currencies, b.currency, affiliateCurrency)))
      const freeTourCalc = defaultFreeTour * (freetour * (people ? people : 1)) * (userAttended ? 1 : 0)
      const totalCalc = (b.total * getRate(currencies, b.currency, affiliateCurrency)) * percent / 100
      if(serviceReservationId) bTotal = b.cancelled || !serviceReservationId ? 0 : b.total === 0 ? freeTourCalc : totalCalc
      a.totalCommission = a.totalCommission + bTotal

      return a
    },{ totalClicks: 0, totalCommission: 0, totalImport: 0, totalPeople: 0 })
  else
    grandTotal = reservations.reduce((a,b) => {
      a.totalClicks = a.totalClicks + b.clicks

      return a
    },{ totalClicks: 0, totalCommission: 0, totalImport: 0, totalPeople: 0 })

  const _handleHideDetails = () => {
    onChange(false, [], [])
  }

  const getPeople = (priceGroups = [], total = 0) => {
    let people = 0
    // Get people, dont sum childs if coming from freetour
    for (let pg of priceGroups)
      for (let pre of pg.prices)
        if(!childCheck(pre.name) && total === 0) people += pre.people
        else if(total !== 0) people += pre.people

    return people
  }

  return (
    <>
      <div className='row' style={{ marginRight: '0px' }}>
        <div className='col-6'>
          <button
            className={'btn btn-primary'} onClick={() => _handleHideDetails()}><i className='fa fa-arrow-left' />
            Volver
          </button>
        </div>
        <div className='col-6 text-right font-weight-bold text-dark'>
          <p>[Activa <i className='fa fa-circle text-dark'></i>] [Cancelada / No asistió / Pendiente <i className='fa fa-circle text-danger'></i>]</p>
        </div>
      </div>
      <table className='table allow-order mb-0 min-height-250'>
        <thead>
          <tr>
            {type === 'reservation' ?
              <th className='no-wrap order'>Fecha reserva</th> :
              <th className='no-wrap order'>Fecha realización</th>
            }
            <th className='no-wrap order'>Actividad</th>
            <th className='no-wrap order'>Campaña</th>
            <th className='no-wrap order'>Personas</th>
            {type === 'reservation' && (<th className='no-wrap order'>Clics</th>)}
            {type === 'reservation' && (<th className='no-wrap order'>Conversión</th>)}
            <th className='no-wrap order'>Importe</th>
            <th className='no-wrap order'>Comisión</th>
          </tr>
        </thead>
        <tbody>
          { typeData === 'reservation' ?
            reservations.map(({ cancelled, details, createdAt, currency, total },key) => {
              const { activity, clicks, date, cmp, schedule, timezone, userAttended, serviceReservationId, priceGroups } = details
              const hourText = schedule && timezone ? moment(Number(schedule)).tz(timezone).format('HH:mm') : null
              let rTotal = total, cTotal = 0, people = getPeople(priceGroups, total)
              if(currencies && affiliateCurrency) rTotal = (total * getRate(currencies, currency, affiliateCurrency)).toFixed(2) || 0
              if(serviceReservationId) cTotal = total !== 0 ? Number((rTotal) * percent / 100).toFixed(2) : (userAttended ? defaultFreeTour * (freetour * (people ? people : 1)) : 0).toFixed(2)
              else cTotal = 0

              return (
                <tr className={cancelled || (total === 0 && !userAttended) || !serviceReservationId ? 'text-red' : ''} key={key}>
                  {type === 'reservation' ?
                    <td>{ moment(createdAt).format('DD-MM-YYYY')}</td> :
                    <td>{ moment(date * 1000).format('DD-MM-YYYY') + ' ' + hourText}</td>
                  }
                  <td>{ activity && activity.huno ? activity.huno : '' }</td>
                  <td>{ cmp || 'S/C' }</td>
                  <td>{ people }</td>
                  {type === 'reservation' && (<td>{ clicks ? clicks : 0 }</td>)}
                  {/* {type === 'reservation' && (<td>{ cancelled ? 0 : ((clicks * parseFloat(conversionPerRow)) / grandTotal.totalClicks).toFixed(3)  }</td>)} */}
                  {type === 'reservation' && (<td>{ ((clicks * parseFloat(conversionPerRow)) / grandTotal.totalClicks).toFixed(3)  }</td>)}
                  <td>{cancelled ? 0 : rTotal} {affiliateCurrency}</td>
                  <td>{cancelled ? 0 : cTotal} {affiliateCurrency}</td>
                </tr>
              )
            }) :
            reservations.map(({ clicks, cmp, activity, createdAt }, key) => {
              return (
                <tr key={key}>
                  <td>{ moment(createdAt).format('DD-MM-YYYY')}</td>
                  <td>{ activity && activity.huno ? activity.huno : '' }</td>
                  <td>{ cmp || 'S/C' }</td>
                  <td>{ 0 }</td>
                  <td>{ clicks }</td>
                  <td>{ 0 }</td>
                  <td>{ 0 }</td>
                  <td>{ 0 }</td>
                </tr>
              )
            })
          }
        </tbody>
        { typeData === 'reservation' ?
          <tfoot>
            <tr className='table-red-light'>
              <td className='font-weight-bolder' colSpan={type === 'reservation' ? 3 : 3}>TOTAL</td>
              <td className='text-left font-weight-bolder'>{ grandTotal.totalPeople }</td>
              {type === 'reservation' && (<td className='text-left font-weight-bolder'>{ grandTotal.totalClicks }</td>)}
              {type === 'reservation' && (<td className='text-left font-weight-bolder'>{ conversionPerRow.toFixed(2) + ' %' }</td>)}
              <td className='text-left font-weight-bolder'>{ Number(grandTotal.totalImport).toFixed(2) + ' ' + affiliateCurrency }</td>
              <td className='text-left font-weight-bolder'>{ Number(grandTotal.totalCommission).toFixed(2) + ' ' + affiliateCurrency }</td>
            </tr>
          </tfoot> :
          <tfoot>
            <tr className='table-red-light'>
              <td className='font-weight-bolder' colSpan={3}>TOTAL</td>
              <td className='text-left font-weight-bolder'>{ 0 }</td>
              {type === 'reservation' && (<td className='text-left font-weight-bolder'>{ grandTotal.totalClicks }</td>)}
              {type === 'reservation' && (<td className='text-left font-weight-bolder'>{ 0 + ' %' }</td>)}
              <td className='text-left font-weight-bolder'>{ 0 + ' ' + affiliateCurrency }</td>
              <td className='text-left font-weight-bolder'>{ 0 + ' ' + affiliateCurrency }</td>
            </tr>
          </tfoot>
        }
      </table>
    </>
  )
}

export default StatsDetails
