import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'
import Container from '..'
import GraphicStats from '../../../components/Affiliates/GraphicStats'
import { changeMonth } from '../../../reducers/reservations'
import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'
// import Export from '../../../components/Export'
import { getPeoplePrice, getRate, childCheck } from  '../../../utils'
import Pagination from '../../../components/Pagination'

const ReservesAffiliate = () => {
  const dispatch = useDispatch()

  const {
    data: { docs: reservations, totalDocs = 0 },
    currencies,
    defaultFreeTour,
    error,
    loading,
    month,
    myMonthClicks,
    conversionRate,
    tableLoading,
    // quantFreetour,
    subTotal,
    total
  } = useSelector(state => state.reservations) || []
  const { percent, bill, freetour = 1 } = useSelector(state => state.user.affiliate)
  const [ affiliateCurrency ] = useState(bill && bill.currency ? bill.currency : 'EUR')
  // const [ dataToExcel, setDataToExcel ] = useState(null)
  // const [ commission, setCommission ] = useState(0)
  // const [ totalPage, setTotalPage ] = useState(0)
  // const [ totalCommissionPage, setTotalCommissionPage ] = useState(0)

  // events handlers
  const _handleMonth = (month) => {
    dispatch(changeMonth(month))
  }

  // useEffects
  useEffect(()=>{
    if(!month)
      _handleMonth(moment().format('yyyy-MM'))
    else _handleMonth(month)
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   const tempComm = (total * percent / 100) + (quantFreetour * defaultFreeTour)
  //   setCommission(tempComm)
  // }, [ quantFreetour, defaultFreeTour, total, percent ])

  // useEffect(() => {
  //   let [ subTotal, subFreetour ] = getTotalFreetour(reservations)
  //   let tempTotalComm = (subTotal * percent / 100) + (subFreetour * freetour)

  //   setTotalPage(Number(subTotal).toFixed(2))
  //   setTotalCommissionPage(Number(tempTotalComm).toFixed(2))

  //   // eslint-disable-next-line
  // }, [ reservations, percent, freetour ])

  // const toExport = () => {
  //   const data = []

  //   reservations.map(res => {
  //     res.activities.map(act =>  {
  //       let id = act.activity && act.activity.actId ? act.activity.actId : ''
  //       let title =  act.activity ? act.activity.title : ''
  //       let dateCreate = moment(act.createdAt).format('YYYY-MM-DD')
  //       let dateRealization = moment(act.schedule).format('YYYY-MM-DD')
  //       let [ people,  price ] = getPeoplePrice(act) || [ '', '' ]
  //       let campaign = act.cmp || ''
  //       let commission = Number((percent) * getPeoplePrice(act)[1]  / 100).toFixed(2)

  //       return data.push([ id, title, dateCreate, dateRealization, people, campaign, price, commission ])
  //     })

  //     return 0
  //   })

  //   const columns = [ 'ID', 'Producto','Fecha de reserva', 'Fecha realización', 'Viajeros', 'Campaña','Precio €',
  //     'Comisión €' ]

  //   setDataToExcel([ { columns, data } ])
  //   setTimeout(
  //     ()=> {setDataToExcel(null)}
  //     , 100
  //   )
  // }

  const getPeople = (priceGroups = [], total = 0) => {
    let people = 0
    // Get people, dont sum childs if coming from freetour
    for (let pg of priceGroups)
      for (let pre of pg.prices)
        if(!childCheck(pre.name) && total === 0) people += pre.people
        else if(total !== 0) people += pre.people

    return people
  }

  return (
    <Container>
      <div className='main-content reserves'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Reservas</h4>
              </div>
            </div>
          </div>
          { error ? <Error code={500} type={'server error'} /> : loading ?
            <Spinner />          :
            (<div>
              <div className='date-filter row mb-3'>
                <div className='col-md-3'>
                  <input
                    className='form-control'
                    defaultValue={month}
                    max={moment().format('yyyy-MM')}
                    min={moment().format('2020-07')}
                    onChange={({ target: { value } }) => _handleMonth(value)}
                    type='month' />
                </div>
                <div className='col-md-2'>
                  {/* <div className='dropdown'>
                    <button
                      aria-expanded='false' aria-haspopup='true' className='btn btn-bg-white dropdown-toggle'
                      data-toggle='dropdown' id='dropdownMenuButton'>
                      Ver por fecha de reserva <i className='mdi mdi-chevron-down' />
                    </button>
                    <div aria-labelledby='dropdownMenuButton' className='dropdown-menu'>
                      <a className='dropdown-item' href='/'>Ver por fecha de reserva</a>
                      <a className='dropdown-item' href='/'>Ver por tipo de reserva</a>
                      <a className='dropdown-item' href='/'>Ver por categorías</a>
                    </div>
                  </div> */}
                </div>
                {/* <div className='col-md-8 text-right'>
                  <div>
                    <button
                      className='btn btn-bg-white w-lg waves-effect waves-light'
                      onClick={toExport}>
                      <i className='icon-c save-alt' /> Exportar datos a excel
                    </button>
                    {
                      dataToExcel && <Export dataSet={dataToExcel} />
                    }
                  </div>
                </div> */}
              </div>
              <div className='row'>
                <div className='col-xl-3'>
                  <div className='card top-card'>
                    <div className='card-body'>
                      <div className='media'>
                        <div className='avatar-sm font-size-20 mr-3'>
                          <span className='avatar-title bg-soft-blue text-primary rounded'>
                            <i className='fa fa-list  text-soft-blue' />
                          </span>
                        </div>
                        <div className='media-body'>
                          <div className='font-size-16 mt-2'><span>Reservas totales</span></div>
                        </div>
                      </div>
                      <h4 className='mt-4 h4-c wh-sp-nw'>{ totalDocs }</h4>
                      <div className='row'>
                        <div className='col-7'>
                          <p className='mb-0'>
                            <span className='mr-2 span-c'>Clics: { myMonthClicks }</span>
                          </p>
                        </div>
                        <div className='col-5 text-right'>
                          <p className='mb-0'>
                            <span className='span-c'><span>{ conversionRate }%</span> CR</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card top-card'>
                    <div className='card-body'>
                      <div className='media'>
                        <div className='avatar-sm font-size-20 mr-3'>
                          <span className='avatar-title bg-soft-red text-primary rounded'>
                            <i className='fa fa-usd  text-soft-red ' />
                          </span>
                        </div>
                        <div className='media-body'>
                          <div className='font-size-16 mt-2'><span>Ventas totales</span></div>
                        </div>
                      </div>
                      <h4 className='mt-4 h4-c wh-sp-nw'>{ `${total.toFixed(2)} ${affiliateCurrency}` }</h4>
                      <div className='row'>
                        <div className='col-10'>
                          <p className='mb-0'>
                            <span className='span-c'>{ `${subTotal.toFixed(2)} ${affiliateCurrency}` } de comisión</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-9'>
                  <div className='card'>
                    <div className='card-body'>
                      <h4 className='card-title mb-4'>Estadísticas</h4>
                      <GraphicStats month={month} type={'day'} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card'>
                  <div className='card-body'>
                    <div className='table-responsive min-height-250'>
                      { tableLoading ? <Spinner /> : <>
                        <div className='row' style={{ marginRight: '0px' }}>
                          <div className='col-6'></div>
                          <div className='col-6 text-right font-weight-bold text-dark'>
                            <p>[Activa <i className='fa fa-circle text-dark'></i>] [Cancelada/No asistió <i className='fa fa-circle text-red'></i>] [Pendiente <i className='fa fa-circle text-danger'></i>]</p>
                          </div>
                        </div>
                        <table className='table allow-order mb-0 min-height-250'>
                          <thead>
                            <tr>
                              <th className='no-wrap order'>Fecha reserva</th>
                              <th className='no-wrap order'>Fecha realización</th>
                              <th className='no-wrap order order-dsc'>Reserva</th>
                              <th className='no-wrap'>Actividad</th>
                              {/* <th className='no-wrap order'>Asistió</th> */}
                              <th className='no-wrap order'>Personas</th>
                              <th className='no-wrap order'>Campaña</th>
                              <th className='no-wrap order'>Cupón</th>
                              <th className='no-wrap order'>Precio</th>
                              <th className='no-wrap order'>Comisión</th>
                            </tr>
                          </thead>

                          <tbody>
                            {reservations.map(({ _id, cancelled, details, createdAt, total, currency, paymentInformation }) => {
                              const { activity, priceGroups, cmp, serviceReservationId, date, userAttended } = details
                              let rTotal = total, cTotal = 0, activityPercent = 1, people = getPeople(priceGroups, total)
                              const { agency } = activity
                              if(agency && agency.contract && agency.contract.type === 'percent' && agency.contract.amount) activityPercent = agency.contract.amount
                              let currentPercent = activityPercent && activityPercent <= percent ? activityPercent : percent
                              if(currencies && affiliateCurrency && rTotal !== 0 && currency !== affiliateCurrency) rTotal = (total * getRate(currencies, currency, affiliateCurrency)).toFixed(2) || 0
                              cTotal = total !== 0 ? Number((rTotal) * currentPercent / 100).toFixed(2) : (userAttended ? defaultFreeTour * (freetour * (people ? people : 1)) : 0).toFixed(2)

                              return (
                                <tr className={cancelled || (total === 0 && !userAttended)  ? 'text-red' : !serviceReservationId ? 'text-danger' : ''} key={_id}>
                                  <td>{ moment(createdAt).format('DD-MM-YYYY') }</td>
                                  <td>{ moment(date * 1000).format('DD-MM-YYYY') }</td>
                                  <td>{serviceReservationId ? serviceReservationId : 'Pendiente' }</td>
                                  <td>{ activity && activity.huno ? activity.huno : 'Pendiente' }</td>
                                  {/* <td>{ total !== 0 ? 'N/A' : userAttended ? 'Si' : 'No' }</td> */}
                                  <td>{getPeoplePrice(priceGroups)[0] }</td>
                                  <td>{ cmp || 'S/C' }</td>
                                  <td>{ paymentInformation && paymentInformation.applyCoupon && paymentInformation.coupon ? paymentInformation.coupon.name : 'N/A' }</td>
                                  <td>{ rTotal + ` ${affiliateCurrency}`}</td>
                                  <td>{ cTotal + ` ${affiliateCurrency}`}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                          {/* <tfoot className='pt-fr'>
                            <tr className='table-red-light'>
                              <td colSpan={5} />
                              <td className='font-weight-bolder'>TOTAL</td>
                              <td className='font-weight-bolder'>{ totalPage }</td>
                              <td className='font-weight-bolder'>{ totalCommissionPage }</td>
                            </tr>
                          </tfoot> */}
                        </table>
                        { reservations && reservations.length ? <Pagination /> : null }
                      </>
                      }
                    </div>

                  </div>
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
    </Container>
  )
}

export default ReservesAffiliate
