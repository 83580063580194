import { all } from 'redux-saga/effects'

import reservations from './reservations'
import user from './user'

export default function* rootSaga() {
  yield all([
    ...reservations, ...user
  ])
}
