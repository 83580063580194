import React, { useEffect, useRef, useState } from 'react'
import { validateEmail } from '../../../utils'
// import { Link } from 'react-router-dom'
import Container from '..'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import es from 'react-phone-input-2/lang/es.json'
import { connect } from 'react-redux'
import actionTypes from '../../../actions/actionTypes'

const Contact = ({ user, ...props }) => {
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const messageRef = useRef(null)
  const subjectRef = useRef(null)
  const emailRef = useRef(null)
  const status = user.status
  const [ errors, setErrors ] = useState(null)

  const [ data, setData ] = useState({
    _id      : user._id || '',
    email    : user.email || '',
    firstName: user.firstName || '',
    lastName : user.lastName || '',
    message  : '',
    phone    : user.phone || '',
    subject  : '',
    token    : user.token || ''
  })

  const { message, firstName, lastName, phone, subject, email } = data

  const _handleChange = (e) => {
    setData({
      ...data,
      [e.target.id]: e.target.value
    })
  }

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top     : 0
    })
    let inputs = document.querySelectorAll('input[type="text"], input[type="email"], textarea')

    inputs.forEach(input => {
      if(input.value !== '')
        input.parentNode.classList.add('active')
    })
  }, [ ])

  const _handleFocus = (e) => {
    let elem = e.target.parentNode
    elem.classList.add('active')
  }

  const _handleBlur = (e) => {
    if(e.target.value === '') {
      let elem = e.target.parentNode
      elem.classList.remove('active')
    }
  }

  const _handleSendEmail = async (event) => {
    event.preventDefault()
    if(_validateFields())
      await props.sendEmail({ ...data })
  }

  const _validateFields = () => {
    setErrors(null)
    cleanRefs()
    let result = true
    if(firstName === '') {
      setErrors({ message: 'Ups, su nombre es obligatorio' })
      firstNameRef.current.classList.add('b-red')
      result = false
    }
    else {
      firstNameRef.current.classList.add('b-blue')
    }

    if(lastName === '') {
      setErrors({ message: 'Ups, su apellido es obligatorio' })
      lastNameRef.current.classList.add('b-red')
      result = false
    }
    else
    {lastNameRef.current.classList.add('b-blue')}

    if(message === '') {
      setErrors({ message: 'Ups, el mensage es obligatorio' })
      messageRef.current.classList.add('b-red')
      result = false
    }
    else
    {messageRef.current.classList.add('b-blue')}

    if(email === '' || !validateEmail(email)) {
      setErrors({ message: 'Ups, su email no es correcto' })
      emailRef.current.classList.add('b-red')
      result = false
    }
    else
    {emailRef.current.classList.add('b-blue')}

    if(subject === '') {
      setErrors({ message: 'Ups, el asunto es obligatorio' })
      subjectRef.current.classList.add('b-red')
      result = false
    }
    else
    {subjectRef.current.classList.add('b-blue')}

    return result
  }

  const cleanRefs = () => {
    firstNameRef.current.classList.remove('b-red')
    lastNameRef.current.classList.remove('b-red')
    messageRef.current.classList.remove('b-red')
    subjectRef.current.classList.remove('b-red')
    emailRef.current.classList.remove('b-red')

    firstNameRef.current.classList.remove('b-blue')
    lastNameRef.current.classList.remove('b-blue')
    messageRef.current.classList.remove('b-blue')
    subjectRef.current.classList.remove('b-blue')
    emailRef.current.classList.remove('b-blue')
  }

  const showErrors = () => {
    return  errors ? <h4 className='text-red' key={errors.message}>{ errors.message }</h4> : null
  }

  const showMessage = () => {
    return  status === 'EMAIL_SUCCESS' ? <h4 className='text-success text-center'>Email enviado exitosamente</h4> : null
  }

  const _handlePhone = (_, __, ___, p) => {
    const phoneArr = p.split(' ')
    const prefix = phoneArr[0]
    const phone = phoneArr.splice(1, phoneArr.length).join('')
    setData({
      ...data,
      phone: `${prefix} ${phone}`
    })
  }

  return (
    <Container>
      <div className='main-content contact'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Contacto</h4>
              </div>
            </div>
          </div>
          <div className='row modules'>
            <div className='col-xl-6 mr-4 pt-4'>
              <form action='#!' method='get'>
                <h2 className='mb-4 text-left'><i className='icon-c mail-gray mr-2' />Contacto</h2>
                <div className='personal-data'>
                  <div className='ci-wrapper mb-2 check-field'>
                    <label htmlFor='name'>Escribe tu nombre</label>
                    <input
                      id='firstName'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e)=>{_handleChange(e)}}
                      onFocus={(e)=>{_handleFocus(e)}}
                      ref={firstNameRef}
                      type='text'
                      value={firstName} />
                  </div>
                  <div className='ci-wrapper mb-2 check-field'>
                    <label htmlFor='surname'>Escribe tus apellidos</label>
                    <input
                      id='lastName'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e)=>{_handleChange(e)}}
                      onFocus={(e)=>{_handleFocus(e)}}
                      ref={lastNameRef}
                      type='text'
                      value={lastName} />
                  </div>
                  <div className='ci-wrapper mb-2 check-field'>
                    <label htmlFor='email'>E-mail</label>
                    <input
                      disabled
                      id='email'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e)=>{_handleChange(e)}}
                      onFocus={(e)=>{_handleFocus(e)}}
                      ref={emailRef}
                      type='email'
                      value={email} />
                  </div>
                  <div className='ci-wrapper mb-2 check-field active'>
                    <label htmlFor='phone'>Teléfono</label>
                    <PhoneInput
                      countryCodeEditable={false}
                      disableSearchIcon
                      enableSearch
                      inputClass={'react-tel-input user-phone '}
                      localization={es}
                      onChange={_handlePhone}
                      placeholder='Telefono'
                      searchPlaceholder='País'
                      value={phone} />
                  </div>
                  <div className='ci-wrapper mb-2 check-field'>
                    <label htmlFor='subject'>Asunto</label>
                    <input
                      id='subject'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e)=>{_handleChange(e)}}
                      onFocus={(e)=>{_handleFocus(e)}}
                      ref={subjectRef}
                      type='text'
                      value={subject} />
                  </div>
                  <div className='ci-wrapper mb-2 check-field'>
                    <label htmlFor='message'>Escriba su mensaje</label>
                    <textarea
                      id='message'
                      onBlur={(e)=>{_handleBlur(e)}}
                      onChange={(e)=>{_handleChange(e)}}
                      onFocus={(e)=>{_handleFocus(e)}}
                      ref={messageRef}
                      value={message} />
                  </div>
                  { showErrors() }
                  { showMessage() }
                  <div className='form-actions pt-3 text-right'>
                    <button
                      className='btn btn-primary font-weight-bolder col-md-5'
                      disabled={status === 'EMAIL_REQUEST' ? true : false}
                      onClick={(event)=>{_handleSendEmail(event)}}>
                      {status === 'EMAIL_REQUEST' ? 'Enviando...' : 'Enviar'}
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className='col-xl-5 mr-1 pt-4 text-center ctAf'>
              <div>
                <h2 className='mb-4 text-left'><i className='icon-c affiliates-ico mr-2' />Contacto Afiliados</h2>
                <div className='info mb-5'>
                  <img alt='ctAfimg' className='ctAfimg' src='/images/affiliates-image.jpg' />
                  <div className='mt-4 pt-2 text-left'>
                    <span className='icon-c clock-gray mr-3' />
                    <p>Estamos disponibles de Lunes a Domingo las 24 horas del día</p>
                  </div>
                  <div className='pt-3 text-left'>
                    <span className='icon-c phone-gray mr-3' />
                    <p>Teléfono de contacto: <span className='font-weight-bolder'>+34 670 727 067</span></p>
                  </div>
                  <div className='pt-3 text-left'>
                    <span className='icon-c mail2-gray mr-3' />
                    <p>Email: <span className='font-weight-bolder'>afiliados@vipealo.com</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (data) => dispatch({ data, type: actionTypes.EMAIL_REQUEST })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
