import React from 'react'
import imgError from '../../assets/images/error-img.png'
import { Link } from 'react-router-dom'

const Error = ({ code, type }) => {
  return (
    <div className='account-pages'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-md-8 col-lg-6 col-xl-5'>
            <div className='card overflow-hidden'>
              <div className='card-body'>
                <div className='text-center p-3'>
                  <div className='img'>
                    <img alt='' className='img-fluid' src={imgError} />
                  </div>

                  <h1 className='error-page mt-5'><span>{ code }!</span></h1>
                  <h4 className='mb-4 mt-5'>Disculpa, { type }</h4>
                  <p className='mb-4 w-75 mx-auto'>Ha ocurrido un error, intentar mas tarde o contactar soporte.</p>
                  <Link to='/afiliados/panel'>
                    <a className='btn btn-primary mb-4 waves-effect waves-light' href='index.html'><i className='mdi mdi-home' /> Volver al Panel</a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Error
