import React from 'react'
import Container from '..'
import { useSelector } from 'react-redux'

const Links = () => {
  const { aid } = useSelector(state => state.user.affiliate)
  const _handleChangeSection = (id) => {
    let parrs = document.querySelectorAll('.tab-pane')
    let title = document.querySelectorAll('.title')
    parrs.forEach((p, k) => {
      if(p.id === id) {
        title[k].classList.remove('text-dark')
        p.classList.add('active')
        p.classList.add('show')
      }
      else {
        title[k].classList.add('text-dark')
        p.classList.remove('active')
        p.classList.remove('show')
      }
    })

    let links = document.querySelectorAll('.nav-link')
    links.forEach(l => {
      if(l.id === `${id}-tab`)
        l.classList.add('active')

      else
        l.classList.remove('active')
    })
  }

  return (
    <Container>
      <div className='main-content links'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Enlaces</h4>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-3'>
              <div
                aria-orientation='vertical' className='nav flex-column nav-pills' id='v-pills-tab'
                role='tablist'>
                <span
                  aria-controls='v-pills-links'
                  aria-selected='true'
                  className='nav-link pt-4 pb-4 mb-2 active'
                  data-toggle='pill'
                  href='#v-pills-links'
                  id='v-pills-links-tab'
                  onClick={()=>{ _handleChangeSection('v-pills-links') }}
                  role='tab'>
                  <p className='title font-weight-bold mb-0'><i className='fa fa-link link-alt-gray mr-2' />Forma de enlazar con Vipealo</p>
                </span>
                <span
                  aria-controls='v-pills-context'
                  aria-selected='false'
                  className='nav-link pt-4 pb-4 mb-2'
                  data-toggle='pill'
                  id='v-pills-context-tab'
                  onClick={()=>{ _handleChangeSection('v-pills-context') }}
                  role='tab'>
                  <p className='title font-weight-bold text-dark mb-0'><i className='fa fa-bullhorn campaign-gray mr-2' />Distintos canales de promoción</p>
                </span>
                <span
                  aria-controls='v-pills-comparatives'
                  aria-selected='false'
                  className='nav-link pt-4 pb-4 mb-2'
                  data-toggle='pill'
                  id='v-pills-comparatives-tab'
                  onClick={()=>{ _handleChangeSection('v-pills-comparatives') }}
                  role='tab'>
                  <p className='title font-weight-bold text-dark mb-0'><i className='fa fa-thumbs-up like mr-2' /> Maximiza tus ventas</p>
                </span>
              </div>
            </div>
            <div className='col-lg-9 pl-2'>
              <div className='card min-hg'>
                <div className='card-body'>
                  <div className='tab-content' id='v-pills-tabContent'>
                    <div
                      aria-labelledby='v-pills-links-tab'
                      className='tab-pane fade show active'
                      id='v-pills-links'
                      role='tabpanel'>
                      <h1 className='mb-3'>Forma de enlazar con Vipealo</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>
                            ¡Muy fácil! Copia la URL de uno de nuestros productos y agrega tu identificador de afiliado al final <span className='font-weight-bold'>/?aid={aid}</span>
                          </p>
                          <mark className='text-muted  bg-soft-blue mb-2'>
                            https://www.vipealo.com/espana/barcelona<span className='font-weight-bold'>/?aid={aid}</span>
                          </mark>
                          <p className='text-muted'>
                            ¿Qué pasa si alguno de tus enlaces antiguos solo contiene <span className='font-weight-bold'>?aid=</span> sin la barra inclinada <span className='font-weight-bold'>(/)</span>? No pasa nada.
                            La cookie también saltará y sabrá que el usuario nos visita de tu parte. Si al redireccionar tampoco está,
                            la colocamos nosotros. ¡Muy fácil!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      aria-labelledby='v-pills-context-tab'
                      className='tab-pane fade'
                      id='v-pills-context'
                      role='tabpanel'>
                      <h1 className='mb-3'>Distintos canales de promoción</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>
                            Nuestro avanzado sistema sabe distinguir la fuente de ventas a través de diferentes canales
                            (email marketing, redes sociales, internet ...). Todo lo que tiene que hacer es agregar <span className='font-weight-bold'>&cmp=nombredetucampaña</span> para distinguir las ventas y el nombre.
                          </p>
                          <mark className='text-muted bg-soft-blue mt-2 p-2'>https://www.vipealo.com/espana/barcelona<span className='font-weight-bold'>/?aid={aid}&amp;cmp=facebook</span></mark>
                        </div>
                      </div>
                    </div>
                    <div
                      aria-labelledby='v-pills-comparatives-tab' className='tab-pane fade' id='v-pills-comparatives'
                      role='tabpanel'>
                      <h1 className='mb-3'>Maximiza tus ventas</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>
                            Si queremos aumentar las ventas, debemos saber a qué producto debemos dirigir nuestro tráfico.
                          </p>
                          <p className='text-muted'>
                            Si en tu blog hablas, por ejemplo, sobre qué ver en Portugal, en la publicación podrías añadir la frase:
                          </p>
                          <p className='text-muted'>
                            <mark className='text-muted bg-soft-blue pt-1 pb-1'>
                              “Aquí te recomendamos el mejor tour de Portugal en español para que conozcas a fondo la ciudad lisboa“
                            </mark>
                          </p>
                          <p className='text-muted'>
                            Además, si visitas nuestro sitio web, puedes encontrar la actividad en la sección de actividades de Lisboa:
                          </p>
                          <p className='text-muted'>
                            <mark className='text-muted bg-soft-blue pt-1 pb-1'>
                              https://www.vipealo.com/portugal/lisboa
                            </mark>
                          </p>
                          <p className='text-muted'>
                            Al final el enlace de vuestra web es el mismo pero añadiendo el parámetro: <span className='font-weight-bold'>/?aid={aid}</span>, entonces se vería:
                          </p>
                          <mark className='text-muted bg-soft-blue pt-1 pb-1'>
                            https://www.vipealo.com/portugal/lisboa/excursion-privada-a-sintra-y-cascais<span className='font-weight-bold'>/?aid={aid}</span>
                          </mark>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Links
