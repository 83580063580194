import React, { useEffect,  useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import actionTypes from '../../../actions/actionTypes'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone'

const { REACT_APP_LOGO } = process.env

const Header = ({ setOpenMenu, openMenu, user, ...props }) => {
  const { firstName = '', notifications, affiliate: { aid } } = user
  const header = useRef()
  const notificRef = useRef()
  const userMenuRef = useRef()
  const history = useHistory()
  const [ notificationsCount, setNotificationsCount ] = useState(0)
  const avatar = user.affiliate.avatar ? user.affiliate.avatar : process.env.REACT_APP_DEFAULT_AVATAR

  useEffect(() => {
    const count = notifications.filter((notification) => !notification.read).length
    setNotificationsCount(count)
  }, [ notifications ])

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside)
    // eslint-disable-next-line
  }, [])

  const clickOutside = (event) => {
    if(notificRef && notificRef.current && !notificRef.current.contains(event.target) &&
    userMenuRef && userMenuRef.current && !userMenuRef.current.contains(event.target)
    )
      removeMenu(event)
  }

  const removeMenu = () => {
    let menus = document.querySelectorAll('.menu-header')
    menus.forEach(menu => {
      menu.classList.remove('show')
    })
  }

  const _handleShowMenu = (e, menu) => {
    e.preventDefault()
    if(menu === 'main-menu') {
      setOpenMenu(!openMenu)

      return
    }
    document.querySelector(`#${menu}`).classList.toggle('show')
  }

  const _handleLogOut = () => {
    props.logout(history)
  }

  const _dateAgo = (date) => {
    let checkTz = moment.tz.guess(), fromDate = moment(date).tz(checkTz)
    const result = moment(fromDate, 'YYYY-MM-DD hh:mm:ss').fromNow()

    return result
  }

  const _markNotification = (e, id) => {
    e.preventDefault()
    const notification = notifications.find((notification) => notification._id === id)
    if(!notification.read)
      props.markNotification(history, id)
    else
      history.push('/afiliados/reservas')
  }

  const openTab = () => { window.open(`https://www.vipealo.com?aid=${aid}`) }

  return (
    <header id='page-topbar' ref={header}>
      <div className='navbar-header'>
        <div className='container-fluid'>
          <div className='float-right'>
            <div className='dropdown d-inline-block'>
              <button
                aria-expanded='false'
                aria-haspopup='true'
                className='btn header-item noti-icon waves-effect'
                data-toggle='dropdown'
                id='page-header-notifications-dropdown' onClick={(e) => { _handleShowMenu(e, 'notificationsMenu') }}>
                <i className='fa fa-bell' />
                <span className='badge badge-danger badge-pill'>{notificationsCount}</span>
              </button>
              <div
                aria-labelledby='page-header-notifications-dropdown'
                className='dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 menu-header'
                id='notificationsMenu'
                ref={notificRef}>
                <div className='p-3'>
                  <div className='row align-items-center'>
                    <div className='col'>
                      <h6 className='m-0'>Notificaciones</h6>
                    </div>
                    <div className='col-auto'>
                      <Link className='small' to={'/afiliados/nofiticaciones'}>Ver todas</Link>
                    </div>
                  </div>
                </div>
                <div data-simplebar>
                  {notifications && notifications.map((notification) => (
                    <Link
                      className='text-reset notification-item' key={notification._id} onClick={(e) => { _markNotification(e, notification._id) }}
                      to={'/afiliados/reservas'}>
                      <div className='media' >
                        <div className='media-body'>
                          <h6 className='mt-0 mb-1'>{notification.title}</h6>
                          <div className='font-size-12 text-muted'>
                            <p className='mb-1'>{notification.message}</p>
                            <p className='mb-0'><i className='mdi mdi-clock-outline' />{_dateAgo(notification.createdAt)}</p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
                <div className='p-2 border-top'>
                  <Link className='btn btn-sm btn-link font-size-14 btn-block text-center' to={'/afiliados/nofiticaciones'}>
                    <i className='mdi mdi-arrow-right-circle mr-1' /> Ver más..
                  </Link>
                </div>
              </div>
            </div>
            <div className='header-item d-inline-flex flex-row' onClick={(e) => { _handleShowMenu(e, 'userMenu') }} style={{ cursor: 'pointer', paddingRight: '5px' }}>
              <div className=''> <img alt={firstName} className='rounded-circle header-profile-user' src={avatar} /> </div>
              <div className=''>
                <div className='d-flex flex-row mb-1'>
                  <span className='d-none d-xl-inline-block ml-1'>{firstName}</span>
                </div>
                <div> <span className='d-none d-xl-inline-block ml-1'>{`ID ${aid}`}</span> <i className='arrow-down' /></div>
              </div>
              <div className='dropdown-menu dropdown-menu-right menu-header' id='userMenu' ref={userMenuRef}>
                <Link className='dropdown-item' to={'/afiliados/mi-cuenta'}>
                  <i className='bx bx-user font-size-16 align-middle mr-1' /> Mi cuenta
                </Link>
                <Link className='dropdown-item' to={'/afiliados/datos-de-pago'}>
                  <i className='bx bx-wallet font-size-16 align-middle mr-1' /> Mis datos de pago
                </Link>
                <Link className='dropdown-item' to={'/afiliados/datos-de-acceso'}>
                  <i className='bx bx-lock-open font-size-16 align-middle mr-1' /> Mis datos de acceso
                </Link>
                <Link className='dropdown-item' onClick={openTab} to={'#'}>
                  <i className='bx bx-trending-up font-size-16 align-middle mr-1' /> Visitar Vipealo
                </Link>
                <div className='dropdown-divider' />
                <div className='dropdown-item' onClick={_handleLogOut}>
                  <i className='bx bx-power-off font-size-16 align-middle mr-1 text-danger' /> Salir
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='navbar-brand-box'>
              <Link className='logo logo-dark' to='/'>
                <span className='logo-sm'>
                  <img alt='' height={20} src={REACT_APP_LOGO} />
                </span>
                <span className='logo-lg'>
                  <img alt='' height={30} src='assets/images/logo-dark.png' />
                  <span className='uT'>Afiliados</span>
                </span>
              </Link>
              <Link className='logo logo-light' to={'/'}>
                <span className='logo-sm'>
                  <img alt='' height={20} src={REACT_APP_LOGO} />
                </span>
                <span className='logo-lg'>
                  <img alt='' height={30} src={REACT_APP_LOGO} />
                  <span className='uT'>Afiliados</span>
                </span>
              </Link>
            </div>
            <button
              className='btn btn-sm px-3 font-size-16 header-item toggle-btn waves-effect'
              id='vertical-menu-btn'
              onClick={(e)=>{_handleShowMenu(e, 'main-menu')}}
              type='button' >
              <i className='fa fa-fw fa-bars' />
            </button>
          </div>
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    logout          : (history) => dispatch({ history, type: actionTypes.LOGOUT }),
    markNotification: (history, notificationId) => dispatch({ history, notificationId, type: actionTypes.MARK_NOTIFICATION })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
