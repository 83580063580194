import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Container from '..'
import dotenv from 'dotenv'
dotenv.config()

const HelpAffiliate = () => {
  const toggleShow = (elem, title) => {
    if(elem && title) {
      document.querySelector(`#${elem}`).classList.toggle('show')
      document.querySelector(`#${title}`).classList.toggle('collapsed')
    }
  }

  const { affiliate: { aid }, settings } = useSelector(state => state.user)

  return (
    <Container>
      <div className='main-content need-help'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Preguntas frecuentes</h4>
              </div>
            </div>
          </div>
          <div className='wrapper row pl-3'>
            <div className='col-lg-12 p-3' id='accordion'>
              <h4 className='card-title mb-4'>A continuación te resolvemos las dudas más comunes sobre nuestra plataforma.</h4>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingOne'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapseOne'
                      aria-expanded='true'
                      className='d-block font-weight-bold text-dark c-pointer'
                      data-toggle='collapse'>
                      Vender con Vipealo
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingOne' className='collapse show' data-parent='#accordion'
                  id='collapseOne'>
                  <div className='card-body section-info'>
                    <div className='need-help-grid'>
                      <div className='grid-cel'>
                        <div className='need-help-icons link-to-vipealo lg-icons text-light-blue'>
                          <i className='fa fa-link'></i>
                        </div>
                        <div>
                          <h5 className='link-appearance'>Linkeanos</h5>
                          <p>Solo necesitas agregar tu ID al enlace para comenzar a usar todos los recursos que tenemos.</p>
                        </div>
                      </div>
                      <div className='grid-cel'>
                        <div className='need-help-icons link-to-vipealo lg-icons text-light-blue'>
                          <i className='fa fa-line-chart'></i>
                        </div>
                        <div>
                          <h5 className='link-appearance'>Haz más visible tu web</h5>
                          <p>Comienza con artículos que generen más clics</p>
                        </div>
                      </div>
                      <div className='grid-cel'>
                        <div className='need-help-icons link-to-vipealo lg-icons text-light-blue'>
                          <i className='fa fa-calendar-check-o'></i>
                        </div>
                        <div>
                          <h5 className='link-appearance'>{`Obtén hasta un  ${settings && settings.afiliados ? settings.afiliados : '15'}% de comisión`}</h5>
                          <p>Una vez que tus usuarios reserven, comenzarás a cobrar comisiones por todas las ventas que generes.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingTwo'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-2' aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-2'
                      onClick={()=>{ toggleShow('collapse-2', 'title-2') }}>
                      Enlaza tu web a Vipealo
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingTwo' className='collapse' data-parent='#accordion'
                  id='collapse-2'>
                  <div className='card-body section-info'>
                    <p>¡Facilísimo! Copia la URL de uno de nuestros productos y agrega tu identificador de afiliado al final <strong>/?aid={aid}</strong></p>
                    <mark className='text-muted  bg-soft-blue mb-2'>
                      https://www.vipealo.com/espana/barcelona<span className='font-weight-bold'>/?aid={aid}</span>
                    </mark>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-3'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-3'
                      onClick={()=>{ toggleShow('collapse-3', 'title-3') }}>
                      No te preocupes si el ID desaparece de tu enlace: ¿qué ha pasado?
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-3'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>Cuando un cliente ingresa a Vipealo a través de tu web, obtenemos tu número de ID a través de su cookie por un período de 30 días. En este plazo de tiempo,
                      incluso si el enlace con el tu código ya no se muestra (?aid={aid}), seguirás recibiendo la comisión generada por las ventas asociadas a este usuario..</p>
                    <p className='mb-2'>De modo que nuestra cookie se guarda desde el primer clic que hace el usuario antes incluso de redirigir. ¡Estate tranquilo! Cobrarás comisiones por todas
                      las compras realizadas por los clientes en Vipealo durante ese mes en curso.</p>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-4'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-4'
                      onClick={()=>{ toggleShow('collapse-4', 'title-4') }}>
                      Recursos de los que dispones para generar ventas
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-4'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>Disponemos de una amplia gama de recursos, para que encuentres el recurso que mejor se adapte a tus necesidades.</p>
                    <p className='mb-2'>Haz clic en la sección “Enlaces y más recursos” para encontrar todos los que necesitas, además de tu ID de enlace y una breve explicación sobre cómo usarlo.</p>
                    <p className='mb-2'>También verás diferentes formatos y diseños de banners, widgets y recursos multimedia.</p>
                  </div>
                </div>
              </div>
              {/* <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-5'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse'
                      onClick={()=>{ toggleShow('collapse-5') }}>
                      ¿Cómo integro en mi web o blog  la API?
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-5'>
                  <div className='card-body section-info'>
                    <p>Contacta con nosotros a través de un feed y una API e integra nuestros productos en tu web. Escríbenos a
                      <a className='link-appearance' href={`mailto:${process.env.REACT_APP_EMAIL_AFFFILIATE}?subject=Ayuda%20afiliado`}> afiliados@vipealo.com </a>
                       si necesitas más información sobre cómo hacer una integración.</p>
                  </div>
                </div>
              </div> */}
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-6'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-6'
                      onClick={()=>{ toggleShow('collapse-6', 'title-6') }}>
                      Todo lo que necesitas saber sobre las comisiones
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-6'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>{`Como afiliado recibirás hasta un ${settings && settings.afiliados ? settings.afiliados : '15'}% de comisión por cada venta realizada.`}</p>
                    {/* <ul className='list-unstyled'>
                      <li>- Si las ventas llegan a XXXX€: XX% de comisión.</li>
                      <li>- Si las ventas están entre xXXX€ y XXXX€: x% de comisión.</li>
                      <li>- Si las ventas son más de XXXXX€: x% de comisión.</li>
                    </ul> */}
                    <p className='mb-2'>{`Además, cobrarás una comisión fija de ${settings && settings.afiliados_free_tour ? settings.afiliados_free_tour : '1'} € por cada free tour vendido.`}</p>
                    <p className='mb-2'>¡Regístrate y empieza a ganar dinero! Las comisiones se pagan un mes después de la ejecución del servicio.</p>
                    <p>Nuestra tasa de conversión te garantiza que pronto puedes empezar a ganar comisiones.</p>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-7'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-7'
                      onClick={()=>{ toggleShow('collapse-7', 'title-7') }}>
                      Conoce los detalles de tus ventas
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-7'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>Podrás saber qué es lo que más vendes accediendo en el menú de la izquierda, a los siguientes apartados:</p>
                    <ul>
                      <li><span className='font-weight-bold'>Reservas:</span> sabrás en tiempo real todos los datos relacionados con las reservas las comisiones generadas, los productos vendidos y, por supuesto, el importe de cada uno.</li>
                      <li><span className='font-weight-bold'>Estadísticas:</span> de un vistazo podrás conocer el número de clics, la tasa de conversión, las páginas que generan ventas y mucha más información. Gracias a esta información podrás analizar tu situación y ser más eficiente.</li>
                      <li><span className='font-weight-bold'>Facturación:</span> en este apartado verás las reservas que ya han sido realizadas y la comisión pendiente de facturación que te corresponde.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-8'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-8'
                      onClick={()=>{ toggleShow('collapse-8', 'title-8') }}>
                      ¿Y si un usuario cancela la reserva o no acude al free tour?
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-8'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>Deduciremos tu comisión si el cliente cancela su compra dentro del periodo de cancelación permitido. En el caso de que un
                    cliente cancele o no asista al free tour también se te deducirá la comisión recibida.</p>
                    <p>Estarás al tanto de cuándo se ha cancelado una venta o no haya acudido el cliente al free tour porque en el panel te aparecerá en un color distinto.</p>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-9'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-9'
                      onClick={()=>{ toggleShow('collapse-9', 'title-9') }}>
                        Pon a punto tu método de cobro y empieza a cobrar tus comisiones
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-9'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>Utilizamos la autofacturación proactiva. Solo debes marcar esta opción en la sección <Link to={'/afiliados/datos-de-pago'}>Mis datos de pago</Link> y comprobar que la información de facturación y
                    el método de pago están completos. Cuando actualices toda la información, se generará automáticamente una factura. Una vez que se complete el proceso, recibirás un email en el
                    correo electrónico que te permitirá hacer un seguimiento de tu factura en el apartado de <Link to={'/afiliados/facturacion'}>Facturación</Link>.</p>
                    {/* <p className='mb-2'>Con la autofactura activa solo tienes que asegurarte de marcar la opción en el apartado &nbsp;
                      <Link to={'/afiliados/datos-de-pago'}>Mis datos de pago</Link>
                      . Revisa que estén perfectamente completados tanto los datos de facturación, como la forma de pago. Cuando esté toda la información actualizada
                      se generará una autofactura automática. Una vez terminado el proceso, te llegará una notificación al correo lo que
                      te permitirá darle seguimiento a tu autofactura en la sección &nbsp;
                      <Link to={'/afiliados/facturacion'}>Facturación</Link>:</p> */}
                    <p className='mb-2'>No olvides que las comisiones se pagan mensualmente y las recibirás bajo las siguientes condiciones:</p>
                    <ul>
                      <li>La autofactura se genera automáticamente el día 15 de cada mes.</li>
                      <li>Las comisiones que se incluyen se refieren a los servicios realizados durante el mes anterior, independientemente de la fecha de la reserva.</li>
                      <li>Se debe alcanzar como mínimo 50€ en comisiones para generar autofactura. Pero ¡no te preocupes! Si no lo consigues, se quedan acumulados para el próximo mes.</li>
                    </ul>
                    <p>Si sigues teniendo alguna duda sobre tus facturas o quieres enviar tus facturas de forma personal comunícate con nosotros en &nbsp;
                      <a className='link-appearance' href={`mailto:${process.env.REACT_APP_EMAIL_AFFFILIATE}?subject=ayuda%20Afiliado`}>afiliados@vipealo.com.</a></p>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-10'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-10'
                      onClick={()=>{ toggleShow('collapse-10', 'title-10') }}>
                      Completa todos tus datos de pago para la factura
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-10'>
                  <div className='card-body section-info'>
                    <p className='mb-2'>
                      Debes introducir tanto nuestros datos como los tuyos: tu nombre o razón social, identificación y la dirección fiscal.
                      También es importante agregar tus datos bancarios o tu cuenta paypal para que podamos realizarte el pago.
                    </p>
                    <p className='font-weight-bold mb-2'>Estos son nuestros datos de facturación: </p>
                    <p className='mb-2'>Vipealo S.L</p>
                    <p className='mb-2'>NIF: B88579404</p>
                    <p className='mb-2'>Domicilio fiscal: Calle de José de Abascal 56, segundo piso oficina 200. 28803 Madrid.</p>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-11'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-11'
                      onClick={()=>{ toggleShow('collapse-11', 'title-11') }}>
                      La importancia de tener factura
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-11'>
                  <div className='card-body section-info'>
                    <p>Las facturas son necesarias para que podamos declarar como empresa los pagos que hemos recibido. Por tanto, es necesario
                      que nos la envíes. Somos conscientes de que las normas fiscales variarán de un afiliado a otro. Te recomendamos que busques
                      asesoramiento para que nos puedas presentar todos los documentos en los que se muestre tu actividad fiscal correctamente.
                    </p>
                  </div>
                </div>
              </div>
              <div className='card mb-1 shadow-none'>
                <div className='card-header' id='headingThree'>
                  <h6 className='m-0'>
                    <span
                      aria-controls='collapse-11'
                      aria-expanded='false'
                      className='d-block font-weight-bold text-dark collapsed c-pointer'
                      data-toggle='collapse' id='title-12'
                      onClick={()=>{ toggleShow('collapse-12', 'title-12') }}>
                      ¡Contacta con nosotros siempre que quieras!
                    </span>
                  </h6>
                </div>
                <div
                  aria-labelledby='headingThree' className='collapse' data-parent='#accordion'
                  id='collapse-12'>
                  <div className='card-body section-info'>
                    <p>¡Muy fácil! Puedes hacerlo dirigiéndote al apartado de &nbsp;
                      <Link className='link font-weight-bolder' to='/afiliados/contacto'>
                        Contacto
                      </Link>
                      , o enviándonos un email a
                      <a className='link-appearance' href={`mailto:${process.env.REACT_APP_EMAIL_AFFFILIATE}?subject=Ayuda%20afiliado`}> afiliados@vipealo.com.</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default HelpAffiliate
