import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ path, component, ...rest }) => {
  const user = useSelector(state => state.user)
  if(user.token)
    return <Route component={component} path={path} {...rest} />

  return <Redirect to={'/'} {...rest} />
}

export default PrivateRoute
