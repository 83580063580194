import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import actionTypes from '../../actions/actionTypes'
import { useHistory } from 'react-router-dom'
import { withRouter, Link } from 'react-router-dom'

const Create = ({ user, ...props }) => {
  const [ firstName, setFirstName ] = useState('')
  const [ lastName, setLastName ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ generalConditions, setGeneralConditions ] = useState(false)
  const [ errors, setErrors ] = useState({})
  const [ loading, setloading ] = useState(false)
  const history = useHistory()
  const alertRef = useRef(null)
  const generalConditionsRef = useRef(null)

  useEffect(()=>{
    user.status === 'Create user error' ? _handleAlert('add') : _handleAlert()
  }, [ user ])

  const handleSave = (event) => {
    event.preventDefault()
    if(!formIsValid()) return
    setloading(true)
    props.createUser({ email, firstName, lastName, password }, history)
    setloading(false)
  }

  const formIsValid = () => {
    const errors = {}
    if(!email) errors.email = 'Email requerido'
    if(!password) errors.password = 'Contraseña requerida'
    if(!generalConditions) errors.generalConditions = 'Debe aceptar las condiciones Generales'
    if(password !== confirmPassword)
      errors.password = 'Contraseñas no coinciden'
    else if(password.length < 7)
      errors.password = 'Las contraseñas deben poseer al menos 7 caracteres'
    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const _handleAlert = (action) => {
    if(action === 'add')
      alertRef.current.classList.add('show')
    else alertRef.current.classList.remove('show')
  }

  const _handleGeneralConditions = e => {
    e.target.parentNode.classList.toggle('checked')
    setGeneralConditions(!generalConditions)
  }

  return (
    <div className='d-flex justify-content-center align-items-center h-100vh'>
      <div className='alert alert-credentials alert-danger alert-dismissible fade' ref={alertRef} role='alert'>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='alert'
          onClick={() => {_handleAlert('remove')}}
          type='button'>
          <span aria-hidden='true'>&times;</span>
        </button>
        El email proporcionado ya existe.
      </div>
      <div className='container b-shadow-all mt-5'>
        <div className='card  top-card m-3 mt-0'>
          <div className='card-body'>
            <div className='media'>
              <div className='media-body card-header p-1 mb-4'>
                <h2 className=' mt-2 text-center text-darki'>Registro</h2>
              </div>
            </div>
            <div className='col-md-12'>
              <form onSubmit={(event) => handleSave(event)}>
                <div className='form-group'>
                  <label htmlFor='firstName'>Escribe tu nombre:</label>
                  <input
                    aria-describedby='firstNameHelp' className='form-control' id='firstName'
                    onChange={(event) => setFirstName(event.target.value)} placeholder='nombre'
                    type='firstName' />
                </div>
                <div className='form-group'>
                  <label htmlFor='lastName'>Escribe tus apellidos:</label>
                  <input
                    aria-describedby='lastNameHelp' className='form-control' id='lastName'
                    onChange={(event) => setLastName(event.target.value)} placeholder='apellidos'
                    type='lastName' />
                </div>
                <div className='form-group'>
                  <label htmlFor='email'>Correo electrónico:</label>
                  <input
                    aria-describedby='emailHelp' className='form-control' id='email'
                    onChange={(event) => setEmail(event.target.value)} placeholder='usuario@test.com'
                    type='email' />
                </div>
                {errors.email && (
                  <div className='alert alert-danger' role='alert'>
                    {errors.email}
                  </div>
                )}
                <div className='form-group'>
                  <label htmlFor='password'>Contraseña:</label>
                  <input
                    className='form-control' id='password' onChange={(event) => setPassword(event.target.value)}
                    placeholder='Contraseña'
                    type='password' />
                </div>
                <div className='form-group'>
                  <input
                    className='form-control' id='confirmPassword' onChange={(event) => setConfirmPassword(event.target.value)}
                    placeholder='Confirma tu contraseña'
                    type='password' />
                </div>
                {errors.password && (
                  <div className='alert alert-danger' role='alert'>
                    {errors.password}
                  </div>
                )}
                <div className='form-group'>
                  <label className='cb-label mr-2 col-md-6'>
                    <span className='custom-checkbox' />
                    <input
                      name='newsletter'
                      onChange={(e)=>{_handleGeneralConditions(e)}}
                      ref={generalConditionsRef}
                      type='checkbox' />
                    <Link target='_blank' to='/afiliados/registrarse/condiciones-generales'>Condiciones Generales de Afiliados</Link>
                  </label>
                </div>
                {errors.generalConditions && (
                  <div className='alert alert-danger' role='alert'>
                    {errors.generalConditions}
                  </div>
                )}
                <div className='text-center'>
                  <button
                    className='btn btn-primary' disabled={loading} style={{ marginRight: '5px' }}
                    type='submit'>
                    {loading ? 'Verificando...' : 'Registrarme'}
                  </button>
                  <Link className='btn btn-primary' to='/'>Volver</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps =  (dispatch) => {
  return {
    createUser: (user, history) => dispatch({ history, type: actionTypes.CREATE_USER, user })
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create))
