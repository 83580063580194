import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import rootReducer from '../reducers/rootReducer'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import rootSaga from '../sagas'
const persistConfig = {
  key: 'affiliates',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const composeArgs = [
    applyMiddleware(sagaMiddleware)
  ]

  if(window && window.__REDUX_DEVTOOLS_EXTENSION__)
    composeArgs.push(window.__REDUX_DEVTOOLS_EXTENSION__())

  const store = createStore(
    persistedReducer,
    compose.apply(undefined, composeArgs)
  )
  let persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { persistor, store }
}
