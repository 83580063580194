import React from 'react'
import Container from '..'
import GeneralC from '../../GeneralConditions'

const GeneralConditions = () => {
  return (
    <Container>
      <GeneralC />
    </Container>
  )
}

export default GeneralConditions
