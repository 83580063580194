import React from 'react'
import { getRate } from '../../utils'

const StatsMain = ({ affiliateCurrency, conversionRate, currencies, defaultFreeTour, onChange, freetour, percent, data = [], type }) => {
  const _handleShowDetails = (reservations, activities, typeData, pos, subpos) => {
    onChange(true, reservations, activities, typeData, pos, subpos)
  }
  const totalClicks = data.reduce((a,b) => a + b.reservations.reduce((a,b) => a + b.details.clicks,0),0)

  return (
    <table className='table allow-order mb-0 min-height-250'>
      <thead>
        <tr>
          {type === 'reservation' ?
            <th className='no-wrap order'>Fecha reserva</th> :
            <th className='no-wrap order'>Fecha realización</th>
          }
          <th className='no-wrap order'>Reservas</th>
          {type === 'reservation' && (<th className='no-wrap order'>Clics</th>)}
          {type === 'reservation' && (<th className='no-wrap order'>Conversión</th>)}
          <th className='no-wrap order'>Importe</th>
          <th className='no-wrap order'>Comisión</th>
          <th className='no-wrap order'>Detalles</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ _id, reservations, activities }, key) => {
          const reservationsTotal = reservations.reduce((a,b) => {
            const { userAttended, serviceReservationId } = b.details, { people } = b.summary
            let bTotal = 0
            a.totalPeople = a.totalPeople + (b.summary && b.summary.people ? b.summary.people : 1)
            a.totalClicks = a.totalClicks + b.details.clicks
            a.totalImport = a.totalImport + (b.total * getRate(currencies, b.currency, affiliateCurrency))
            // const { userAttended } = b.details, { people } = b.summary
            // const bTotal = b.total !== 0 ? (b.total * getRate(currencies, b.currency, affiliateCurrency)) * percent / 100 : userAttended ? defaultFreeTour * (freetour * (people ? people : 1)) : 0
            const freeTourCalc = defaultFreeTour * (freetour * (people ? people : 1)) * (userAttended ? 1 : 0)
            const totalCalc = (b.total * getRate(currencies, b.currency, affiliateCurrency)) * percent / 100
            if(serviceReservationId) bTotal = b.cancelled || !serviceReservationId ? 0 : b.total === 0 ? freeTourCalc : totalCalc
            a.totalCommission = a.totalCommission + bTotal
            a.totalConversion = a.totalConversion + 1

            return a
          },{ totalClicks: 0, totalCommission: 0, totalConversion: 0, totalImport: 0, totalPeople: 0 })
          const conversionPerRow = (reservationsTotal.totalClicks * Number(conversionRate)) / totalClicks

          return (
            <>
              {reservations.length > 0 && (
                <tr id={_id + key + '0'} key={_id + 'R'}>
                  <td>{ _id }</td>
                  <td>{ reservations.length }</td>
                  {type === 'reservation' && (<td>{ reservationsTotal.totalClicks }</td>)}
                  {type === 'reservation' && (<td>{ conversionPerRow.toFixed(2) + ' %' }</td>)}
                  <td>{Number(reservationsTotal.totalImport).toFixed(2)} {affiliateCurrency}</td>
                  <td>{Number(reservationsTotal.totalCommission).toFixed(2)} {affiliateCurrency}</td>
                  <td>
                    <button
                      className={'btn btn-primary'} onClick={() => _handleShowDetails(reservations, activities, 'reservation', conversionPerRow)}><i className='fa fa-list-alt' />
                      Detalles
                    </button>
                  </td>
                </tr>
              )}
              {type === 'reservation' && activities.length && (
                <tr id={_id + key + '1'} key={_id + 'A'}>
                  <td>{ _id }</td>
                  <td>{ 0 }</td>
                  <td>{ activities.reduce((a,b) => a + b.clicks, 0) }</td>
                  <td>{ 0 + ' %' }</td>
                  <td>{ 0 } {affiliateCurrency}</td>
                  <td>{ 0 } {affiliateCurrency}</td>
                  <td>
                    <button
                      className={'btn btn-primary'} onClick={() => _handleShowDetails(reservations, activities, 'activity', 0)}><i className='fa fa-list-alt' />
                      Detalles
                    </button>
                  </td>
                </tr>
              )}
            </>
          )
        })}
      </tbody>
    </table>
  )
}

export default StatsMain
