import React, { useState } from 'react'
import Container from '..'
import { Modal, Image } from 'react-bootstrap'

var listCampaigns = {
  'campania-2': {
    '120x450' : '/banners/campaña-2/120x450.gif',
    '120x60'  : '/banners/campaña-2/120x60.gif',
    '120x600' : '/banners/campaña-2/120x600.gif',
    '120x90'  : '/banners/campaña-2/120x90.gif',
    '150x150' : '/banners/campaña-2/150x150.gif',
    '160x600' : '/banners/campaña-2/160x600.gif',
    '180x150' : '/banners/campaña-2/180x150.gif',
    '200x200' : '/banners/campaña-2/200x200.gif',
    '234x60'  : '/banners/campaña-2/234x60.gif',
    '240x400' : '/banners/campaña-2/240x400.gif',
    '250x250' : '/banners/campaña-2/250x250.gif',
    '300x1050': '/banners/campaña-2/300x1050.gif',
    '300x250' : '/banners/campaña-2/300x250.gif',
    '300x600' : '/banners/campaña-2/300x600.gif',
    '320x100' : '/banners/campaña-2/320x100.gif',
    '320x50'  : '/banners/campaña-2/320x50.gif',
    '336x280' : '/banners/campaña-2/336x280.gif',
    '428x60'  : '/banners/campaña-2/428x60.gif',
    '468x60'  : '/banners/campaña-2/468x60.gif',
    '728x90'  : '/banners/campaña-2/728x90.gif',
    '88x31'   : '/banners/campaña-2/88x31.gif',
    '970x250' : '/banners/campaña-2/970x250.gif',
    '970x90'  : '/banners/campaña-2/970x90.gif'
  },
  'campania-vipealo': {
    '120x450' : '/banners/campaña-vipealo/120x450.gif',
    '120x60'  : '/banners/campaña-vipealo/120x60.gif',
    '120x600' : '/banners/campaña-vipealo/120x600.gif',
    '120x90'  : '/banners/campaña-vipealo/120x90.gif',
    '150x150' : '/banners/campaña-vipealo/150x150.gif',
    '160x600' : '/banners/campaña-vipealo/160x600.gif',
    '180x150' : '/banners/campaña-vipealo/180x150.gif',
    '200x200' : '/banners/campaña-vipealo/200x200.gif',
    '234x60'  : '/banners/campaña-vipealo/234x60.gif',
    '240x400' : '/banners/campaña-vipealo/240x400.gif',
    '250x250' : '/banners/campaña-vipealo/250x250.gif',
    '300x1050': '/banners/campaña-vipealo/300x1050.gif',
    '300x250' : '/banners/campaña-vipealo/300x250.gif',
    '300x600' : '/banners/campaña-vipealo/300x600.gif',
    '320x100' : '/banners/campaña-vipealo/320x100.gif',
    '320x50'  : '/banners/campaña-vipealo/320x50.gif',
    '336x280' : '/banners/campaña-vipealo/336x280.gif',
    '428x60'  : '/banners/campaña-vipealo/428x60.gif',
    '468x60'  : '/banners/campaña-vipealo/468x60.gif',
    '728x90'  : '/banners/campaña-vipealo/728x90.gif',
    '88x31'   : '/banners/campaña-vipealo/88x31.gif',
    '970x250' : '/banners/campaña-vipealo/970x250.gif',
    '970x90'  : '/banners/campaña-vipealo/970x90.gif'
  }
}

const Banners = () => {
  const [ campaign, setCampaign ] = useState('campania-vipealo')
  const [ show, setShow ] = useState(false)
  const [ image, setImage ] = useState('/banners/campaña-vipealo/728x90.gif')

  const handleClose = () => {
    setShow(false)
  }

  const handleShow =  (url) => {
    setImage(url)
    setShow(true)
  }

  const _handleChangeCampaign = (e) => {
    setCampaign(e.target.value)
  }

  return (
    <>
      <Modal
        aria-labelledby='example-custom-modal-styling-title'
        centered
        dialogClassName={'customDialog'}
        onHide={handleClose}
        show={show}>
        <Image className='img-modal' rounded src={image} />
      </Modal>
      <Container>

        <div className='main-content banners'>
          <div className='page-content'>
            {/* Titulo pagina */}
            <div className='row'>
              <div className='col-12'>
                <div className='page-title-box d-flex align-items-center justify-content-between'>
                  <h4 className='page-title mb-0 font-size-18'>Banners</h4>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='dropdown act-rt'>
                <select
                  className='btn btn-bg-white dropdown-toggle c-pointer'
                  defaultValue='campania-vipealo'
                  id='dropdownCampaigns'
                  onChange={(e) => { _handleChangeCampaign(e) }}
                  value={campaign}>
                  <i className='mdi mdi-chevron-down ml-2' />
                  <option
                    className='dropdown-item c-pointer text-gray'
                    value='campania-vipealo'>
                      Campaña vipealo
                  </option>
                  <option
                    className='dropdown-item c-pointer text-gray'
                    value='campania-2'>
                      Campaña 2
                  </option>
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='wrapper mt-3 col-6'>
                <div className>
                  <div className='section-info'>
                    <p>
                    Puedes descargar los banners individualmente o todo el kit.<br />
                    Pincha sobre los banners para verlos a tamaño real.
                    </p>
                    <button
                      className='btn btn-primary btn-md'>
                      <i className='fa fa-download mr-2' />
                      <a
                        className='text-white'
                        download='Banners-Vipealo-.7z'
                        href={`${ campaign === 'campania-vipealo' ? '/banners/campaña-vipealo.7z' : '/banners/campaña-2.7z' }`}>
                          Descargar kit
                      </a>
                    </button>
                  </div>
                  <div className='mt-3'>
                    <h1 className='mb-4'>Top performing</h1>
                    <div className='banner-list' style={{ overflow: 'auto' }}>
                      <div className='mb-4 mr-3 banner b728x90'>
                        <span>728x90px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['728x90']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['728x90']}`, 'x720x28')}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button className='btn btn-primary save-white ml-3 p-1'  id='save-banner' >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['728x90']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='mb-4 mr-3 banner b320x100'>
                        <span>320x100px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['320x100']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['320x100']}`, 'x720x28')}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button className='btn btn-primary save-white p-1' id='save-banner' >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['320x100']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <br /><br /><br />
                      <div className='mb-4 mr-3 banner b300x600'>
                        <span>300x600px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['300x600']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['300x600']}`)}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button className='btn btn-primary save-white p-1' id='save-banner' >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['300x600']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='mb-4 mr-3 banner b300x250'>
                        <span>300x250px</span>
                        <div
                          className='banner-dimension hasBanner x300x250'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['300x250']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['300x250']}`)}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button className='btn btn-primary save-white p-1' id='save-banner' >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['300x250']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='mb-4 mr-3 banner b336x280'>
                        <span>336x280px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['336x280']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['336x280']}`)}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button className='btn btn-primary save-white p-1' id='save-banner' >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['336x280']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className='mt-3'>
                    <h1 className='mb-4'>Otros tamaños soportados</h1>
                    <div className='banner-list banner-grid'>
                      <div className='mb-4 mr-4 banner b300x1050'>
                        <span>300x1050px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['300x1050']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['300x1050']}`)}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button className='btn btn-primary save-white p-1' id='save-banner' >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['300x1050']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className>
                        <div className='mb-4 banner b240x400'>
                          <span>240x400px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['240x400']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['240x400']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['240x400']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b160x600'>
                          <span>160x600px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['160x600']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['160x600']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['240x400']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className>
                        <div className='mb-4 mr-4 banner b120x450'>
                          <span>120x450px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['120x450']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['120x450']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['120x450']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b120x600'>
                          <span>120x600px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['120x600']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['120x600']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['120x600']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className>
                        <div className='mb-4 mr-4 banner b250x250'>
                          <span>250x250px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['250x250']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['250x250']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['250x250']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b200x200'>
                          <span>200x200px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['200x200']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['200x200']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['200x200']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b180x150'>
                          <span>180x150px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['180x150']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['180x150']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['180x150']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b150x150'>
                          <span>150x150px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['150x150']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['150x150']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['150x150']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='banner-list' style={{ overflow: 'auto' }}>
                      <div className='mb-4 mr-4 banner b970x250'>
                        <span>970x250px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['970x250']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['970x250']}`, 'x720x28')}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button
                              className='btn btn-primary save-white p-1'
                              id='save-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['970x250']}`)}} >
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['970x250']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className='mb-4 mt-5 banner b970x90'>
                        <span>970x90px</span>
                        <div
                          className='banner-dimension hasBanner'
                          style={{ backgroundImage: `url(${listCampaigns[campaign]['970x90']})` }}>
                          <div>
                            <button
                              className='btn btn-primary visibility-white p-1'
                              id='view-banner'
                              onClick={() => {handleShow(`${listCampaigns[campaign]['970x90']}`)}}>
                              <i className='fa fa-eye'></i>
                            </button>
                            <button
                              className='btn btn-primary save-white p-1'
                              id='save-banner'>
                              <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['970x90']}`}>
                                <i className='fa fa-download text-white'></i>
                              </a>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className>
                        <div className='mb-4 mr-4 mt-5 banner b468x60'>
                          <span>468x60px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['468x60']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['468x60']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['468x60']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b428x60'>
                          <span>428x60px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['428x60']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['428x60']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['428x60']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className>
                        <div className='mb-4 mr-4 mt-5 banner b320x50'>
                          <span>320x50px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['320x50']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['320x50']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['320x50']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mr-4 mt-5 banner b234x60'>
                          <span>234x60px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['234x60']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['234x60']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['234x60']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b88x31'>
                          <span>88x31px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['88x31']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['88x31']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['88x31']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='mb-4'>
                        <div className='mb-4 mr-4 mt-5 banner b120x90'>
                          <span>120x90px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['120x90']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['120x90']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['120x90']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className='mb-4 mt-5 banner b120x60'>
                          <span>120x60px</span>
                          <div
                            className='banner-dimension hasBanner'
                            style={{ backgroundImage: `url(${listCampaigns[campaign]['120x60']})` }}>
                            <div>
                              <button
                                className='btn btn-primary visibility-white p-1'
                                id='view-banner'
                                onClick={() => {handleShow(`${listCampaigns[campaign]['120x60']}`)}}>
                                <i className='fa fa-eye'></i>
                              </button>
                              <button className='btn btn-primary save-white p-1' id='save-banner' >
                                <a download='Vipealo Banner 728x90' href={`${listCampaigns[campaign]['120x60']}`}>
                                  <i className='fa fa-download text-white'></i>
                                </a>
                              </button>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Banners
