import React, { useState, useRef, useEffect } from 'react'
import Container from '..'
import { connect } from 'react-redux'
import actionTypes from '../../../actions/actionTypes'
import { validateEmail } from '../../../utils'

const AccessData = ({ user, ...props }) => {
  const [ status, setStatus ] = useState(user.status)
  const [ affiliate ] = useState(user.affiliate ? user.affiliate : {})
  const password = 'xxxxxxxxxxxxxxxxxx'
  const [ email, setEmail ] = useState(user.email || '')
  const [ newEmail, setNewEmail ] = useState(email)
  const [ newPassword, setNewPassword ] = useState('')
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ errors, setErrors ] = useState({})
  const [ errorMessage, setErrorMessage ] = useState('')
  const [ errorMessageShow, setErrorMessageShow ] = useState(false)
  const emailRef = useRef(null)
  const passRef = useRef(null)
  const newPassRef = useRef(null)
  const confNewPassRef = useRef(null)

  const showMessage = () => {
    return  status === 'UPDATE_USER_SUCCESS' ? <h4 className='text-success text-center'>Usuario actualizado</h4> : null
  }

  useEffect(() => {
    setStatus(user.status)
  }, [ user ])

  // disable msg on first load in case validation is true
  useEffect(() => {
    if(status === 'UPDATE_USER_SUCCESS') setStatus('UPDATE_USER')
    // eslint-disable-next-line
  }, [])

  const _handleFocus = (e) => {
    let elem = e.target.parentNode
    elem.classList.add('active')
  }

  const _handleBlur = (e) => {
    if(e.target.value === '') {
      let elem = e.target.parentNode
      elem.classList.remove('active')
    }
  }

  const _handleUpdate = async (event) => {
    event.preventDefault()
    setErrors({})
    if(_validateFields())
      await props.updateUser({ _id: user._id, affiliate, email, password: newPassword, role: user.role, token: user.token })
  }

  const _validateFields = () => {
    cleanRefs()
    let check = true
    if(newPassword !== '' || confirmPassword !== '')
      if(newPassword.length >= 7) {
        if(newPassword !== confirmPassword) {
          setErrors({ message: 'Ups, las contraseñas no coinciden' })
          newPassRef.current.classList.add('b-red')
          confNewPassRef.current.classList.add('b-red')
          check = false
        } else {
          newPassRef.current.classList.add('b-blue')
          confNewPassRef.current.classList.add('b-blue')
        }
      }
      else {
        setErrors({ message: 'Ups, las contraseñas deben poseer al menos 7 caracteres' })
        newPassRef.current.classList.add('b-red')
        confNewPassRef.current.classList.add('b-red')
        check = false
      }

    if(email.length > 4 && validateEmail(email)) {
      emailRef.current.classList.add('b-blue')
    }
    else {
      check = false
      emailRef.current.classList.add('b-red')
      setErrors({ message: 'Ups, debe introducir un email válido' })
    }

    if(check)
      setErrors([])

    return check
  }

  const cleanRefs = () => {
    emailRef.current.classList.remove('b-red')
    passRef.current.classList.remove('b-red')
    newPassRef.current.classList.remove('b-red')
    confNewPassRef.current.classList.remove('b-red')

    emailRef.current.classList.remove('b-blue')
    passRef.current.classList.remove('b-blue')
    newPassRef.current.classList.remove('b-blue')
    confNewPassRef.current.classList.remove('b-blue')
  }

  const _handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
  }

  const _handleNewEmail = (e) => {
    setNewEmail(e.target.value)
    if(email !== e.target.value)
      setEmail(e.target.value)
  }

  const _handleNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  const showErrors = () => {
    return (
      errors ? (<h4 className='text-red' key={errors.message}>{ errors.message }</h4>) : null
    )
  }

  const showApiError = () => {
    return (
      errorMessageShow ? (<h4 className='text-red' key={errorMessage}>{ errorMessage }</h4>) : null
    )
  }

  useEffect(() => {
    let inputs = document.querySelectorAll('input[type="email"], input[type="password"]')

    inputs.forEach(input => {
      if(input.value !== '')
        input.parentNode.classList.add('active')
    })
  }, [ ])

  useEffect(() => {
    const { status, error } = user
    if(status !== 'Update user success' && error) {
      setErrorMessage('El email proporcionado ya existe.')
      setErrorMessageShow(true)
    } else {
      setErrorMessageShow(false)
    }
  }, [ email, user ])

  return (
    <Container>
      <div className='main-content my-access-data'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Mis datos de acceso</h4>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='wrapper'>
              <form action='/' method='POST'>
                <h1 className='mb-4'><i className='icon-c shield-icon mr-2' /> Datos de acceso</h1>
                <div className='access-data'>
                  <div className='mb-2'>
                    <div className='ci-wrapper mb-3 check-field'>
                      <label htmlFor='email'>Correo electrónico</label>
                      <input
                        defaultValue={newEmail}
                        id='email'
                        onBlur={(e)=>{_handleBlur(e)}}
                        onChange={(e) => _handleNewEmail(e)}
                        onFocus={(e)=>{_handleFocus(e)}}
                        ref={emailRef}
                        type='email' />
                    </div>
                    <div className='ci-wrapper mb-3 check-field'>
                      <label htmlFor='old_password'>Contraseña</label>
                      <input
                        id='old_password'
                        onBlur={(e)=>{_handleBlur(e)}}
                        onFocus={(e)=>{_handleFocus(e)}}
                        readOnly
                        ref={passRef}
                        type='password'
                        value={password} />
                    </div>
                  </div>
                  <h2 className='mt-4 mb-2'>Cambiar contraseña</h2>
                  <div className='mb-2'>
                    <div className='ci-wrapper mb-3 check-field'>
                      <label htmlFor='new_password'>Nueva contraseña</label>
                      <input
                        id='new_password'
                        onBlur={(e)=>{_handleBlur(e)}}
                        onChange={(e)=>{_handleNewPassword(e)}}
                        onFocus={(e)=>{_handleFocus(e)}}
                        ref={newPassRef}
                        type='password'
                        value={newPassword} />
                    </div>
                    <div className='ci-wrapper mb-3 check-field'>
                      <label htmlFor='confirm_password'>Confirma tu nueva contraseña</label>
                      <input
                        id='confirm_password'
                        onBlur={(e)=>{_handleBlur(e)}}
                        onChange={(e)=> {_handleConfirmPassword(e)}}
                        onFocus={(e)=>{_handleFocus(e)}}
                        ref={confNewPassRef}
                        type='password'
                        value={confirmPassword} />
                    </div>
                  </div>
                  <div className='separator mt-4' />
                  { showErrors() }
                  { showApiError() }
                  { showMessage() }
                  <div className='form-actions pt-3'>
                    <div className='row ml-0 mt-4'>
                      <label className='cb-label mr-2 col-md-6' />
                      <button
                        className='btn btn-primary font-weight-bolder col-md-5'
                        disabled={status === 'UPDATE_USER_REQUEST' ? true : false}
                        onClick={(e)=>{ _handleUpdate(e) }}>
                        {status === 'UPDATE_USER_REQUEST' ? 'Enviando...' : 'Guardar'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch({ type: actionTypes.UPDATE_USER_REQUEST, user })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessData)
