import React from 'react'
import Container from '..'

const BetterPracticesAffiliate = () => {
  const showText = (id) => {
    let parrs = document.querySelectorAll('.tab-pane')
    let title = document.querySelectorAll('.title')
    parrs.forEach((p, k) => {
      if(p.id === id) {
        title[k].classList.remove('text-dark')
        p.classList.add('active')
        p.classList.add('show')
      }
      else {
        title[k].classList.add('text-dark')
        p.classList.remove('active')
        p.classList.remove('show')
      }
    })

    let links = document.querySelectorAll('.nav-link')
    links.forEach(l => {
      if(l.id === `${id}-tab`)
        l.classList.add('active')

      else
        l.classList.remove('active')
    })
  }

  return (
    <Container>
      <div className='main-content best-practice'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>¿Cómo maximizar tus ventas?</h4>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-2 col-lg-2-5'>
              <div
                aria-orientation='vertical' className='nav flex-column nav-pills c-pointer' id='v-pills-tab'
                role='tablist'>
                <p
                  aria-controls='v-pills-links'
                  aria-selected='false'
                  className='nav-link pt-4 pb-4 mb-2 active'
                  data-toggle='pill'
                  id='v-pills-links-tab'
                  onClick={() => { showText('v-pills-links') }}
                  role='tab'>
                  <span className='title font-weight-bold mb-0' ><i className='fa fa-link mr-2' /> Links</span>
                </p>
                <p
                  aria-controls='v-pills-context'
                  aria-selected='true'
                  className='nav-link pt-4 pb-4 mb-2'
                  data-toggle='pill'
                  id='v-pills-context-tab'
                  onClick={() => { showText('v-pills-context') }}
                  role='tab'>
                  <span className='title text-dark font-weight-bold mb-0'><i className='fa fa-window-maximize mr-2' /> Contexto</span>
                </p>
                <p
                  aria-controls='v-pills-comparatives'
                  aria-selected='false'
                  className='nav-link pt-4 pb-4 mb-2'
                  data-toggle='pill'
                  id='v-pills-comparatives-tab'
                  onClick={() => { showText('v-pills-comparatives') }}
                  role='tab'>
                  <span className='title text-dark font-weight-bold mb-0'><i className='fa fa-exchange mr-2' /> Análisis</span>
                </p>
                <p
                  aria-controls='v-pills-visible-links'
                  aria-selected='false'
                  className='nav-link pt-4 pb-4 mb-2'
                  data-toggle='pill'
                  id='v-pills-visible-links-tab'
                  onClick={() => { showText('v-pills-visible-links') }}
                  role='tab'>
                  <span className='title text-dark font-weight-bold mb-0'><i className='fa fa-eye mr-2' /> Destaca los enlaces</span>
                </p>
                <p
                  aria-controls='v-pills-focused'
                  aria-selected='false'
                  className='nav-link pt-4 pb-4 mb-2'
                  data-toggle='pill'
                  id='v-pills-focused-tab'
                  onClick={() => { showText('v-pills-focused') }}
                  role='tab'>
                  <span className='title text-dark font-weight-bold mb-0'><i className='fa fa-window-close-o mr-2' /> Enlaces específicos</span>
                </p>
              </div>
            </div>
            <div className='col-lg-9 pl-2'>
              <div className='card min-hg'>
                <div className='card-body'>
                  <div className='tab-content' id='v-pills-tabContent'>
                    <div
                      aria-labelledby='v-pills-links-tab' className='tab-pane fade show active' id='v-pills-links'
                      role='tabpanel'>
                      <h1 className='mb-3'>Links</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>Coloca los links en tus publicaciones con más visualizaciones de tu blog o sitio web. Es importante que los enlaces sean fáciles de ver y de
                            localizar por los lectores. Esto te permitirá generar más clics, aumentado así tus probabilidades de venta. Es importante hacerlo todo en su justa medida. No es
                            beneficioso que los usuarios vean tus publicaciones como comerciales pues dañará tu credibilidad.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      aria-labelledby='v-pills-context-tab' className='tab-pane fade' id='v-pills-context'
                      role='tabpanel'>
                      <h1 className='mb-3'>Contexto</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>Es importante que crees contenido sobre aquellos productos que quieres enlazar. Comparte tu contenido e información sobre ese evento o destino.
                            Por último, añade nuestros widgets y enlaces sobre el producto.
                          </p>
                          <p className='text-muted mb-2'>Por ejemplo, si tienes un artículo sobre qué ver en Sevilla, en la última frase de tu publicación puedes añadir: “Conoce todos los rincones
                            de Sevilla apuntándote a un tour en español”.
                          </p>
                          <p className='text-muted mb-2'>Recuerda que aquellos productos que tú recomiendes generarán más conversiones que el resto.</p>
                        </div>
                      </div>
                    </div>
                    <div
                      aria-labelledby='v-pills-comparatives-tab' className='tab-pane fade' id='v-pills-comparatives'
                      role='tabpanel'>
                      <h1 className='mb-3'>Análisis</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>Visita la sección “Análisis” para saber cuáles son nuestras actividades más vendidas. Podrás conocer los productos más populares de
                            Vipealo y así sacar ideas sobre qué actividades es mejor enlazar en tu web. Priorizar estos producto, en ocasiones, puede mejorar el CR.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      aria-labelledby='v-pills-visible-links-tab' className='tab-pane fade' id='v-pills-visible-links'
                      role='tabpanel'>
                      <h1 className='mb-3'>Destaca los enlaces</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>¡Sácale partido a los enlaces! Es muy importante que los links sean llamativos para atraer la atención de los usuarios que visiten tu sitio
                            web o blog. Si no son vistosos y pasan desapercibidos no ciclarán en ellos. Por lo tanto, te recomendamos que resaltes los enlaces de los textos en negrita o de
                            cualquier otro color que llame la atención.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      aria-labelledby='v-pills-focused-tab' className='tab-pane fade' id='v-pills-focused'
                      role='tabpanel'>
                      <h1 className='mb-3'>Enlaces específicos</h1>
                      <div className='faq-box media mb-4'>
                        <div className='media-body'>
                          <p className='text-muted'>Debes ser concreto al colocar tus enlaces. Esto quiere decir que, si hablas en tu artículo sobre Madrid, es mejor poner
                            “Eventos en Madrid” que “Eventos en España”.
                          </p>
                          <p className='text-muted'>Si buscas añadir enlaces más generales, agrega una frase que coincida con la actividad: “Si está buscando eventos en
                            otras partes de España, te proporcionaremos algunas cosas que puede hacer".
                          </p>
                          <p className='text-muted'>Te recomendamos que utilices más los enlaces específicos porque generan una tasa más alta de conversión y eso te ayudará
                            a aumentar las ventas.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default BetterPracticesAffiliate
