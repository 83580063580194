import { call, put, takeLatest, select, fork, take, all } from 'redux-saga/effects'
import { api } from '../utils'
import {
  FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_SUCCESS,
  FETCH_RESERVATIONS_ERROR,
  FETCH_GROUPED_RESERVATIONS,
  FETCH_GROUPED_RESERVATIONS_SUCCESS,
  FETCH_GROUPED_RESERVATIONS_ERROR,
  CHANGE_MONTH,
  SET_MONTH,
  CHANGE_STATS_TYPE,
  SET_STATS_TYPE,
  SET_STATS_TYPE_ERROR,
  FETCH_COMPARATIVES_SUCCESS,
  FETCH_COMPARATIVES_ERROR,
  GET_PAGE,
  FETCH_PAGE,
  FETCH_PAGE_SUCCESS,
  FETCH_PAGE_ERROR
} from '../reducers/reservations'
import { EVALUATE_USER } from '../actions/actionTypes'

const getReservations = async (affiliateId, month, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = new URLSearchParams({ date: month, page, pagination: 1 }).toString()
      const res = await api('get', `affiliates/reservations/${affiliateId}`, `?${params}`, {}, '')
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

const getClicks = async (affiliateId, month, page = 1) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = new URLSearchParams({ date: month, page }).toString()
      const res = await api('get', `affiliates/clicks/${affiliateId}`, `?${params}`, {}, '')
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

const getTotals = async (affiliateId, month, paidAffiliate, currency, irpf, typeBussines, statsType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = new URLSearchParams({ currency, date: month, irpf, ...paidAffiliate, typeBussines, statsType }).toString()
      const res = await api('get', `affiliates/totals/${affiliateId}`, `?${params}`, {}, '')
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

const getGroupedReservations = async (affiliateId, month, paidAffiliate, currency, irpf, typeBussines, statsType) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = new URLSearchParams({ currency, date: month, irpf, ...paidAffiliate, typeBussines, statsType }).toString()
      const res = await api('get', `affiliates/groupedReservations/${affiliateId}`, `?${params}`, {}, '')
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}

function* fetchGroupedReservations({ payload }) {
  const month = payload
  yield put({ payload: month , type: SET_MONTH })
  try {
    let affiliateId = yield select(state => state.user.affiliate._id) || 1
    const { affiliate: { bill: { typeBussines = 'company', irpf = '15', currency = 'EUR' } } } = yield select(state => state.user)
    const { statsType = 'reservation' } = yield select(state => state.reservations)

    const [ resultTotals ] = yield all([
      call(getGroupedReservations, affiliateId, month, { paidAffiliate: 1 }, currency, irpf, typeBussines, statsType)
    ])
    const { groupedReservations } = resultTotals
    yield put({ groupedReservations, type: FETCH_GROUPED_RESERVATIONS_SUCCESS })
  } catch (err) {
    // eslint-disable-next-line no-restricted-syntax
    console.log(err)
    yield put({ payload: err.message, type: FETCH_GROUPED_RESERVATIONS_ERROR })
  }
}

const getSettings = async (affiliateId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await api('get', `affiliates/settings/${affiliateId}`, '', {}, '')
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}
/*
const getTotals = async (affiliateId, month) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = new URLSearchParams({ date: month }).toString()
      const res = await api('get', `affiliates/totals/${affiliateId}`, `?${params}`, {}, '')
      resolve(res)
    } catch (error) {
      reject(error)
    }
  })
}
*/
function*  loadReservations({ payload }) {
  const month = payload
  yield put({ payload: month , type: SET_MONTH })
  yield put({ type: FETCH_RESERVATIONS })
  try {
    let affiliateId = yield select(state => state.user.affiliate._id) || 1
    // let currency = yield select(state => state.user.affiliate.bill.currency)
    const { affiliate: { bill: { typeBussines = 'company', irpf = '15', currency = 'EUR' } } } = yield select(state => state.user)
    const { statsType = 'reservation' } = yield select(state => state.reservations)

    const [ resultReservations, resultClicks, resultTotals, resultSettings ] = yield all([
      call(getReservations, affiliateId, month),
      call(getClicks, affiliateId, month),
      call(getTotals, affiliateId, month, { paidAffiliate: 1 }, currency, irpf, typeBussines, statsType),
      call(getSettings, affiliateId)
    ])

    const { reservations } = resultReservations
    const {  myLastSales, myMonthClicks, conversionRate } = resultClicks
    const {  currencies, total, totalToPay, freetour, defaultFreeTour, myLastPayment, subTotal, affPercent, affFreetour } = resultTotals
    const totalToBilling = totalToPay, freetourToBilling = freetour
    const { afiliados_free_tour, afiliados } = resultSettings
    // console.log('***resultSettings', resultSettings)

    yield put({
      conversionRate, currencies, defaultFreeTour, freetour, freetourToBilling,
      myLastPayment, myLastSales, myMonthClicks, payload: reservations,  subTotal, total, totalToBilling,
      type   : FETCH_RESERVATIONS_SUCCESS
    })
    yield put({ affFreetour, affPercent, afiliados, afiliados_free_tour, type: EVALUATE_USER })
  } catch (err) {
    // eslint-disable-next-line no-restricted-syntax
    console.log(err)
    yield put({ payload: err.message, type: FETCH_RESERVATIONS_ERROR })
  }
}

export function* fetchComparatives() {
  while (true) {
    const { data: { month, token, affiliateId } } = yield take('FETCH_COMPARATIVES')
    const body = { affiliateId, month, token }
    try {
      const result = yield api('post', 'affiliates', 'comparative', body, token)
      if(result.success)
        yield put({ payload: result.data, type: FETCH_COMPARATIVES_SUCCESS })
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log(error)
      yield put({ payload: error, type: FETCH_COMPARATIVES_ERROR })
    }
  }
}

export function* getPage({ payload }) {
  const { newPage } = payload
  const { month } = yield select(state => state.reservations)
  let affiliateId = yield select(state => state.user.affiliate._id)

  try {
    yield put({ type: FETCH_PAGE })
    const reservations = yield call(getReservations,affiliateId, month, newPage)
    yield put({ payload: reservations, type: FETCH_PAGE_SUCCESS })
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
    yield put({ payload: error.message, type: FETCH_PAGE_ERROR  })
  }
}

export function* setStatsType({ payload }) {
  console.log('***setStatsType', payload)
  try {
    const type = payload
    yield put({ payload: type , type: SET_STATS_TYPE })
  } catch (error) {
    // eslint-disable-next-line
    console.log(error)
    yield put({ payload: error.message, type: SET_STATS_TYPE_ERROR  })
  }
}

export default [
  takeLatest(CHANGE_MONTH, loadReservations),
  fork(fetchComparatives),
  takeLatest(GET_PAGE, getPage),
  // fork(setStatsType)
  takeLatest(CHANGE_STATS_TYPE, setStatsType),
  takeLatest(FETCH_GROUPED_RESERVATIONS, fetchGroupedReservations)
]
