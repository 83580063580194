export const FETCH_RESERVATIONS         = 'FETCH_RESERVATIONS'
export const FETCH_RESERVATIONS_SUCCESS = 'FETCH_RESERVATIONS_SUCCESS'
export const FETCH_RESERVATIONS_ERROR   = 'FETCH_RESERVATIONS_ERROR'
export const CHANGE_MONTH               = 'CHANGE_MONTH'
export const SET_MONTH                  = 'SET_MONTH'
export const SET_STATS_TYPE             = 'SET_STATS_TYPE'
export const CURRENT_RESERVATIONS       = 'CURRENT_RESERVATIONS'
export const FETCH_COMPARATIVES         = 'FETCH_COMPARATIVES'
export const FETCH_COMPARATIVES_SUCCESS = 'FETCH_COMPARATIVES_SUCCESS'
export const FETCH_COMPARATIVES_ERROR   = 'FETCH_COMPARATIVES_ERROR'
export const DELETE_CONTENT             = 'DELETE_CONTENT'

export default {
  CHANGE_MONTH,
  CURRENT_RESERVATIONS,
  DELETE_CONTENT,
  FETCH_COMPARATIVES,
  FETCH_COMPARATIVES_ERROR,
  FETCH_COMPARATIVES_SUCCESS,
  FETCH_RESERVATIONS,
  FETCH_RESERVATIONS_ERROR,
  FETCH_RESERVATIONS_SUCCESS,
  SET_MONTH
}
