import React from 'react'
import { Pagination } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getPage } from '../../reducers/reservations'

const CustomPagination = () => {
  const {
    page,
    totalPages
    // totalDocs,
    // prevPage,
    // nextPage
  } = useSelector(state => state.reservations.data)

  const dispatch = useDispatch()

  const changePage = (newPage) => {
    if(newPage !== page)
      dispatch(getPage({ newPage }))
  }

  let items = []
  for (let i = page === 1 ? page : totalPages >= 3 && page >= 3 ? page - 2 :  page - 1; i <= totalPages && i < page + 3; i++)
    items.push(
      <Pagination.Item className={page === i ? 'active' : ''} key={i} onClick={()=>changePage(i)}>
        {i}
      </Pagination.Item>)

  return (
    <div className='pagination mt-4 mb-0 '>
      <Pagination className='mx-auto'>
        <Pagination.Prev
          className={page === 1 ? 'disabled' : ''} onClick={()=>changePage(page - 1)} />
        {
          items
        }
        <Pagination.Next
          className={page === totalPages || totalPages === 0  ? 'disabled' : ''}
          onClick={()=>changePage(page + 1)} />
      </Pagination>
    </div>
  )
}

export default CustomPagination
