import React from 'react'
import { Link } from 'react-router-dom'
import Container from '..'

const ResourcesAffiliate = () => {
  return (
    <Container>
      <div className='main-content links-and-resources'>
        <div className='page-content'>
          {/* Titulo pagina */}
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Enlaces y más recursos</h4>
              </div>
            </div>
          </div>
          <div className='row modules'>
            <div className='col-xl-5 mr-1 text-center'>
              <div className='p-5'>
                <span><i className='icon-c links-icon' style={{ backgroundImage: 'url(/images/links-icon.svg)' }} /></span>
                <div className='mt-3'>
                  <h3>Enlaces</h3>
                  <p>
                    Súper simple. <br />
                    Solo necesitas agregar tu ID de afiliado al final de cada enlace.
                  </p>
                  <Link className='btn btn-primary w-60 mt-2' to='/afiliados/recursos/links'>Ver más</Link>
                </div>
              </div>
            </div>
            <div className='col-xl-5 text-center'>
              <div className='p-5'>
                <span><i className='icon-c banners-icon' style={{ backgroundImage: 'url(/images/banners-icon.svg)' }} /></span>
                <div className='mt-3'>
                  <h3>Banners</h3>
                  <p>Utiliza nuestros diseños de banners para atraer la atención de tus seguidores.</p>
                  <Link className='btn btn-primary w-60 mt-2' to='/afiliados/recursos/banners'>Ver más</Link>
                </div>
              </div>
            </div>

            {/* <div class='col-xl-5 text-center mt-2'>
              <div class='p-5'>
                <span><i class='icon-c widget-icon' style={{ backgroundImage: 'url(/images/widget-icon.svg)' }}></i></span>

                <div class='mt-3'>
                  <h3>Widget</h3>
                  <p>Tú eliges las actividades que quieres mostrar en la web. ¡Super personalizado!</p>
                  <Link class='btn btn-primary w-60 mt-2' to='/afiliados/recursos/widgets'>Ver más</Link>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

    </Container>
  )
}

export default ResourcesAffiliate
