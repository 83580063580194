import React, { Fragment } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { useState } from 'react'

const acceptFormats = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'pdf',
  'xls',
  'doc',
  'docx',
  'webp'
]

const  MyDropzone = ({ setError, setDoc, doc, route = 'affiliates/uploads/documents', accept = acceptFormats, height = 200 }) => {
  const [ thumbFile, setThumbFile ] = useState(
    doc.split('.')[doc.split('.').length - 1 ] === 'jpg' || doc.split('.')[doc.split('.').length - 1 ] ===  'jpeg' || doc.split('.')[doc.split('.').length - 1 ] ===  'png' || doc.split('.')[doc.split('.').length - 1 ] ===  'gif' ?
      (<Fragment>
        <img
          alt={`${doc}`} className='img-fluid img-thumbnail rounded float-left mb-4 ml-3 avatar-dimention'
          src={`${doc}`} />
      </Fragment>)      :
      <div className='list-group-item mb-3'><a href={doc} rel='noopener noreferrer' target='_blank'>Ver documento</a></div>
  )

  const _handleDrop = async (files) => {
    setError({
      message: '',
      status : false
    })
    files.map(async file => {
      const name = file.name
      const extension = file.name.split('.').pop()
      if(accept.find(e => e === extension)) {
        const filename = `${new Date().getTime()}.${extension}`

        const options = {
          post: {
            headers: {
              'Content-Type': 'application/json'
            }
          },
          put: {
            headers: {
              'Content-Type': file.type,
              'x-amz-acl'   : 'public-read'
            }
          }
        }

        const { REACT_APP_API } = process.env

        try {
          const {
            data: { token, success }
          } = await axios.post(
            `${REACT_APP_API}/upload/token`,
            { data: { route: `${route}/${filename}`, type: file.type } },
            options.post
          )

          if(!success)
            throw new Error()

          await axios.put(token, file, options.put)

          const fileUrl = token.split('?')[0]

          if(fileUrl && fileUrl !== '') {
            setDoc(fileUrl)
            setThumbFile(
              extension === 'jpg' || extension ===  'jpeg' || extension ===  'png' || extension ===  'gif' ?
                (<Fragment>
                  <a href={fileUrl} rel='noopener noreferrer' target='_blank'><img
                    alt='Document' className='img-fluid img-thumbnail rounded float-left mb-4 ml-3' src={`${fileUrl}`}
                    width='150px' /></a>
                </Fragment>)                :
                <div className='list-group-item mb-3'><a href={fileUrl} rel='noopener noreferrer' target='_blank'>{ name }</a></div>

            )
          }
          else
          {throw new Error()}
        } catch (error) {
          setError({
            message: 'Ups, ocurrio un error cargando el archivo... intentalo de nuevo',
            status : true
          })
        }
      } else {
        setError({
          message: `Solo se permiten archivos ${accept.join(', ')}`,
          status : true
        })
      }
    })
  }

  // eslint-disable-next-line
  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop  : acceptedFiles => _handleDrop(acceptedFiles)
  })

  return (
    <>
      <div {...getRootProps()} className={`dropzone h-${height}`}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Suelta aquí tu archivo ...</p> :
            <p><i className='fa fa-cloud-upload'></i>Adjunta o arrastra aquí tu archivo</p>
        }
      </div>
      {
        doc && doc !== '' ? (
          <section className='mb-3 d-flex justify-content-start'>
            { thumbFile }
          </section>
        ) : null
      }
    </>
  )
}

export default MyDropzone
