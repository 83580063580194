import React, { useEffect, useState } from 'react'
import { withRouter, Link } from 'react-router-dom'
import imageHelp from '../../../assets/images/users/avatar-3.jpg'
import { connect } from 'react-redux'

const Sidebar = ({ openMenu, user, ...props }) => {
  const { firstName = 'Pendiente', lastName = 'Pendiente' } = user
  const [ afId ] = useState(user.affiliate && user.affiliate.aid ? user.affiliate.aid : 'Pendiente')
  const avatar = user.affiliate.avatar ? user.affiliate.avatar : process.env.REACT_APP_DEFAULT_AVATAR

  useEffect(()=>{
    let links = document.querySelectorAll('.link-sidebar')
    links.forEach(elem => {
      let path = elem.href.split('/')[elem.href.split('/').length - 1]

      if(path === props.location.pathname.split('/')[2])
        elem.classList.add('mm-active')
      else
        elem.classList.remove('mm-active')
    })
    // eslint-disable-next-line
  }, [])

  let classes = openMenu ? 'show-main-menu' : ''

  return (
    <div className={`vertical-menu ${classes}`} id='main-menu'>
      <div className='h-100' id='leftsidebar-fixed'>
        <div className='user-wid text-center py-4'>
          <Link className='user-img' to='/afiliados/mi-cuenta'>
            <div className='user-img'>
              <img alt='' className='avatar-md mx-auto rounded-circle' src={`${avatar}`} />
            </div>
            <div className='mt-3'>
              <p className='text-dark font-weight-medium font-size-16'>{`${firstName} ${lastName }`}</p>
              <p className='text-body brand-color font-weight-bolder mt-1 mb-0 font-size-13'>ID {afId}</p>
            </div>
          </Link>
        </div>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/panel'>
                <i className='fa fa-columns' />
                <span>Panel</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/reservas'>
                <i className='fa fa-bookmark' />
                <span>Reservas</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/estadisticas'>
                <i className='fa fa-bar-chart' />
                <span>Estadísticas</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/facturacion'>
                <i className='fa fa-list-alt' />
                <span>Facturación</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/comparativas'>
                <i className='fa fa-line-chart' />
                <span>Análisis</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/recursos'>
                <i className='fa fa-link' />
                <span>Enlaces y más recursos</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect link-sidebar' to='/afiliados/ayuda'>
                <i className='fa fa-commenting' />
                <span>Preguntas frecuentes</span>
              </Link>
            </li>
            <li>
              <Link className='waves-effect active link-sidebar' to='/afiliados/mejores-practicas'>
                <i className='fa fa-thumbs-up' />
                <span>¿Cómo maximizar tus ventas?</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className='need-help-mod'>
          <img alt='User Helper' src={imageHelp} />
          <h4>¿Necesitas ayuda?</h4>
          <p>Contacta para resolver cualquier duda.</p>
          <Link className='btn btn-primary btn-md' to={'/afiliados/contacto'}>Contactar</Link>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.user })

export default withRouter(connect(mapStateToProps)(Sidebar))
