import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typeahead } from 'react-bootstrap-typeahead'
import Container from '..'
import { api } from '../../../utils'
import Spinner from '../../../components/Spinner'
import { Modal, Alert } from 'react-bootstrap'
import IFrame from 'iframe-resizer-react'
import 'iframe-resizer/js'

const Widgets = () => {
  const { aid } = useSelector(state => state.user.affiliate)
  const BaseUrlIframe = `${process.env.REACT_APP_URL}/afiliados/widgets-actividades/?aid=${aid}`
  const [ hiddeCheckViewAll, setHiddeCheckViewAll ] = useState(false)
  const [ centerAct, setCenterAct ] = useState(false)
  const [ noBgColor, setNoBgColor ] = useState(true)
  const [ height, setHeight ] = useState(0)
  const [ width, setWidth ] = useState(0)
  const [ maxWidth, setMaxWidth ] = useState('')
  const [ quantity, setQuantity ] = useState(6)
  const [ campaign, setCampaign ] = useState('')
  const [ typeSelection, setTypeSelection ] = useState('all-destination')
  const [ formatSelected, setFormatSelected ] = useState('body-140x210')
  const [ listActivities, setListActivities ] = useState([])
  const [ country, setCountry ] = useState('')
  const [ listCountries, setListCountries ] = useState([])
  const [ listDestinations, setListDestinations ] = useState([])
  const [ destination, setDestination ] = useState([])
  const [ loadingDestinations, setLoadingDestinations ] = useState(false)
  const [ selectActivity, setSelectActivity ] = useState([])
  const [ activities, setActivities ] = useState([])
  const [ loadingActivities, setLoadingActivities ] = useState(false)
  const [ showModal, setShowModal ] = useState(false)
  const [ activeAlert, setActiveAlert ] = useState(false)
  const [ urlToPrev, setUrlToPrev ] = useState('')

  const imgRef140x210 = useRef(null)
  const imgRef153x105 = useRef(null)
  const imgRef103x86 = useRef(null)
  const imgRef141x43 = useRef(null)
  const imgRef115x97 = useRef(null)
  const imgRef158x81 = useRef(null)
  const wrapperModalRef = useRef(null)
  const desktopRef = useRef(null)
  const tabletRef = useRef(null)
  const mobileRef = useRef(null)
  const modalOptionsRef = useRef(null)
  const viewBtnAllRef = useRef(null)
  const centerWidgetref = useRef(null)
  const bgColorWidgetRef = useRef(null)
  const iframeRef = useRef(null)

  const getCountries = async () => {
    try {
      const { countries } = await api('get', 'countries', '', {}, '')
      setListCountries(countries)
    } catch (error) {
      console.log(error)
    }
  }

  const getDestinations = async () => {
    try {
      setLoadingDestinations(true)
      let query = {}
      if(country && country !== '')
        query = { country }
      const params = new URLSearchParams(query)

      const { cities, success } = await api('get', 'cities', `?${params}`, {}, '')
      if(success) {
        setListDestinations(cities)
        setLoadingDestinations(false)
      }
      else {
        throw new Error('success false on getDestinations()')
      }
    } catch (error) {
      console.log(error)
      setLoadingDestinations(false)
    }
  }

  const getActivities = async () => {
    if(destination && destination.length)
      try {
        setLoadingActivities(true)
        const query = { city: destination[0]._id, pagination: 0 }
        const params  = new URLSearchParams(query)
        const res = await api('get', 'activities', `?${params}`, {}, '')
        if(res.success) {
          setListActivities(res.docs)
          setLoadingActivities(false)
        }
        else {
          throw new Error('success false on getActivities()')
        }
      } catch (error) {
        console.log(error)
        setLoadingActivities(false)
      }
  }

  useEffect(() => {
    if(formatSelected && formatSelected.split('-')[0] === 'body')
      setMaxWidth('100%')
    else setMaxWidth('350px')
  }, [ formatSelected ])

  useEffect(() => {
    if(activeAlert)
      setTimeout(
        ()=>{setActiveAlert(false)},
        1500
      )
  }, [ activeAlert ])

  const getUrl = () => {
    let url = BaseUrlIframe
    if(country && country !== '')
      url += (`${ url[url.length - 1] === '?' ? '' : '&'}country=${country}`)
    if(destination && destination.length)
      url += (`${ url[url.length - 1] === '?' ? '' : '&'}city=${destination[0]._id}`)
    if(formatSelected)
      url += (`${ url[url.length - 1] === '?' ? '' : '&'}format=${formatSelected}`)
    if(activities && activities.length) {
      let arrActs = []
      activities.map(act => {
        arrActs.push(act.actId)

        return 0
      })
      url += (`${url[url.length - 1] === '?' ? '' : '&'}activities=${arrActs.join(',')}`)
    }
    if(quantity && quantity !== 0)
      url += (`${url[url.length - 1] === '?' ? '' : '&'}quant=${quantity}`)
    if(campaign)
      url += (`${url[url.length - 1] === '?' ? '' : '&'}cmp=${campaign}`)
    if(hiddeCheckViewAll)
      url += (`${url[url.length - 1] === '?' ? '' : '&'}hiddeBtn=1`)
    if(centerAct)
      url += (`${url[url.length - 1] === '?' ? '' : '&'}centerAct=1`)
    if(noBgColor)
      url += (`${url[url.length - 1] === '?' ? '' : '&'}noBgColor=1`)

    return url
  }

  const copyCode = (e) => {
    e.preventDefault()
    const url = getUrl()
    const iframe = createIframe(url)
    pushToClipboard(iframe)
    setActiveAlert(true)
  }

  const pushToClipboard = (text) => {
    var input = document.createElement('input')
    input.setAttribute('value', text)
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }

  const createIframe = (url) => {
    const iframe = `<div style='text-align: ${centerAct ? 'center' : 'left;max-width:' + maxWidth }'><iframe src='${url}' height='${height === 0 ? '100%' : height + 'px'}' width='${width === 0 ? '100%' : width +  'px'}' style='border: none;max-width: ${maxWidth}; min-height: ${height && height !== 0 ? height + 'px' : '-webkit-fill-available;'}' frameborder='0'></iframe></div>`

    return iframe
  }

  useEffect(()=> {
    getCountries()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    getActivities()
    // eslint-disable-next-line
  }, [ destination ])

  useEffect(() => {
    getDestinations()
    // eslint-disable-next-line
  }, [ country ])

  const _handleChangeDestination = (value) => {
    if(value !== destination) {
      setListActivities([])
      setDestination(value)
    }
  }

  const _handleChangeType = (e) => {
    setTypeSelection(e.target.value)
  }

  const _handleShowModal = () => {
    const url = getUrl()
    setUrlToPrev(url)
    setShowModal(true)
  }

  const _handleQuantity = (e) => {
    setQuantity(e.target.value)
  }

  const closeModal = (close) => {
    setShowModal(close)
  }

  const _handleFormat = (e) => {
    const formats = [ imgRef140x210, imgRef153x105, imgRef103x86, imgRef141x43, imgRef115x97, imgRef158x81 ]

    formats.map(fm => {
      if(fm && fm.current) {
        fm.current.classList.remove('active')
        fm.current.classList.remove('active-preview')
        if(fm.current === e.target) {
          fm.current.classList.add('active')
          setFormatSelected(e.target.getAttribute('format'))
        }
        else {
          fm.current.classList.add('active-preview')
        }
      }

      return 0
    })
  }

  const _handleSelectActivity = (value) => {
    setSelectActivity(value)
  }

  const pushActivity = (e) => {
    e.preventDefault()
    if(selectActivity && selectActivity.length)
      setActivities(activities.concat([ selectActivity[0] ]))
  }

  const removeActivity = (id) => {
    const newActs = activities.filter(act => act._id !== id)
    setActivities(newActs)
  }

  const _handleHeight = (e) => {
    setHeight(e.target.value)
  }

  const _handleWidth = (e) => {
    setWidth(e.target.value)
  }

  const _handleCmp = (e) => {
    setCampaign(e.target.value)
  }

  const markSelected = (type) => {
    const refs = [ desktopRef, mobileRef, tabletRef ]
    refs.map(ref => {
      if(ref && ref.current)
        ref.current.classList.remove('icon-active')

      return 0
    })

    type === 'desktop' ? desktopRef.current.classList.add('icon-active') :
      type === 'tablet' ? tabletRef.current.classList.add('icon-active') :
        mobileRef.current.classList.add('icon-active')
  }

  const _handleViewModal = (type) => {
    if(wrapperModalRef && wrapperModalRef.current) {
      const mode = wrapperModalRef.current.getAttribute('data-mode')
      if(mode !== type) {
        wrapperModalRef.current.setAttribute('data-mode', type)
        markSelected(type)
      }

      if(type === 'desktop' || type === 'tablet') {
        if(modalOptionsRef && modalOptionsRef.current)
          modalOptionsRef.current.classList.remove('mobile')
      }
      else {
        if(modalOptionsRef && modalOptionsRef.current)
          modalOptionsRef.current.classList.add('mobile')
      }
    }
  }

  const _handleCountry = (e) => {
    setCountry(e.target.value)
    setDestination([])
    setListActivities([])
    setSelectActivity([])
  }

  const _handleCheckViewAll = () => {
    setHiddeCheckViewAll(!hiddeCheckViewAll)
    if(viewBtnAllRef && viewBtnAllRef.current)
      viewBtnAllRef.current.parentNode.classList.toggle('checked')
  }

  const _handleCheckBgColor = () => {
    setNoBgColor(!noBgColor)
    if(bgColorWidgetRef && bgColorWidgetRef.current)
      bgColorWidgetRef.current.parentNode.classList.toggle('checked')
  }

  const _handleCheckCenterAct = () => {
    setCenterAct(!centerAct)
    if(centerWidgetref && centerWidgetref.current)
      centerWidgetref.current.parentNode.classList.toggle('checked')
  }

  const _handleFocus = (e) => {
    let elem = e.target.parentNode
    elem.classList.add('active')
  }

  const _handleBlur = (e) => {
    if(e.target.value === '') {
      let elem = e.target.parentNode
      elem.classList.remove('active')
    }
  }

  return (
    <Container>
      <div className='main-content widget'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Widget</h4>
              </div>
            </div>
          </div>
          <div className='modules'>
            <div className='mr-4 pt-4 text-center'>
              <form action='#' className='mb-5 disable-check' method='POST'>
                <h2 className='mb-4 text-left'>Vipealo widget</h2>
                <p className='text-left'>Hay dos formas de personalizar tu widget: puedes elegir las actividades de forma individual o seleccionar la zona, destino o país y categorías. Generalmente el widget está programado para mostrar las 6 actividades con más éxito. </p>
                <div className='general-data'>
                  <h5 className='mt-4 mb-3 text-left'>General</h5>
                  <div className='row m-0 row-flw'>
                    <div className='col-md-6 ci-wrapper p-0 mr-3 mb-3'>
                      <div className='dropdown act-rt'>
                        <select
                          className='btn btn-bg-white dropdown-toggle'
                          data-toggle='dropdown'
                          id='dropdownCountry'
                          onChange={(e)=>{_handleCountry(e)}}
                          value={country}>
                          <option value=''>Selecciona un pais</option>
                          {
                            listCountries.map(({ _id, name }) => (
                              <option className='dropdown-item' key={_id} value={_id} >{ name }</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6 ci-wrapper p-0 mr-3 mb-3'>
                      <div className='dropdown act-rt'>
                        <select
                          aria-expanded='false' aria-haspopup='true' className='btn btn-bg-white dropdown-toggle'
                          data-toggle='dropdown' id='dropdownCurrency'>
                          <option>Selecciona una moneda</option>
                          <option className='dropdown-item' data-val='eur'>Euros</option>
                          {/*     <option className='dropdown-item' data-val='usd'>Dólares</option>
                          <option className='dropdown-item' data-val='gb'>Libra Esterlina</option>
                    */} </select>
                      </div>
                    </div>
                  </div>
                  <div className='row m-0 row-flw'>
                    <div className='col-md-6 ci-wrapper p-0 mr-3 mb-3'>
                      <label htmlFor='num_activities'>Número de actividades</label>
                      <input
                        id='num_activities'
                        onBlur={(e)=>_handleBlur(e)}
                        onChange={(e)=>{_handleQuantity(e)}}
                        onFocus={(e)=>_handleFocus(e)}
                        type='number'
                        value={quantity === 6 ? '' : quantity} />
                    </div>
                    <div className='col-md-6 ci-wrapper p-0 mb-3'>
                      <label htmlFor='campaign'>Campaña</label>
                      <input
                        id='campaign'
                        onBlur={(e)=>_handleBlur(e)}
                        onChange={(e)=>{_handleCmp(e)}}
                        onFocus={(e)=>_handleFocus(e)}
                        type='text'
                        value={campaign} />
                    </div>
                  </div>
                  <div className='row m-0 row-flw'>
                    <div className='col-md-6 p-0 mr-3 mb-2'>
                      <p className='m-0 pl-2 text-left pxls'>Ancho (píxeles)</p>
                      <div className='ci-wrapper'>
                        <label htmlFor='width' />
                        <input
                          id='width'
                          onChange={(e)=>{_handleWidth(e)}}
                          placeholder='Auto'
                          type='number'
                          value={width === 0 ? '' : width} />
                      </div>
                    </div>
                    <div className='col-md-6 p-0 mr-3 mb-2'>
                      <p className='m-0 pl-2 text-left pxls'>Alto (píxeles)</p>
                      <div className='ci-wrapper'>
                        <label htmlFor='height' />
                        <input
                          id='height'
                          onChange={(e)=>{_handleHeight(e)}}
                          placeholder='Auto'
                          type='number'
                          value={height === 0 ? '' : height} />
                      </div>
                    </div>
                  </div>
                  <div className='mt-4 general-data-options'>
                    <ul>
                      <li className>
                        <label className='cb-label' htmlFor='hide_see_all_activities'>
                          <span className='custom-checkbox' />
                          <input
                            id='hide_see_all_activities'
                            name='opt[see_all_activities]'
                            onClick={_handleCheckViewAll}
                            ref={viewBtnAllRef}
                            type='checkbox' />
                            Ocultar botón "Ver todas las actividades"
                        </label>
                      </li>
                      <li className>
                        <label className='cb-label' htmlFor='center_widget_activities'>
                          <span className='custom-checkbox' />
                          <input
                            id='center_widget_activities'
                            name='opt[center_widget_activities]'
                            onClick={_handleCheckCenterAct}
                            ref={centerWidgetref}
                            type='checkbox' />
                              Centrar las actividades en el widget
                        </label>
                      </li>
                      <li className>
                        <label className='cb-label checked' htmlFor='remove_background_color'>
                          <span className='custom-checkbox' />
                          <input
                            id='remove_background_color'
                            name='opt[remove_background_color]'
                            onClick={_handleCheckBgColor}
                            ref={bgColorWidgetRef}
                            type='checkbox' />
                          Quitar color de fondo
                        </label>
                      </li>
                    </ul>
                  </div>
                  <h5 className='mt-4 mb-4 text-left'>Actividades</h5>
                  <div className='ci-wrapper p-0 mb-3'>
                    <div className='dropdown act-rt'>
                      <select
                        aria-expanded='false'
                        className='btn btn-bg-white dropdown-toggle'
                        id='dropdownCurrency'
                        onChange={(e)=>_handleChangeType(e)}
                        value={typeSelection}>
                        <option className='dropdown-item' value='all-destination'>Mostrar todas las actividades del destino</option>
                        <option className='dropdown-item' value='individual'>Elejir las actividades manualmente</option>
                      </select>
                    </div>
                  </div>
                  <div className='ci-wrapper p-0 mb-3'>
                    {
                      loadingDestinations ?  <Spinner size='2' top='4' /> :
                        <Typeahead
                          id='destination'
                          labelKey={option => `${option.name}`}
                          onBlur={(e)=>_handleBlur(e)}
                          onChange={(value)=>_handleChangeDestination(value)}
                          onFocus={(e)=>_handleFocus(e)}
                          options={listDestinations}
                          placeholder='Destinos'
                          selected={destination} />
                    }
                  </div>
                  <div className='ci-wrapper p-0 mb-3'>
                    {
                      loadingActivities ?  <Spinner size='2' top='4' /> :
                        <Typeahead
                          disabled={listActivities.length === 0 || typeSelection !== 'individual'}
                          id='activities'
                          labelKey={option => `${option.title}`}
                          onBlur={(e)=>_handleBlur(e)}
                          onChange={(e)=>{_handleSelectActivity(e)}}
                          onFocus={(e)=>_handleFocus(e)}
                          options={listActivities}
                          placeholder='Actividades'
                          selected={selectActivity} />
                    }
                  </div>
                  <div className='form-actions pt-3 text-right'>
                    <button
                      className='btn btn-primary-alt font-weight-bolder col-md-2'
                      id='add-activity'
                      onClick={(e)=>{pushActivity(e)}}>
                        Añadir
                    </button>
                  </div>
                  <div className='activities-container mt-3 pt-3 text-left' id='activities-container'>
                    {
                      activities.map(act => {
                        return (<div
                          className='bg-soft-blue mt-2 mr-2 p-1 pl-2 pr-2'
                          key={act._id}>
                          <span>{act.title} </span>
                          <i
                            aria-hidden='true'
                            class='fa fa-times c-pointer'
                            onClick={()=>{removeActivity(act._id)}}></i>
                        </div>)
                      })
                    }
                  </div>
                </div>
              </form>
            </div>
            <div className='mr-1 pt-4 text-center ctAf'>
              <div className='design-module'>
                <h2 className='mb-4 text-left'>Variedad de diseños</h2>
                <p className='text-left mb-1'>Disponemos de varios formatos para adaptarlos al espacio donde tengas pensado ponerlos.</p>
                <p className='text-left mb-4'>Ya sabes que el formato es diferente para cada dispositivo donde aparezca.</p>
                <div className='info text-left mb-4'>
                  <h5>Cuerpo de página</h5>
                  <div className='pt-2 text-left'>
                    <div
                      className='design act140x210 img-format'
                      data-ref='act140x210'
                      format='body-140x210'
                      onClick={(e)=>{_handleFormat(e)}}
                      ref={imgRef140x210}
                      style={{ backgroundImage: 'url(/images/actividades/act-1.jpg)' }} />
                    <div
                      className='design act153x105 img-format'
                      data-ref='act153x105'
                      format='body-153x105'
                      onClick={(e)=>{_handleFormat(e)}}
                      ref={imgRef153x105}
                      style={{ backgroundImage: 'url(/images/actividades/act-2.jpg)' }} />
                    <div
                      className='design act103x86 img-format'
                      data-ref='act103x86'
                      format='body-103x86'
                      onClick={(e)=>{_handleFormat(e)}}
                      ref={imgRef103x86}
                      style={{ backgroundImage: 'url(/images/actividades/act-3.jpg)' }} />
                    <div
                      className='design act141x43 img-format'
                      data-ref='act141x43'
                      format='body-141x43'
                      onClick={(e)=>{_handleFormat(e)}}
                      ref={imgRef141x43}
                      style={{ backgroundImage: 'url(/images/actividades/act-4.jpg)' }} />
                  </div>
                  <h5 className='mt-4'>Lateral de página</h5>
                  <div className='pt-3 mb-4 text-left'>
                    <div
                      className='design act115x97 img-format'
                      data-ref='act115x97'
                      format='lateral-115x97'
                      onClick={(e)=>{_handleFormat(e)}}
                      ref={imgRef115x97}
                      style={{ backgroundImage: 'url(/images/actividades/act-5.jpg)' }} />
                    <div
                      className='design act158x81 img-format'
                      data-ref='act158x81'
                      format='lateral-158x81'
                      onClick={(e)=>{_handleFormat(e)}}
                      ref={imgRef158x81}
                      style={{ backgroundImage: 'url(/images/actividades/act-6.jpg)' }} />
                  </div>
                </div>
                <div className='form-actions pt-3 text-right'>
                  <button
                    className='mr-1 btn btn-primary-alt font-weight-bolder'
                    id='preview'
                    onClick={()=>{_handleShowModal(true)}}>
                      Previsualizar
                  </button>
                  <Alert
                    className='m-2' show={activeAlert} style={{ position: 'absolute', right: '38px', width: '120px' }}
                    variant={'primary'}>
                         Copiado! <span aria-label='mano arriba' role='img'>&#x1f44d;</span>
                  </Alert>
                  <input
                    className='btn btn-primary font-weight-bolder'
                    onClick={(e)=>{copyCode(e)}}
                    type='button'
                    value='Copiar código' />
                </div>
              </div>
            </div>
          </div>
          <Modal
            dialogClassName={'customDialog'}
            onHide={()=>{closeModal(false)}}
            show={showModal}>
            <div className='designs-popup'>
              <div className='p-4 popup-wrapper'>
                <Modal.Header closeButton>
                  <div className='popup-header'>
                    <div className='p-2 pl-3 pr-3 popup-options' ref={modalOptionsRef} >
                      <i
                        aria-hidden='true'
                        class='fa fa-television icon-view icon-active'
                        onClick={()=>{_handleViewModal('desktop')}}
                        ref={desktopRef} ></i>
                      <i
                        aria-hidden='true'
                        class='fa fa-tablet icon-view'
                        onClick={()=>{_handleViewModal('tablet')}}
                        ref={tabletRef} ></i>
                      <i
                        aria-hidden='true'
                        class='fa fa-mobile icon-view'
                        onClick={()=>{_handleViewModal('mobile')}}
                        ref={mobileRef} ></i>
                    </div>
                    <h4 className='text-center'>Previsualización del Widget</h4>
                  </div>
                </Modal.Header>
                <div className='popup-body' style={{ minWidth: '90vw'  }}>
                  <div
                    className='text-center body-wrapper pl-3'
                    data-mode='desktop'
                    ref={wrapperModalRef}
                    style={{ maxWidth: maxWidth, minHeight: 'fit-content', width: `${width && width !== 0 ? width + 'px' : '' }` }}>
                    <div className='popup-act m-auto' data-act={`act${formatSelected.split('-')[1] || '140x210'}`}>
                      <div className='row'>
                        <div
                          className='text-center m-auto'
                          style={{ height: height ? `${height}px` : '', maxWidth: maxWidth, width: width ? `${width}px` : '1200px' }} >
                          <IFrame
                            forwardRef={iframeRef}
                            heightCalculationMethod='lowestElement'
                            inPageLinks
                            resizeFrom='parent'
                            scrolling={true}
                            sizeWidth={true}
                            src={urlToPrev}
                            style={{ border: 'none' , minWidth: '100%', width: '1px' }}
                            width={maxWidth}
                            widthCalculationMethod='taggedElement' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </Container>

  )
}

export default Widgets
