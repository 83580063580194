import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PublicRoute = ({ path, component, ...rest }) => {
  const { computedMatch: { params: { aid, token: adminToken } } } = rest
  const { token } = useSelector(state => state.user)
  if(token && (!aid && !adminToken)) return <Redirect to={'afiliados/panel'} {...rest} />

  return <Route component={component} path={path} {...rest} />
}

export default PublicRoute
