import React, { useState } from 'react'
import StatsMain from './StatsMain'
import StatsDetails from './StatsDetails'

const Stats = ({ affiliateCurrency, currencies, conversionRate = 0, defaultFreeTour, freetour, percent, data = [], type }) => {
  const [ showDetails, setShowDetails ] = useState(false)
  const [ detailsData, setDetailsData ] = useState([])
  const [ typeData, setTypeData ] = useState('reservation')
  const [ conversionPerRow, setConversionPerRow ] = useState(0)
  // const conversionPerRow = (conversionRate / data.filter((v) => v.reservations.length > 0).length).toFixed(2)

  const _handleChangeTable = (show, reservations, activities, typeData, totalConversion) => {
    setConversionPerRow(totalConversion)
    setTypeData(typeData)
    setDetailsData(typeData === 'reservation' ? reservations : activities)
    setShowDetails(show)
  }

  return (
    showDetails ?
      <StatsDetails
        affiliateCurrency={affiliateCurrency} conversionPerRow={conversionPerRow}
        conversionRate={conversionRate} currencies={currencies} defaultFreeTour={defaultFreeTour}
        freetour={freetour} onChange={_handleChangeTable} percent={percent}
        reservations={detailsData} type={type} typeData={typeData} /> :
      <StatsMain
        affiliateCurrency={affiliateCurrency}
        conversionRate={conversionRate} currencies={currencies} data={data}
        defaultFreeTour={defaultFreeTour} freetour={freetour}
        onChange={_handleChangeTable} percent={percent} type={type} />
  )
}

export default Stats
