import React, { useState, useRef, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import actionTypes from '../../actions/actionTypes'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

const PasswordRequest = (props) => {
  const { status } = useSelector(state => state.user)
  const [ confirmCode, setConfirmCode ] = useState(0)
  const [ confirmPassword, setConfirmPassword ] = useState('')
  const [ newPassword, setNewPassword ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ errors, setErrors ] = useState({})
  const [ loading, setloading ] = useState(false)
  const [ showConfirmationBlock, setShowConfirmationBlock ] = useState(false)

  const history = useHistory()
  const alertRef = useRef(null)

  useEffect(()=>{
    if(status === 'PASSWORD_REQUEST') {
      setloading(true)
    } else if(status === 'PASSWORD_REQUEST_ERROR') {
      setloading(false)
      _handleAlert('add')
    } else {
      setloading(false)}

    if(status === 'PASSWORD_REQUEST_SUCCESS' || status === 'PASSWORD_CONFIRM_CODE' || status === 'PASSWORD_CONFIRM_CODE_SUCCESS' ||
    status === 'PASSWORD_CONFIRM_CODE_ERROR' || status === 'PASSWORD_UPDATE' || status === 'PASSWORD_UPDATE_SUCCESS' ||
    status === 'PASSWORD_UPDATE_ERROR')
      setShowConfirmationBlock(true)
  },[ status, showConfirmationBlock ])

  const handleSave = (event) => {
    event.preventDefault()
    if(!formIsValid()) return
    if(showConfirmationBlock && confirmCode > 0)
      props.updatePassword(email, confirmPassword)
    else
      props.passwordRequest(email, history)
  }

  const formIsValid = () => {
    const errors = {}
    if(!email) errors.email = 'Email requerido'
    if(showConfirmationBlock)
      if(newPassword.length >= 7) {
        if(newPassword !== confirmPassword)
          errors.password = 'Ups, las contraseñas no coinciden'
      } else {
        setErrors({ message: 'Ups, las contraseñas deben poseer al menos 7 caracteres' })
      }
    setErrors(errors)

    return Object.keys(errors).length === 0
  }

  const _handleConfirmCode = (data, email) => {
    data.preventDefault()
    if(email && data.target.value)
      props.confirmCode(email, data.target.value)
  }

  const _handleAlert = (action) => {
    if(action === 'add')
      alertRef.current.classList.add('show')
    else alertRef.current.classList.remove('show')
  }

  return (
    <div className='d-flex justify-content-center align-items-center h-100vh'>
      <div className='alert alert-credentials alert-danger alert-dismissible fade' ref={alertRef} role='alert'>
        <button
          aria-label='Close'
          className='close'
          data-dismiss='alert'
          onClick={() => {_handleAlert('remove')}}
          type='button'>
          <span aria-hidden='true'>&times;</span>
        </button>
        Email incorrecto o usuario no existe
      </div>
      <div className='container b-shadow-all mt-5'>
        <div className='card  top-card m-3 mt-0'>
          <div className='card-body'>
            <div className='media'>
              <div className='media-body card-header p-1 mb-4'>
                <h2 className=' mt-2 text-center text-darki'>Resetear contraseña</h2>
              </div>
            </div>
            <div className='col-md-12'>
              <form onSubmit={(event) => handleSave(event)}>
                <div className='form-group'>
                  <label htmlFor='email'>Email:</label>
                  <input
                    aria-describedby='emailHelp' className='form-control' id='email'
                    onChange={(event) => setEmail(event.target.value)}
                    placeholder='usuario@test.com'
                    type='email' />
                </div>
                {errors.email && (
                  <div className='alert alert-danger' role='alert'>
                    {errors.email}
                  </div>
                )}
                { showConfirmationBlock ?
                  <>
                    <div className='card-body text-center'>
                      <p className='mb-2'>Email confirmado, en unos minutos recibirás el código de confirmación necesario para actualizar tu contraseña.</p>
                    </div>
                    <div className='form-group'>
                      <label htmlFor='confirm_code'>Código de confirmación:</label>
                      <input
                        className='form-control' id='confirm_code'
                        onBlur={(e) => _handleConfirmCode(e, email)}
                        onChange={(event) => setConfirmCode(event.target.value)} />
                    </div>
                    {status === 'PASSWORD_CONFIRM_CODE_ERROR' ?
                      <div className='alert alert-danger' role='alert'>
                        El código de confirmación no corresponde, no podrá cambiar la contraseña.
                      </div> :
                      null }
                    <div className='form-group'>
                      <label htmlFor='new_password'>Nueva contraseña:</label>
                      <input
                        className='form-control' id='new_password' onChange={(event) => setNewPassword(event.target.value)}
                        placeholder='Contraseña'
                        type='password' />
                    </div>
                    <div className='form-group'>
                      <label htmlFor='confirm_password'>Confirma tu nueva contraseña:</label>
                      <input
                        className='form-control' id='confirm_password' onChange={(event) => setConfirmPassword(event.target.value)}
                        placeholder='Contraseña'
                        type='password' />
                    </div>
                    {status === 'PASSWORD_UPDATE_SUCCESS' ?
                      <div className='alert alert-info' role='alert'>
                        Contraseña actualizada exitosamente.
                      </div> :
                      null }
                    {errors.password && (
                      <div className='alert alert-danger' role='alert'>
                        {errors.password}
                      </div>
                    )}

                  </> : null
                }
                <div className='text-center'>
                  <button
                    className='btn btn-primary' disabled={loading || status === 'PASSWORD_CONFIRM_CODE_ERROR' || status === 'PASSWORD_UPDATE_SUCCESS' ? true : false} style={{ marginRight: '5px' }}
                    type='submit'>
                    {loading ? 'Verificando...' : 'Enviar'}
                  </button>
                  <Link className='btn btn-primary' to={'/'}>Volver</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.user })

const mapDispatchToProps =  (dispatch) => {
  return {
    confirmCode    : (email, confirmCode) => dispatch({ confirmCode, email, type: actionTypes.PASSWORD_CONFIRM_CODE }),
    passwordRequest: (email, history) => dispatch({ email, history, type: actionTypes.PASSWORD_REQUEST }),
    updatePassword : (email, password) => dispatch({ email, password, type: actionTypes.PASSWORD_UPDATE })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordRequest)
