import React, { useRef } from 'react'
import { useEffect } from 'react'

const logo = 'https://vipealo.s3.us-east-2.amazonaws.com/images/logo.svg'

const Footer = () => {
  const logoImg = useRef()

  useEffect(()=>{
    const logoStyle = 'invert(51%) sepia(34%) saturate(7498%) hue-rotate(181deg) brightness(104%) contrast(105%)'
    logoImg.current.style.filter = logoStyle
  }, [])

  return (
    <footer className='footer'>
      <div className='container-fluid'>
        <div className='ft-b-r text-sm-right d-none d-sm-block'>
          <span>
            <img
              alt='Vasa'
              className='logo-footer'
              ref={logoImg}
              src={logo}
              width='50px' />
          </span> Afiliados | Todos los derechos reservados
        </div>
      </div>
    </footer>
  )
}

export default Footer
