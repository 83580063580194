import React from 'react'
import { slugify, getFirstPrice, getOffer, formatDuration } from '../../utils'

const Body4 = ({ activity, aid, cmp }) => {
  const getDiscount = () => {
    let result = ''
    if(activity.offer && activity.offer.type && activity.offer.amount)
      if(activity.offer.type === 'percent')
        result = activity.ofer.amount + ' %'
      else
      if(activity.offer.type === '€')
        result = activity.offer.amount + ' €'

    return result
  }

  return (
    <div className='col-sm-11  m-auto'>
      <a
        href={`https://vipealo.com/${slugify(activity.country.name)}/${slugify(activity.city.name)}/${slugify(activity.title)}/?aid=${aid}&cmp=${cmp}`} key={activity._id} rel='noopener noreferrer'
        target='_blank'>
        <article className='text-left item mb-2'  key={activity._id}>
          <div className='item-header' style={{ backgroundImage: `url(${ activity.thumbnail })` }}>
            <span className='pl-1 pr-1'><i className='icon-c clock-white' />Sin colas de espera</span>
          </div>
          <div className='pl-2 item-body'>
            <p className='font-weight-bold'>{ activity.title }</p>
            <div className>
              <div className='rate'>
                <span className='font-weight-bold'>9,7<i aria-hidden='true' className='icon-c fa fa-star ml-1'> </i></span>
                <span className='font-weight-bold'>{activity.visits} visitas</span>
                <label>Cancelación gratuita</label>
              </div>
              <div className='description'>
                <p className='mt-2 mb-4'>{ activity.metadescription }</p>
              </div>
              <div className='popup-design-footer'>
                <div className='text-left extra-info'>
                  <label className='mr-3'>
                              Duración
                    <span className='font-weight-bold'>{ formatDuration(activity.duration) }</span>
                  </label>
                  <label>
                              Idioma
                    <span className='font-weight-bold'>ESP</span>
                  </label>
                </div>
                <div className='text-right price'>
                  <span className='font-weight-bold'>
                    <span className='discount'>{ getDiscount()  }</span>
                    <span className='old-price'>{ getOffer(activity) ? getOffer(activity) + ' €' : ''  }</span>
                  </span>
                  <span className='font-weight-bold'>{`${!getFirstPrice(activity.prices) ? '--- €' : getFirstPrice(activity.prices) === 0 ? 'FREE' : getFirstPrice(activity.prices).toFixed(2) + ' €' }`}</span>
                  <span>
                    <i className='icon-c arrow-white' />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </article>
      </a>
    </div>
  )
}

export default Body4
