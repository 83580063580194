import React from 'react'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Container from '..'

const NotificationsAffiliate = () => {
  const notifications = useSelector(state => state.user.notifications || {})
  const history = useHistory()
  const _gotoReserve = () => {
    history.push('/afiliados/reservas')
  }

  return (
    <Container>
      <div className='main-content links-and-resources'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Notificaciones</h4>
              </div>
            </div>
          </div>
          <div>
            <div className='card'>
              <div className='card-body'>
                <div className='table-responsive'>
                  <table className='table allow-order mb-0'>
                    <thead>
                      <tr>
                        <th className='no-wrap'>ID</th>
                        <th className='no-wrap'>Fecha</th>
                        <th className='no-wrap'>Título</th>
                        <th className='no-wrap'>Mensaje</th>
                      </tr>
                    </thead>
                    <tbody>
                      {notifications.map(notification => (
                        <tr key={notification.nid} onClick={() => _gotoReserve()} >
                          <td>{ notification.nid }</td>
                          <td>{ moment(notification.createdAt).format('YYYY-MM-DD hh:mm:ss') }</td>
                          <td>{ notification.title }</td>
                          <td>{ notification.message }</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default NotificationsAffiliate
