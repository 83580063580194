
import { combineReducers } from 'redux'
import user from './user'
import reservations from './reservations'

const rootReducer = combineReducers({
  reservations,
  user
})

export default rootReducer
