import React from 'react'

const Spinner = ({ size, top }) => {
  return (
    <div className={`text-center mt-${top ? top : 5}`}>
      <i className={`fa fa-spinner fa-pulse fa-${size ? size : 3}x fa-fw text-primary mx-auto`}></i>
    </div>
  )
}

export default Spinner
