import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

const Tops = ({ mainClass = 'col-xl-12', mainTitle, topsData = [],  topsType = 'quantity', topsDataType = 'default', topsShowValues = true }) => {
  const [ currentType, setCurrentType ] = useState(topsType)
  const [ currentData, setCurrentData ] = useState(topsData)
  const [ topClass, setTopClass ] = useState(mainClass)
  const [ currentTitle ] = useState(mainTitle || 'sin titulo')
  const [ currentDataType, setCurrentDataType ] = useState(topsDataType)
  const [ limmitRows, setLimmitRows ] = useState(3)
  const [ showMore, setShowMore ] = useState(false)

  const _handleShowMore = (e, showMore) => {
    e.preventDefault()
    if(!showMore) {
      setLimmitRows(9)
      setShowMore(true)
    } else {
      setLimmitRows(3)
      setShowMore(false)
    }
  }

  useEffect(()=>{
    setCurrentType(topsType)
    setCurrentData(topsData)
    setTopClass(mainClass)
    setCurrentDataType(topsDataType)
  }, [ topsType, topsData, mainClass, topsDataType ])

  const _handleRow = (e) => {
    e.preventDefault()
  }

  return (
    <div className={topClass}>
      <div className='card'>
        <div className='card-body'>
          <h4 className='card-title mb-4'>{currentTitle}</h4>
          <ul className='inbox-wid list-unstyled'>
            {
              currentData && currentData.map((data, pos) => {
                let title, thumbnail, quantity, clicks, visits, date
                if(currentDataType === 'default') {
                  title = data.activity.huno && data.activity.huno.length ? data.activity.huno : data.activity.title
                  thumbnail = data.activity.thumbnail
                  quantity = topsShowValues ? data.quantity : ''
                  clicks = topsShowValues ? data.clicks : ''
                } else if(currentDataType === 'dates') {
                  title = data.details.activity.huno && data.details.activity.huno.length ? data.details.activity.huno : data.details.activity.title
                  thumbnail = data.details.activity.thumbnail
                  date = moment(data.createdAt).format('DD-MM-YYYY')
                } else {
                  title = data.huno && data.huno.length ? data.huno : data.title
                  thumbnail = data.thumbnail
                  visits = topsShowValues ? data.visits : ''
                }
                if(pos <= limmitRows)
                  return (
                    <li className='inbox-list-item' key={data._id || data.activity._id}>
                      <a href='/#' onClick={(e) => _handleRow(e)}>
                        <div className='media'>
                          <div className='media-body overflow-hidden'>
                            <img alt='reserva' src={thumbnail} />
                            <span className='font-size-16 mb-1'>{title}</span>
                          </div>
                          <div className='extra-info font-size-12 ml-2'>
                            {currentDataType === 'default' ? <span>{currentType === 'quantity' ? quantity : clicks }</span> : null}
                            {currentDataType !== 'default' || currentDataType !== 'dates' ? <span>{visits}</span> : null}
                            {currentDataType === 'dates' ? <span>{date}</span> : null}
                          </div>
                        </div>
                      </a>
                    </li>
                  )
                else
                  return (null)
              })
            }
          </ul>
          <div className='text-right'>
            {currentData && currentData.length > 3 ?
              !showMore ?
                <button className='btn btn-primary btn-sm' onClick={(e) => _handleShowMore(e, showMore)} >Ver más...</button> :
                <button className='btn btn-primary btn-sm' onClick={(e) => _handleShowMore(e, showMore)} >Ver menos...</button> :
              null}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(Tops)
