import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { changeMonth } from '../../../reducers/reservations'
import moment from 'moment-timezone'
import Container from '..'
import GraphicStats from '../../../components/Affiliates/GraphicStats'
import Spinner from '../../../components/Spinner'
import Error from '../../../components/Error'
import Tops from '../../../components/Tops'

const DashboardAffiliate = () => {
  const dispatch = useDispatch()
  const {
    data,
    defaultFreeTour,
    month,
    loading,
    error,
    myMonthClicks,
    conversionRate,
    myLastSales,
    // quantFreetour,
    subTotal,
    total
    // totalToBilling
  } = useSelector(state => state.reservations)
  const { affiliate: { percent, bill, freetour } } = useSelector(state => state.user)
  const [ affiliateCurrency ] = useState(bill && bill.currency ? bill.currency : 'EUR')

  const _handleMonth = (month) => {
    dispatch(changeMonth(month))
  }

  useEffect(()=>{
    if(!month)
      _handleMonth(moment().format('yyyy-MM'))
    else _handleMonth(month)
    // eslint-disable-next-line
  }, [])

  return (
    <Container>
      <div className='page-content'>
        <div className='row'>
          <div className='col-12'>
            <div className='page-title-box d-flex align-items-center justify-content-between'>
              <h4 className='page-title mb-0 font-size-18'>Panel</h4>
            </div>
          </div>
        </div>
        { error ? <Error code={500} type={'server error'} /> : loading ?
          <Spinner />          :
          (<div>
            <div className='date-filter row mb-3 input-month'>
              <div className='col-md-3'>
                <input
                  className='form-control'
                  defaultValue={month}
                  max={moment().format('yyyy-MM')}
                  min={moment().format('2020-07')}
                  onChange={({ target: { value } }) => _handleMonth(value)}
                  type='month' />
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-3'>
                <div className='card top-card card-dynamic-height'>
                  <div className='card-body'>
                    <div className='media'>
                      <div className='avatar-sm font-size-20 mr-3'>
                        <span className='avatar-title bg-soft-blue text-primary rounded'>
                          <i className='fa fa-bookmark text-soft-blue' />
                        </span>
                      </div>
                      <div className='media-body'>
                        <div className='font-size-16 mt-2'><span>Cantidad de reservas</span></div>
                      </div>
                    </div>
                    <h4 className='mt-4 h4-c wh-sp-nw'>{ data && data.totalDocs ? data.totalDocs : 0 }</h4>
                    <div className='row'>
                      <div className='col-7'>
                        <p className='mb-0'>
                          <span className='mr-2 span-c'>Clics: { myMonthClicks }</span>
                        </p>
                      </div>
                      <div className='col-5 text-right'>
                        <p className='mb-0'>
                          <span className='span-c'><span>{ conversionRate }%</span> CR</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-3'>
                <div className='card top-card card-dynamic-height'>
                  <div className='card-body'>
                    <div className='media'>
                      <div className='avatar-sm font-size-20 mr-3'>
                        <span className='avatar-title bg-soft-red text-primary rounded'>
                          <i className='fa fa-shopping-cart text-soft-red' />
                        </span>
                      </div>
                      <div className='media-body'>
                        <div className='font-size-16 mt-2'><span>Ventas totales</span></div>
                      </div>
                    </div>
                    <h4 className='mt-4 h4-c wh-sp-nw'>{ `${total ? total.toFixed(2) : 0} ${affiliateCurrency}`}</h4>
                    <div className='row'>
                      <div className='col-10'>
                        <p className='mb-0'>
                          <span className='span-c'>{`${subTotal ? subTotal.toFixed(2) : 0} ${affiliateCurrency}`} de comisión</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-3'>
                <div className='card top-card card-dynamic-height'>
                  <div className='card-body'>
                    <div className='media'>
                      <div className='avatar-sm font-size-20 mr-3'>
                        <span className='avatar-title bg-soft-purple text-primary rounded'>
                          <i className='fa fa-pie-chart text-soft-purple' />
                        </span>
                      </div>
                      <div className='media-body'>
                        <div className='font-size-16 mt-2'><span>Comisión</span></div>
                      </div>
                    </div>
                    <h4 className='mt-4 h4-c wh-sp-nw'>{ percent }%</h4>
                    <div className='row'>
                      <div className='col-10'>
                        <p className='mb-0'>
                          <span className='span-c'>{`${(defaultFreeTour * (freetour ? freetour : 1)).toFixed(2) || 0} ${affiliateCurrency}`}  Freetour</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl-3'>
                <div className='card top-card'>
                  <div className='card-body'>
                    <div className='media'>
                      <div className='avatar-sm font-size-20 mr-3'>
                        <span className='avatar-title bg-soft-green text-primary rounded'>
                          <i className='fa fa-list text-soft-green' />
                        </span>
                      </div>
                      <div className='media-body'>
                        <div className='font-size-16 mt-2'><span>Comisiones totales a facturar</span></div>
                      </div>
                    </div>
                    <h4 className='mt-4 h4-c wh-sp-nw'>{ `${subTotal ? subTotal.toFixed(2) : 0} ${affiliateCurrency}` }</h4>
                    <div className='row'>
                      <div>
                        <p className='mb-0'>
                          <span className='mr-2 span-c'>
                          Hasta el mes seleccionado
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-xl-6'>
                <div className='card'>
                  <div className='card-body'>
                    <h4 className='card-title mb-4'>Estadísticas</h4>
                    <GraphicStats month={month} type={'month'} />
                  </div>
                </div>
              </div>
              <Tops
                mainClass={'col-xl-6'} mainTitle={'Últimas reservas'} topsData={myLastSales}
                topsDataType={'dates'} topsType={'dates'} />
            </div>
          </div>)
        }
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(DashboardAffiliate)
