import { post } from 'axios'
import { call, cancel, cancelled, fork, put, take } from 'redux-saga/effects'
import { api } from '../utils'
import actionTypes from '../actions/actionTypes'
const { REACT_APP_API } = process.env

export function authUser(email, password, path, id, token) {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await post(`${REACT_APP_API}/users/${path}`, { email, id, origin: 'affiliates', password, token })
      resolve(data)
    } catch (error) {
      reject(error)
    }
  })
}

export function* authorize(user, password, history) {
  try {
    const { user: userLogged, token, error, notifications } = yield call(authUser, user, password, 'login')
    if(token) {
      // alert(`USER LOGGED IN, TOKEN: ${token}`)
      yield put({ type: 'LOGIN_SUCCESS' })
      yield put({ type: 'STORE_USER', user: userLogged })
      yield put({ notifications, type: 'STORE_NOTIFICATION' })
      yield put({ token, type: 'STORE_TOKEN' })
      history.push('/afiliados/panel')
    } else {
      yield put({ error, type: 'LOGIN_ERROR' })
    }
  } catch (error) {
    yield put({ error, type: 'LOGIN_ERROR' })
  }
  finally {
    if(yield cancelled())
      yield put({ type: 'LOGIN_CANCELLED' })
  }
}

export function* loginFlow() {
  while (true) {
    const { user, password, history } = yield take('LOGIN_REQUEST')
    const task = yield fork(authorize, user, password, history)
    const action = yield take([ 'LOGOUT', 'LOGIN_ERROR' ])
    if(action.type === 'LOGOUT') {
      yield cancel(task)
      yield put({ type: 'DELETE_TOKEN' })
      yield put({ type: 'DELETE_CONTENT' })
      // alert('USER LOGGED OUT, TOKEN DELETED')
      history.push('/')
    }
  }
}

export function* adminLogin() {
  while (true) {
    const { id, token, history } = yield take('ADMIN_LOGIN_REQUEST')
    const { user: userLogged, token: userToken, success, error, notifications } = yield call(authUser, null, null, 'reLogin', id, token)
    if(userToken && success) {
      yield put({ type: 'DELETE_TOKEN' })
      yield put({ type: 'DELETE_CONTENT' })
      yield put({ type: 'LOGIN_SUCCESS' })
      yield put({ type: 'STORE_USER', user: userLogged })
      yield put({ notifications, type: 'STORE_NOTIFICATION' })
      yield put({ token: userToken, type: 'STORE_TOKEN' })
      history.push('/afiliados/panel')
    } else {
      yield put({ error, type: 'LOGIN_ERROR' })
    }
  }
}

export function* logActions() {
  while (true) {
    const action = yield take('*')
    // eslint-disable-next-line no-restricted-syntax
    console.log(action.type)
  }
}

export function* updateUser() {
  while (true) {
    const { user } = yield take('UPDATE_USER_REQUEST')
    const body = { data: user, userId: user._id }
    try {
      const result = yield api('post', 'users', 'update', body, user.token)
      if(result.success)
        yield put({ type: 'UPDATE_USER_SUCCESS', user: result.user })
      else
        yield put({ error: result.message, type: 'UPDATE_USER_ERROR' })
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      yield put({ type: 'UPDATE_USER_ERROR' })
    }
  }
}

export function* createUser() {
  while (true) {
    const { user, history } = yield take('CREATE_USER')
    const body = { data: user }
    try {
      const { success, message } = yield api('post', 'users', 'createAffiliate', body)
      if(success)
        history.push('/')
      else
        yield put({ error: message, type: 'CREATE_USER_ERROR' })
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      yield put({ error, type: 'CREATE_USER_ERROR' })
    }
  }
}

export function* markNotification() {
  while (true) {
    const { history, notificationId } = yield take('MARK_NOTIFICATION')
    const body = { data: notificationId }
    try {
      const result = yield api('post', 'users', 'markNotification', body)
      if(result.success) {
        yield put({ notifications: result.notifications, type: 'STORE_NOTIFICATION' })
        history.push('/afiliados/reservas')
      } else {
        yield put({ error: result.message, type: 'MARK_NOTIFICATION_ERROR' })
      }
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      yield put({ error, type: 'MARK_NOTIFICATION_ERROR' })
    }
  }
}

export function* passwordRequest() {
  while (true)
    try {
      const { email } = yield take('PASSWORD_REQUEST')
      const body = { data: email, origin: 'affiliates' }
      const { success } = yield api('post', 'users', 'passwordRequest', body)
      if(success)
        yield put({ type: actionTypes.PASSWORD_REQUEST_SUCCESS })
      else
        yield put({ type: actionTypes.PASSWORD_REQUEST_ERROR })
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      yield put({ error, type: actionTypes.PASSWORD_REQUEST_ERROR })
    }
}

export function* confirmCode() {
  while (true)
    try {
      const { confirmCode, email } = yield take('PASSWORD_CONFIRM_CODE')
      const body = { data: confirmCode, email }
      const { success } = yield api('post', 'users', 'confirmCode', body)
      if(success)
        yield put({ type: actionTypes.PASSWORD_CONFIRM_CODE_SUCCESS })
      else
        yield put({ type: actionTypes.PASSWORD_CONFIRM_CODE_ERROR })
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      yield put({ error, type: actionTypes.PASSWORD_CONFIRM_CODE_ERROR })
    }
}

export function* passwordUpdate() {
  while (true)
    try {
      const { email, password } = yield take('PASSWORD_UPDATE')
      const body = { data: email, password }
      const { success } = yield api('post', 'users', 'passwordUpdate', body)
      if(success)
        yield put({ type: actionTypes.PASSWORD_UPDATE_SUCCESS })
      else
        yield put({ type: actionTypes.PASSWORD_UPDATE_ERROR })
    } catch (error) {
      // eslint-disable-next-line no-restricted-syntax
      console.log('error', error)
      yield put({ error, type: actionTypes.PASSWORD_CONFIRM_CODE_ERROR })
    }
}

export function* sendEmail() {
  while (true)
    try {
      const { data } = yield take('EMAIL_REQUEST')
      const { token } = data
      const body = { data }
      const { success } = yield api('post', 'affiliates', 'sendEmail', body, token)
      if(success)
        yield put({ type: actionTypes.EMAIL_SUCCESS })
      else
        yield put({ type: actionTypes.EMAIL_ERROR })
    } catch (error) {
      console.log('error', error)
      yield put({ error, type: actionTypes.EMAIL_ERROR })
    }
}

export function* getSettings() {
  while (true)
    try {
      yield take('GET_SETTINGS')
      const { success, default_settings } = yield api('post', 'users', 'affiliateSettings', {})
      if(success)
        yield put({ default_settings, type: actionTypes.STORE_DEFAULT_SETTINGS })
      else
        yield put({ type: actionTypes.STORE_DEFAULT_SETTINGS_ERROR })
    } catch (error) {
      console.log('error', error)
      yield put({ error, type: actionTypes.SETTINGS_ERROR })
    }
}

export default [
  /* fork(logActions),*/ fork(loginFlow), fork(updateUser),
  fork(createUser), fork(markNotification), fork(passwordRequest),
  fork(confirmCode), fork(passwordUpdate), fork(sendEmail),
  fork(getSettings), fork(adminLogin)
]
