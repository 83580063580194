import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import actionTypes from '../../actions/actionTypes'

const GeneralConditions = ({ user, ...props }) => {
  const location = useLocation()
  const [ settings, setSettings ] = useState(location.pathname === '/afiliados/condiciones-generales' ? user.settings : user.default_settings)

  useEffect(() => {
    _handleSettings()
    // eslint-disable-next-line
  }, [  ])

  useEffect(() => {
    if(user.status === 'DEFAULT_SETTINGS_STORED') setSettings(location.pathname === '/afiliados/condiciones-generales' ? user.settings : user.default_settings)
    // eslint-disable-next-line
  }, [ user.status ])

  const _handleSettings = () => props.getSettings()

  return (
    <div className='main-content my-account'>
      <div className='page-content'>
        <div className='row' style={{ background: location.pathname === '/afiliados/registrarse/condiciones-generales' ? '#009DFF' : '' }}>
          <div className='col-12'>
            <div className='page-title-box d-flex align-items-center justify-content-between'>
              <h4 className='page-title mb-0 font-size-18'>Condiciones generales</h4>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='card card-body'>
            <div className='mb-4'>
              <div className='media-body'>
                <p className='text-muted'>
                  Vipealo SL, domiciliada en Calle de José de Abascal 56, 28003 Madrid y provista de NIF B-88579404 (en adelante “VIPEALO”), titular del Sitio Web
                  www.vipealo.com, es una compañía dedicada a la gestión de compra y reserva de cualquier actividad turística que se precie tanto dentro como fuera de
                  España para los usuarios que quieran contratar algunos de estos servicios.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>1.- Objeto.</h1>
                <p className='text-muted'>
                  Estas condiciones generales (en adelante, las “Condiciones Generales”) tienen como objeto regular las condiciones en virtud de las cuales una persona
                  física o jurídica (en adelante “Afiliado”) publicitará a través de banners, links o cualquier tipo de medio proporcionados por Vipealo como la API
                  o el feed (en adelante, los “Recursos”) a través de su sitio web; y los servicios relacionados con las actividades turísticas publicitadas por Vipealo,
                  a cambio de una comisión previamente estipulada conforme al apartado 4 de las Condiciones Generales expuestas.
                </p>
                <p className='text-muted'>
                  La incorporación al programa de afiliados de Vipealo supone aceptar previamente las Condiciones Generales expuestas a través de una solicitud
                  cumplimentada correctamente y enviada por el usuario Afiliado para dar el alta en el panel de afiliación. Una vez esté hecho el envío de dicha
                  solicitud, Vipealo enviará un email al usuario Afiliado en el que se le informará sobre si su solicitud previa ha sido aceptada o declinada sin
                  que tenga que ser justificada dicha respuesta.
                </p>
                <p className='text-muted'>
                  La incorporación al programa de afiliación no tiene coste alguno, ni tampoco de permanencia.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>2.- Derechos y obligaciones de Vipealo.</h1>
                <h1 className='mb-3'>2.1 Vipealo tendrá los derechos citados a continuación:</h1>
                <p className='text-muted'>
                  (I) Vipealo tendrá la opción tanto de dar de baja la cuenta del usuario Afiliado, como de retener las comisiones generadas si la cuenta de afiliación genera movimientos
                  sospechosos relacionados con la manipulación del sistema de comisiones o conductas que puedan ser negligentes o fraudulentas.
                </p>
                <p className='text-muted'>
                  (II) Vipealo podrá asegurarse de que los links HTML funcionan correctamente monitorizando los sitios web pertenecientes al panel de Afiliación. Si se descubre un mal
                  funcionamiento de alguno de ellos, Vipealo hará saber al usuario Afiliado que debe de efectuar los cambios pertinentes en un periodo no superior a 7 días desde que
                  recibe la notificación. Se suspenderá la condición de Afiliado si el usuario no realiza dichos cambios.
                </p>
                <p className='text-muted'>
                  (III) Si el usuario Afiliado no realiza un correcto uso de las Herramientas, ya sea por no usarlas correctamente o utilizarlas de forma independiente para su
                  beneficio, Vipealo tendrá la opción de suspender o cancelar la cuenta del usuario Afiliado así como del uso de las Herramientas. Vipealo también podrá suspender
                  o cancelar la cuenta del Afiliado si dicho usuario no cumple con los flujos de reserva previamente concretados con Vipealo.
                </p>
                <h1 className='mb-3'>2.2 Vipealo se hará cargo de las obligaciones siguientes:</h1>
                <p className='text-muted'>
                  (I) Cuando la solicitud de afiliación sea aprobada, Vipealo se compromete tanto a dar de alta la cuenta de Afiliado como de facilitar el acceso del mismo a
                  la plataforma correspondiente.
                </p>
                <p className='text-muted'>
                  (II) Vipealo tendrá la obligación de dar acceso y facilitar la descarga de los medios promocionales disponibles como links HTML y URL que harán posible la
                  identificación del usuario y sus compras del Sitio Web de Vipealo. No solo con eso, Vipealo tendrá que facilitar las Herramientas al Afiliado para que pueda utilizarlas.
                </p>
                <p className='text-muted'>
                  (III) Vipealo se compromete a hacer un registro de cada uno de sus Afiliados y de realizar un seguimiento constante a las transacciones realizadas por ellos, poniendo a su
                  alcance informes o dosieres relacionados con los movimientos que produzcan dichos usuarios.
                </p>
                <p className='text-muted'>
                  (IV) Vipealo tendrá que pagar al usuario Afiliado la comisión instaurada en el apartado 4 de las actuales Condiciones Generales.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>3.- Derechos y obligaciones del Afiliado.</h1>
                <h1 className='mb-3'>3.1 El Afiliado tendrá los derechos citados a continuación:</h1>
                <p className='text-muted'>
                  (I) El Afiliado podrá hacer uso del logotipo, la marca y cualquier medio promocional de Vipealo en su/s Sitio/s Web siempre y cuando cumplan los
                  términos citados en las Condiciones Generales.
                </p>
                <p className='text-muted'>
                  (II) El Afiliado podrá utilizar cualquiera de las Herramientas mencionadas en el primer apartado de las Condiciones Generales expuestas.
                </p>
                <h1 className='mb-3'>3.2 El Afiliado se hará cargo de las obligaciones siguientes:</h1>
                <p className='text-muted'>
                  (I) El Afiliado declara la veracidad de toda la información que proporciona a Vipealo y que, si se produjera algún cambio, la información
                  será inmediatamente actualizada.
                </p>
                <p className='text-muted'>
                  (II) El usuario Afiliado deberá asegurarse de que su clave y contraseña de acceso al área de Afiliado están debidamente protegidas frente al uso por
                  otras personas. Además, deberá solicitar de forma inmediata una nueva clave a Vipealo si sospecha que sus claves han sido utilizadas sin consentimiento
                  por terceros. En relación con esto, el usuario Afiliado excusa de todo tipo de responsabilidad a Vipealo por los daños que haya podido ocasionar por
                  su uso indebido.
                </p>
                <p className='text-muted'>
                  (III) El usuario Afiliado se hace responsable de su/s sitio/s Web y su contenido de forma que se compromete a cumplir la totalidad de las normativas
                  y leyes aplicables incluyendo las relacionadas con la privacidad y la protección de datos. A este parecer, el Afiliado declara que su/s Sitio/s Web
                  incluyen todos los textos legales correspondientes a la normativa vigente, y que su contenido cumple con todas las disposiciones legales exigidas en
                  relación con la protección de datos conforme a lo estipulado en el apartado 10 de las actuales Condiciones Generales.
                </p>
                <p className='text-muted'>
                  (IV) El usuario Afiliado solo podrá utilizar los medios promocionales exclusivamente para dirigir a los visitantes y/o clientes al Sitio Web de Vipealo.
                  Como consecuente, el usuario utilizará exclusivamente los materiales y diseños que se encuentren a su disposición en el área de afiliados (logotipos,
                  banners, imágenes) sin poder alterar el diseño o realizar modificaciones de algún tipo. A estos efectos, el Afiliado tendrá que utilizar los medios
                  disponibles en el área de afiliados en cada momento.
                </p>
                <p className='text-muted'>
                  (V) El usuario Afiliado garantiza que su/s Sitio/s Web utilizan contenido que, o bien le pertenece, o tiene permiso por el propietario para utilizarlo.
                  Además, garantiza que dicho contenido o información no infringe ninguno de los derechos de propiedad intelectual de terceros. Asimismo, asegura que ese
                  contenido, no es, ni será (durante el periodo en vigor de las Condiciones Generales expuestas) ofensivo, de mal gusto, ni cuestionable de ninguna forma
                  (racista, machista, sexual, violento…).
                </p>
                <p className='text-muted'>
                  (VI) El usuario Afiliado no debe originar, ni motivar la generación de Tráfico Artificial al sitio Web de Vipealo.
                </p>
                <p className='text-muted'>
                  (VII) Si el usuario Afiliado intenta generar de alguna forma fraudulenta o ilegal Tráfico Artificial al Sitio Web Vipealo o no cumple las Condiciones
                  Generales, Vipealo puede, no solo anular su condición de Afiliado, también reclamar una compensación en modo de indemnización por los daños y perjuicios
                  que el Afiliado haya podido causar a Vipealo en relación con la legislación vigente. No solo con eso, en el caso en concreto Vipealo tendrá la opción de
                  anular cualquiera de los pagos de comisiones que le quede por pagar al usuario Afiliado, incluyendo las comisiones pendientes de pago.
                </p>
                <p className='text-muted'>
                  (VIII) El usuario Afiliado se hará cargo de forma única del desarrollo, mantenimiento y operaciones tanto de su sitio web como de todos los medios
                  materiales que aparezcan en ella.
                </p>
                <p className='text-muted'>
                  (IX) El usuario Afiliado únicamente tendrá la opción de abrir cuentas a su nombre e interés propio. De esta forma, no queda permitido transferir de las
                  cuentas existentes a favor de un tercero, sin que no haya de por medio una autorización por escrito y expresa de Vipealo.
                </p>
                <p className='text-muted'>
                  (X) El usuario Afiliado toma el compromiso de realizar una actividad promocional constante con la finalidad de conseguir de forma periódica nuevos clientes
                  a Vipealo. En referencia a publicar de forma atractiva y constante los links que Vipealo le haya proporcionado en todos su/s sitio/s webs.
                </p>
                <p className='text-muted'>
                  (XI) El usuario Afiliado no comprará, pujará o registrará palabras clave en ninguno de los buscadores o metabuscadores existentes (Google ads, Yandex, Bing…).
                  Los miembros no buscarán palabras u otros elementos similares a las marcas registradas de Vipealo, estas palabras u otros elementos pueden ser utilizados como
                  referencia de identificación en cualquier servicio de Internet.
                </p>
                <p className='text-muted'>
                  (XII) Sin la autorización por escrito de los proveedores de Vipealo el usuario Afiliado no tendrá la opción de utilizar ni la marca ni cualquier otro medio
                  promocional perteneciente a los proveedores de Vipealo.
                </p>
                <p className='text-muted'>
                  (XIII) El usuario Afiliado se compromete a utilizar las Herramientas proporcionadas por Vipealo según lo estipulado en la documentación previamente recibida
                  en relación con el procedimiento y el protocolo de dichas Herramientas.
                </p>
                <p className='text-muted'>
                  (XIV) Tanto las obligaciones como las responsabilidades del uso de las Herramientas citadas en el anterior punto se someterán a lo establecido en los
                  procedimientos y protocolos que el usuario Afiliado recibirá de Vipealo.
                </p>
                <p className='text-muted'>
                  (XV) El usuario Afiliado no tendrá permitido la utilización de las Herramientas para construir algún tipo de relación comercial de forma independiente a Vipealo
                  con otros usuarios o proveedores de Vipealo. También quedará prohibido para el usuario Afiliado incumplir alguna de las indicaciones mencionadas en los
                  procedimientos y protocolos.
                </p>
                <p className='text-muted'>
                  (XVI) El usuario Afiliado toma el compromiso de mantener actualizada la información proporcionada por Vipealo en cuanto a procedimientos y protocolos de las
                  Herramientas en su/s sitio/s web. Así como con la información relacionada con precios, ofertas y promociones publicadas en el Sitio Web de Vipealo.
                </p>
                <p className='text-muted'>
                  De esta forma, el usuario Afiliado toma el compromiso de ejecutar actualizaciones de forma periódica del feed (mínimo una al mes) y de mostrar la información
                  recibida de Vipealo sin alteración alguna. Esto quiere decir que el usuario Afiliado exime a Vipealo de cualquier responsabilidad frente a terceros por el no
                  cumplimiento de las obligaciones presentes en este apartado 3.2 de las Condiciones Generales.
                </p>
                <p className='text-muted'>
                  (XVII) El usuario Afiliado toma el compromiso de no mostrar las credenciales de las Herramientas con otras personas.
                </p>
                <p className='text-muted'>
                  (XVII) El usuario Afiliado toma el compromiso de cumplir con lo estipulado en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril
                  de 2016, relativo a la protección de las personas físicas en lo que hace referencia tanto al tratamiento de datos personales como a la libre circulación de estos
                  datos y por el que se deroga la Directiva 95/46/ CE (Reglamento general de protección de datos) en todo el mundo y la Ley Orgánica 3/2018, de 5 de diciembre, de
                  Protección de Datos Personales y garantía de los derechos digitales en España, eximiendo a Vipealo de toda responsabilidad sobre el uso de sus bases de datos personales.
                  En concreto, el usuario Afiliado español se compromete a cumplir con los criterios de la Agencia Española de Protección de Datos en materia de Cookies.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>4.- Comisiones.</h1>
                <h1 className='mb-3'>4.1 Porcentaje y cálculo.</h1>
                <p className='text-muted'>
                  Por cada venta generada por un cliente referido desde el sitio web del usuario Afiliado a Vipealo, el usuario Afiliado recibirá una comisión abonada por Vipealo. Como cliente
                  referido se entiende cualquier persona que visite el/los sitio/s web del Afiliado, haga clic en el link del Sitio Web de Vipealo o realice alguna reserva desde la API proporcionada
                  por Vipealo y haga una o más compras de productos ofertados por Vipealo de forma directa.
                </p>
                <p className='text-muted'>
                  El programa de Afiliados tiene una configuración flexible que se adapta a las comisiones dependiendo del importe generado, desde el sitio web del Afiliado, por las ventas
                  mensualmente.
                </p>
                <p className='text-muted'>
                  El día 1 de cada mes natural se ajustará el cálculo del porcentaje de comisión. Dicho ajuste se efectuará de forma automática en relación con las ventas generadas en el mes
                  anterior por los clientes referidos desde el sitio web del Afiliado a Vipealo.
                </p>
                <p className='text-muted'>
                  {`La facturación de las ventas tendrá hasta una comisión del ${settings && settings.afiliados ? settings.afiliados : '20'}% sobre las ventas generadas.`}
                </p>
                <p className='text-muted'>
                  {`A esta comisión del ${settings && settings.afiliados ? settings.afiliados : '20'}% se les sumará un canon de hasta ${settings && settings.afiliados_free_tour ? settings.afiliados_free_tour : '1'} euros a favor del usuario Afiliado por cada actividad Free tour reservada y realizada por un cliente.`}
                </p>
                <p className='text-muted'>
                  Vipealo se reserva el derecho a modificar el porcentaje de la comisión así como la cantidad acordada por free tour, siendo de obligado cumplimiento el preaviso al usuario Afiliado.
                </p>
                <p className='text-muted'>
                  Las ventas realizadas a un cliente referido en el periodo de treinta días naturales siguientes a la entrada de un cliente referido al Sitio Web de Vipealo
                  (por medio de una cookie de 30 días) serán reconocidas a favor del Afiliado.
                </p>
                <p className='text-muted'>
                  Esto quiere decir que Vipealo incluirá una cookie sobre el cliente referido cuando este mismo visite por primera vez el Sitio Web, con lo cual, si el cliente retorna al
                  Sitio Web en un plazo de 30 días naturales para hacer alguna reserva de los servicios de Vipealo, la comisión del usuario Afiliado le será reconocida.
                </p>
                <h1 className='mb-3'>4.2 Forma de pago y facturación.</h1>
                <p className='text-muted'>
                  La condiciones de pago y facturación serán las siguientes:
                </p>
                <p className='text-muted'>
                  (I) Las comisiones serán abonadas un mes después de que el cliente referido reciba la prestación del servicio.
                </p>
                <p className='text-muted'>
                  (II) Una vez se acepten estas Condiciones Generales, el usuario Afiliado tomará conciencia expresa del sistema de autofacturación, implementado por Vipealo, conforme el cual
                  Vipealo hará la factura a su nombre y le pagará con relación a dicha factura, conforme con las comisiones adquiridas por el usuario Afiliado. Tantos los impuestos que resulten de
                  aplicación (si los hay) como el IVA serán añadidos a la remuneración mostrada en el panel de afiliados. En el supuesto de que sea el usuario Afiliado el que emita su factura de
                  comisión deberá hacérselo saber expresamente a Vipealo para que no se le aplique la autofactura de comisión.
                </p>
                <p className='text-muted'>
                  (III) El sistema de autofacturación generará la factura el día 15 de cada mes y los pagos relacionados con dichas facturas se efectuarán de forma automática. El usuario Afiliado
                  encontrará la factura, una vez que se emita, en la sección “Facturación” del Panel de Afiliado.
                </p>
                <p className='text-muted'>
                  (IV) Si el usuario Afiliado necesita pedir a Vipealo modificaciones en la factura tendrá un plazo de 7 días para llevarlo a cabo, transcurrido dicho periodo, se sobreentenderá que
                  la factura ha sido realizada correctamente.
                </p>
                <p className='text-muted'>
                  Si se diera el caso de que Vipealo necesitara más información del usuario Afiliado para realizar el proceso de autofacturación, el usuario Afiliado recibirá una alerta en su panel
                  de afiliado solicitando que actualice su información.
                </p>
                <p className='text-muted'>
                  (V) En el caso de que los datos aportados por el usuario Afiliado para la facturación sean incorrectos o inexactos, el usuario Afiliado asume toda la responsabilidad y exime
                  expresamente a Vipealo.
                </p>
                <p className='text-muted'>
                  (VI) En conformidad a lo antes expuesto, el usuario Afiliado autoriza a Vipealo a que expida las facturas necesarias en relación con las transacciones que genere el usuario
                  Afiliado.
                </p>
                <p className='text-muted'>
                  (VII) Las cantidades menores a cincuenta euros (50€) que tengan que ser abonadas por transferencia bancaria, se abonarán solamente cuando la cantidad acumulada total sea
                  superior a cincuenta euros (50€). Para las demás cantidades de comisión inferiores a cincuenta euros (50€) se transferirán al mes siguiente y únicamente se pagarán cuando la
                  cantidad acumulada supere los cincuenta euros (50€).
                </p>
                <p className='text-muted'>
                  (VIII) El usuario Afiliado asumirá los costes ocasionados por la transferencia bancaria, con un mínimo de dos euros (2€) por transferencia, junto con la responsabilidad por
                  daños y perjuicios que pueda causar a Vipealo como consecuencia de proporcionar por parte del usuario Afiliado información bancaria incorrecta o insuficiente.
                </p>
                <p className='text-muted'>
                  (IX) En el caso de existir una cancelación de una reserva, una reclamación del pago o la realización de cualquier fraude, la comisión relacionada con esta reserva queda anulada.
                </p>
                <p className='text-muted'>
                  (X) Los pagos deberán realizarse en Euros. En el caso que se realicen con otro tipo de divisa serán calculados el último día del mes del pago referido según el tipo de
                  cambio indicado en www.bde.es.
                </p>
                <p className='text-muted'>
                  (XI) El pago de todos los impuestos, seguros, tasas y gastos de esta índole producidos por los pagos realizados a su favor por Vipealo serán asumidos por el usuario Afiliado.
                </p>
                <p className='text-muted'>
                  (XII) El Panel de Afiliación mostrará al usuario Afiliado toda la información con relación a los productos vendidos y las ventas realizadas a los clientes referidos, así como la
                  fecha del pago de las comisiones.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>5.- Período del contrato.</h1>
                <p className='text-muted'>
                  Las Condiciones Generales expuestas entrarán en vigor cuando el usuario Afiliado sea comunicado sobre la aprobación de su solicitud de afiliación y su duración será equivalente a
                  un año prorrogable tácitamente en periodos iguales, a condición que ninguna de las partes haga una notificación escrita sobre su intención de finalizar la relación contractual con
                  una antelación de treinta días naturales a la finalización del contrato.
                </p>
                <p className='text-muted'>
                  Sin perjuicio de las disposiciones anteriores, Vipealo se reserva el derecho a finalizar la relación contractual con el usuario Afiliado en el momento que sea necesario sin previo
                  requerimiento del Afiliado, en el caso de que dicho usuario incumpliera alguna de las normas aquí expuestas.
                </p>
                <p className='text-muted'>
                  Una vez que alguna de las dos partes termine el contrato, el usuario Afiliado no podrá seguir manteniendo el derecho de recibir comisiones por los clientes referidos, sin embargo,
                  tendrá responsabilidad absoluta de todas las operaciones que se ejecuten hasta el día de rescisión. A este parecer, el usuario Afiliado estará obligado a devolver todos los
                  materiales promocionales que haya recibido, y a abstenerse de utilizar las marcas o cualquier derecho de propiedad intelectual que haya utilizado durante el periodo contractual
                  por razón a las expuestas Condiciones Generales. De esta forma, tendrá que devolver inmediatamente toda la información confidencial a la que haya tenido acceso en virtud de las
                  Condiciones Generales expuestas y deberá abstenerse indefinidamente de divulgar o publicar dicha información. No solo con eso, el usuario Afiliado no utilizará ni las Herramientas
                  ni ninguna información recibida de Vipealo como, de forma enunciativa y no limitativa, información, contenido, número de opiniones, opiniones de usuarios, descripciones o títulos
                  de actividades, imágenes, textos de cualquier clase, popularidad, comisiones, descuentos o precios de los que el Sitio Web dispone.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>6.- Supresión de las Condiciones Generales.</h1>
                <p className='text-muted'>
                  Las expuestas Condiciones Generales podrán ser finalizadas en el momento que sea bajo alguno de los siguientes supuestos:
                </p>
                <p className='text-muted'>
                  (I) que las partes se pongan de mutuo acuerdo.
                </p>
                <p className='text-muted'>
                  (II) con relación a Vipealo si en un periodo de seis meses el usuario Afiliado no refiere ningún nuevo cliente.
                </p>
                <p className='text-muted'>
                  (III) si Vipealo acredita que el/los sitio/s web insertan, a su parecer, contenidos inadecuados que puedan herir la sensibilidad o algún tipo de derecho personal reconocido
                  por la ley vigente.
                </p>
                <p className='text-muted'>
                  De esta forma, las que, a grandes rasgos, violen los derechos fundamentales de una persona o promocionen las actividades ilegales, ya sea a nivel colectivo o individual por ejemplo
                  las que incorporen pornografía u otros actos sexuales de índole ilegal, las que promocionen la violencia o la discriminación racial, aquellas que vayan dirigidas a niños y/o
                  menores de edad, las que motiven la xenofobia, discriminen la incapacidad física, discriminen la orientación sexual etc. Esto incluye el uso indebido de las Herramientas o el
                  incumplimiento del usuario Afiliado de alguna de las obligaciones expuestas en las presentes Condiciones Generales.
                </p>
                <p className='text-muted'>
                  La Supresión de las Condiciones Generales expuestas tendrá de forma enunciativa y no limitativa las siguientes consecuencias:
                </p>
                <p className='text-muted'>
                  (I) la Cuenta de Afiliado tendrá una anulación automática y definitiva.
                </p>
                <p className='text-muted'>
                  (II) abono de las comisiones a cobrar, si fuera el caso, sin importar su cuantía.
                </p>
                <p className='text-muted'>
                  (III) la pérdida del derecho a volver a cobrar las comisiones de los clientes referidos hasta el momento.
                </p>
                <p className='text-muted'>
                  (IV) la obligación de quitar de su/s sitio/s web cualquier medio promocional de Vipealo teniendo el usuario Afiliado la obligación de abstenerse, a partir de ese momento, de usar
                  cualquier derecho de propiedad o marca que haya utilizado con anterioridad a razón de las Condiciones Generales aquí expuestas.
                </p>
                <p className='text-muted'>
                  (V) la suspensión del uso de las Herramientas y de la información recibida de parte de Vipealo que sea utilizada hasta el momento (opiniones, contenido, imágenes…). De este modo
                  también de forma inmediata, será devuelta la información confidencial a la que haya tenido acceso.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>7.- Responsabilidad del afiliado y exclusión de garantías.</h1>
                <p className='text-muted'>
                  (I) El usuario Afiliado deberá responsabilizarse de que su/s Sitio/s Web cumpla tanto con la legislación como con la normativa vigente y sobre todo, con la que ayude a proteger
                  los derechos relativos al consumidor, como son el derecho a la intimidad, a la imagen y los relacionados con los datos personales.
                </p>
                <p className='text-muted'>
                  (II) El usuario Afiliado se hará cargo exclusivamente del mantenimiento y buen funcionamiento del contenido de su/s sitio/s web.
                </p>
                <p className='text-muted'>
                  (III) La responsabilidad de mostrar la información actualizada y al día con las Herramientas será del usuario Afiliado. Si por alguna razón se hicieran reservas con datos erróneos
                  donde el cliente tenga la opción de reclamar su devolución, Vipealo podrá solicitar al Afiliado la contraprestación económica de la devolución.
                </p>
                <p className='text-muted'>
                  Salvo las situaciones de acción negligente, la responsabilidad ecónomica contractual, extracontractual o de cualquier tipo de Vipealo, estará limitada a la totalidad de la
                  cantidad pagada como comisiones por clientes referidos cubiertas por las Condiciones Generales expuestas.
                </p>
                <p className='text-muted'>
                  El usuario Afiliado se verá obligado a mantener indemne a Vipealo en relación con la vulneración de las presentes Condiciones generales y/o la legislación aplicable a este
                  Acuerdo que causen cualquier daño, penalización, reclamación, responsabilidad y/o gastos.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>8.- Partes vinculadas.</h1>
                <p className='text-muted'>
                  Nada de lo descrito en las Condiciones Generales expuestas se interpretará como el establecimiento de una relación laboral entre Vipealo y el Afiliado, tipo empleado y empresario.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>9.- Protección y modificación de las Condiciones Generales.</h1>
                <p className='text-muted'>
                  En el caso de que algún apartado del Acuerdo expuesto se declarara improductivo o nulo, los términos acordados en los demás apartados no se alterarán. En cuanto a la parte
                  afectada, se entenderá como no existente y habrá de ser redactada de nuevo con los términos que mejor reflejen el objetivo inicial perseguido.
                </p>
                <p className='text-muted'>
                  Vipealo tendrá derecho a hacer los cambios que considere pertinentes en las Condiciones Generales expuestas. Estas modificaciones deberán ser publicadas en el Sitio Web
                  y se tendrán que avisar de forma directa mediante un correo electrónico a la dirección proporcionada por el usuario Afiliado en la solicitud de registro. Pueden incluir,
                  pero no se limitan a, cambios en los porcentajes de comisión, métodos de cálculo y reglas del programa de membresía.
                </p>
                <p className='text-muted'>
                  Una vez estén notificados los cambios, el usuario Afiliado tendrá la opción de finalizar el contrato con Vipealo, haciendo presente que no está de acuerdo con los nuevos
                  cambios y su contenido. Terminada la relación contractual, Vipealo solo tendrá que abonarle cualquier pago pendiente en relación con los términos de las Condiciones
                  Generales expuestas.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>10.- Cookies y protección de datos.</h1>
                <h1 className='mb-3'>10.1 Protección de datos.</h1>
                <p className='text-muted'>
                  Ambas partes se comprometen a cumplir con el Reglamento 2016/679 del Parlamento Europeo y del Consejo (UE) de 27 de abril de 2016, que regula la protección de las personas
                  físicas en el tratamiento de datos personales y la libre circulación de estos datos, y adopta El 5 de diciembre sobre protección de datos personales y se derogaron la
                  Directiva 95/46 / CE (Reglamento General de Protección de Datos) y la Ley Orgánica 3/2018 de protección de los derechos digitales. Los usuarios miembros garantizan que
                  su sitio web se adapta a esta normativa.
                </p>
                <h1 className='mb-3'>10.2 Cookies.</h1>
                <p className='text-muted'>
                  El usuario Afiliado toma el compromiso de no incumplir la normativa que se le aplica a la relación con el uso de cookies en el contenido publicado en su/s Sitio/s web.
                  A esta razón, el usuario Afiliado se verá obligado tanto a informar como a requerir el consentimiento de los usuarios de su/s Sitio/s web en relación de la analítica y/o
                  la publicidad.
                </p>
                <p className='text-muted'>
                  El usuario Afiliado dispensará a Vipealo de todas las sanciones o reclamaciones derivadas del incumplimiento de las Leyes de Servicios de la Sociedad de la Información
                  y Comercio electrónico (LSSI), el Reglamento General de Protección de Datos o cualquier otra norma complementaria.
                </p>
                <p className='text-muted'>
                  La obtención del consentimiento informado siempre se realizará siguiendo los pasos que la Guía de la Agencia Española de la Protección de Datos determina sobre el uso
                  de las cookies.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>11.- Derechos de Propiedad Intelectual.</h1>
                <p className='text-muted'>
                  Vipealo como propietario tiene la licencia de utilizar la totalidad de los Derechos de Propiedad Intelectual y cualquiera que estén relacionados con el servicio de
                  Vipealo. A favor de lo expuesto en las Condiciones Particulares presentes, el usuario Afiliado no recibe ningún derecho ni licencia más allá del de integrar las
                  Herramientas, banners, enlaces, widgets o cualquier otro recurso en los sitios webs recibidos por parte de Vipealo.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>12.- Confidencialidad.</h1>
                <p className='text-muted'>
                  La totalidad de la información sea cual sea el formato o el medio que las Partes compartan, deberá ser tratada por la Parte receptora con confidencialidad absoluta.
                  No podrá hacerse uso de ella para otros fines que no sean los acordados en las Condiciones Generales expuestas, ni podrá ser difundida, entregada o cedida a ningún
                  otro usuario. Solo estarán exentos los requerimientos emitidos por una autoridad judicial o administrativa que vengan respaldados por la legalidad. Este compromiso
                  será mantenido tras la finalización del contrato de forma inalterable.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>13.- Extinción de derechos y obligaciones.</h1>
                <p className='text-muted'>
                  El usuario Afiliado no podrá ceder su posición expuesta en el contrato sin haber recibido previamente el visto bueno y de manera escrita de Vipealo. Sin embargo,
                  Vipealo sí tendrá la opción de ceder total o parcialmente los derechos y obligaciones que ostente en relación con las Condiciones Generales expuestas, haciéndole
                  llegar por escrito al Afiliado el comunicado.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>14.- Comunicaciones.</h1>
                <p className='text-muted'>
                  Todas las comunicaciones que se realicen entre ambas partes deberán de ser por medios en los que la recepción y el contenido quede patente, como el correo electrónico.
                </p>
              </div>
            </div>
            <div className='mb-4'>
              <div className='media-body'>
                <h1 className='mb-3'>15.- Jurisdicción competente y Ley aplicable.</h1>
                <p className='text-muted'>
                  Ambas partes se supeditan de forma expresa a la jurisdicción de los Juzgados y Tribunales de la ciudad de Madrid. El Acuerdo expuesto se rige por la legislación española.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({ user: state.user })

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: (data) => dispatch({ data, type: actionTypes.GET_SETTINGS })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConditions)
