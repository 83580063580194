export const ADMIN_LOGIN_REQUEST = 'ADMIN_LOGIN_REQUEST'
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGOUT = 'LOGOUT'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_CANCELLED = 'LOGIN_CANCELLED'
export const STORE_TOKEN = 'STORE_TOKEN'
export const MARK_NOTIFICATION = 'MARK_NOTIFICATION'
export const STORE_NOTIFICATION = 'STORE_NOTIFICATION'
export const DELETE_TOKEN = 'DELETE_TOKEN'
export const STORE_USER = 'STORE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const CREATE_USER = 'CREATE_USER'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'
export const PASSWORD_REQUEST = 'PASSWORD_REQUEST'
export const PASSWORD_REQUEST_SUCCESS = 'PASSWORD_REQUEST_SUCCESS'
export const PASSWORD_REQUEST_ERROR = 'PASSWORD_REQUEST_ERROR'
export const PASSWORD_CONFIRM_CODE = 'PASSWORD_CONFIRM_CODE'
export const PASSWORD_CONFIRM_CODE_SUCCESS = 'PASSWORD_CONFIRM_CODE_SUCCESS'
export const PASSWORD_CONFIRM_CODE_ERROR = 'PASSWORD_CONFIRM_CODE_ERROR'
export const PASSWORD_UPDATE = 'PASSWORD_UPDATE'
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD_UPDATE_SUCCESS'
export const PASSWORD_UPDATE_ERROR = 'PASSWORD_UPDATE_ERROR'
export const EMAIL_REQUEST = 'EMAIL_REQUEST'
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS'
export const EMAIL_ERROR = 'EMAIL_ERROR'
export const EVALUATE_USER = 'EVALUATE_USER'
export const SETTINGS_ERROR = 'SETTINGS_ERROR'
export const GET_SETTINGS = 'GET_SETTINGS'
export const STORE_DEFAULT_SETTINGS = 'STORE_DEFAULT_SETTINGS'
export const STORE_DEFAULT_SETTINGS_ERROR = 'STORE_DEFAULT_SETTINGS_ERROR'

export default {
  ADMIN_LOGIN_REQUEST,
  CREATE_USER,
  CREATE_USER_ERROR,
  DELETE_TOKEN,
  EMAIL_ERROR,
  EMAIL_REQUEST,
  EMAIL_SUCCESS,
  EVALUATE_USER,
  GET_SETTINGS,
  LOGIN_CANCELLED,
  LOGIN_ERROR,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  MARK_NOTIFICATION,
  PASSWORD_CONFIRM_CODE,
  PASSWORD_CONFIRM_CODE_ERROR,
  PASSWORD_CONFIRM_CODE_SUCCESS,
  PASSWORD_REQUEST,
  PASSWORD_REQUEST_ERROR,
  PASSWORD_REQUEST_SUCCESS,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_ERROR,
  PASSWORD_UPDATE_SUCCESS,
  SETTINGS_ERROR,
  STORE_DEFAULT_SETTINGS,
  STORE_DEFAULT_SETTINGS_ERROR,
  STORE_NOTIFICATION,
  STORE_TOKEN,
  STORE_USER,
  UPDATE_USER,
  UPDATE_USER_ERROR,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS
}
