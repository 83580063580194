import actionTypes from '../actions/actionTypes'
import statusTypes from '../statusTypes'
import initialState from './initialState'

export default function user(state = initialState.user, action) {
  let newState
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      newState = { ...state, status: statusTypes.LOGIN_REQUEST }

      return newState
    case actionTypes.LOGIN_SUCCESS:
      newState = { ...state, status: statusTypes.LOGGED_IN }

      return newState
    case actionTypes.STORE_TOKEN:
      newState = { ...state, token: action.token }
      newState.token = action.token

      return newState
    case actionTypes.STORE_NOTIFICATION:
      newState = { ...state, notifications: action.notifications }
      newState.notifications = action.notifications

      return newState
    case actionTypes.STORE_USER:
      const { affiliate } = action.user
      if(!affiliate) action.user.affiliate = initialState.user.affiliate
      else if(!affiliate.bill) action.user.affiliate.bill = initialState.user.affiliate.bill

      newState = { ...state, ...action.user, status: statusTypes.STORE_USER_SUCCESS }

      return newState
    case actionTypes.UPDATE_USER_SUCCESS:
      newState = { ...state, ...action.user, error: '', status: statusTypes.UPDATE_USER_SUCCESS }
      newState.user = action.user

      return newState
    case actionTypes.UPDATE_USER_ERROR:
      newState = { ...state, error: action.error, status: statusTypes.UPDATE_USER_ERROR }

      return newState

    case actionTypes.CREATE_USER:
      newState = { ...state, ...action.user, status: statusTypes.CREATE_USER_SUCCESS }

      return newState

    case actionTypes.CREATE_USER_ERROR:
      newState = { ...state, error: action.error, status: statusTypes.CREATE_USER_ERROR }

      return newState

    case actionTypes.DELETE_TOKEN:
      newState = { ...state, ...initialState.user }

      return newState
    case actionTypes.LOGOUT:
      newState = { status: statusTypes.LOGGED_OUT }

      return newState
    case actionTypes.LOGIN_ERROR:
      newState = { ...state, error: action.error, status: statusTypes.LOGIN_ERROR }

      return newState
    case actionTypes.LOGIN_CANCELLED:
      newState = { ...state, status: statusTypes.LOGIN_CANCELLED }

      return newState
    case actionTypes.PASSWORD_REQUEST:
      newState = { ...state, status: statusTypes.PASSWORD_REQUEST }

      return newState
    case actionTypes.PASSWORD_REQUEST_SUCCESS:
      newState = { ...state, status: statusTypes.PASSWORD_REQUEST_SUCCESS }
      newState.confirmCode = action.confirmCode

      return newState
    case actionTypes.PASSWORD_REQUEST_ERROR:
      newState = { ...state, status: statusTypes.PASSWORD_REQUEST_ERROR }

      return newState
    case actionTypes.PASSWORD_CONFIRM_CODE:
      newState = { ...state, status: statusTypes.PASSWORD_CONFIRM_CODE }

      return newState
    case actionTypes.PASSWORD_CONFIRM_CODE_SUCCESS:
      newState = { ...state, status: statusTypes.PASSWORD_CONFIRM_CODE_SUCCESS }

      return newState
    case actionTypes.PASSWORD_CONFIRM_CODE_ERROR:
      newState = { ...state, status: statusTypes.PASSWORD_CONFIRM_CODE_ERROR }

      return newState
    case actionTypes.PASSWORD_UPDATE:
      newState = { ...state, status: statusTypes.PASSWORD_UPDATE }

      return newState
    case actionTypes.PASSWORD_UPDATE_SUCCESS:
      newState = { ...state, status: statusTypes.PASSWORD_UPDATE_SUCCESS }

      return newState
    case actionTypes.PASSWORD_UPDATE_ERROR:
      newState = { ...state, status: statusTypes.PASSWORD_UPDATE_ERROR }

      return newState
    case actionTypes.EMAIL_REQUEST:
      newState = { ...state, status: statusTypes.EMAIL_REQUEST }

      return newState
    case actionTypes.EMAIL_SUCCESS:
      newState = { ...state, status: statusTypes.EMAIL_SUCCESS }

      return newState
    case actionTypes.EMAIL_ERROR:
      newState = { ...state, status: statusTypes.EMAIL_ERROR }

      return newState
    case actionTypes.EVALUATE_USER:
      newState = { ...state, affiliate: { ...state.affiliate }, status: statusTypes.EVALUATE_USER_SUCCESS }
      const { affiliate: { percent: storedPercent, freetour: storedFreetour } } = state
      const { affPercent, affFreetour, afiliados_free_tour, afiliados  } = action
      if(affPercent && affPercent !== storedPercent) newState.affiliate.percent = affPercent
      if(storedFreetour && storedFreetour !== affFreetour) newState.affiliate.freetour = affFreetour
      newState.settings = { afiliados, afiliados_free_tour }

      return newState
    case actionTypes.STORE_DEFAULT_SETTINGS:
      newState = { ...state, default_settings: action.default_settings, status: statusTypes.DEFAULT_SETTINGS_STORED }
      newState.default_settings = action.default_settings

      return newState
    default:
      return state
  }
}
