import React from 'react'
import { slugify, getFirstPrice, getOffer } from '../../utils'

const Lateral1 = ({ activity, aid, cmp, col = 4 }) => {
  return (
    <div className={`col-sm-${col}`}>
      <a
        href={`https://vipealo.com/${slugify(activity.country.name)}/${slugify(activity.city.name)}/${slugify(activity.title)}/?aid=${aid}&cmp=${cmp}`} key={activity._id} rel='noopener noreferrer'
        target='_blank' >
        <article className='text-left item' key={activity._id}>
          <div className='item-header' style={{ backgroundImage: `url(${ activity.thumbnail })` }}>
            <span className='pl-1 pr-1'><i className='icon-c clock-white' />Imprescindible</span>
          </div>
          <div className='p-2 item-body'>
            <div className>
              <p className='pb-1 font-weight-bold'>{ activity.title }</p>
              <div className='price text-right'>
                <span className='font-weight-bold'>{ getOffer(activity) ? getOffer(activity) + ' €' : ''  }</span>
                <span className='font-weight-bold'>{`${!getFirstPrice(activity.prices) ? '--- €' : getFirstPrice(activity.prices) === 0 ? 'FREE' : getFirstPrice(activity.prices).toFixed(2) + ' €' }`}</span>
              </div>
            </div>
          </div>
        </article>
      </a>
    </div>
  )
}

export default Lateral1
