import React, { useEffect, useState } from 'react'
import Container from '..'
import { useDispatch, useSelector } from 'react-redux'
import { changeMonth } from '../../../reducers/reservations'
import moment from 'moment-timezone'
import Spinner from '../../../components/Spinner'
import Error from '../../../components/Error'
import { getPeoplePrice, getRate, childCheck } from  '../../../utils'
import { Modal } from 'react-bootstrap'
import Pagination from '../../../components/Pagination'
const { REACT_APP_API } = process.env

const BillingAffiliate = () => {
  const dispatch = useDispatch()
  const {
    data: { docs: reservations },
    currencies,
    defaultFreeTour,
    myLastPayment,
    month,
    loading,
    error,
    tableLoading,
    // totalToBilling,
    // quantFreetour,
    subTotal
  } = useSelector(state => state.reservations)
  const { affiliate: { percent, freetour = 1, bill } } = useSelector(state => state.user)

  const [ affiliateCurrency ] = useState(bill && bill.currency ? bill.currency : 'EUR')
  // const [ toBilling, setToBilling ] = useState(0)
  // const [ totalPage, setTotalPage ] = useState(0)
  // const [ totalCommissionPage, setTotalCommissionPage ] = useState(0)
  const [ showModal, setShowModal ] = useState(false)

  const _handleMonth = (month) => {
    dispatch(changeMonth(month))
  }

  // useEffects
  // useEffect(() => {
  //   const tempToBilling = (totalToBilling * percent / 100) + (quantFreetour * defaultFreeTour)
  //   setToBilling(tempToBilling)
  // }, [ totalToBilling, quantFreetour, freetour, percent, defaultFreeTour ])

  useEffect(()=>{
    if(!month)
      _handleMonth(moment().format('yyyy-MM'))
    else _handleMonth(month)
    // eslint-disable-next-line
  }, [])

  // useEffect(() => {
  //   let [ subTotal, subFreetour ] = getTotalFreetour(reservations)
  //   let tempTotalComm = (subTotal * percent / 100) + (subFreetour * freetour)
  //   setTotalPage(Number(subTotal).toFixed(2))
  //   setTotalCommissionPage(Number(tempTotalComm).toFixed(2))
  //   // eslint-disable-next-line
  // }, [ reservations, percent, freetour ])

  const getPeople = (priceGroups = [], total = 0) => {
    let people = 0
    // Get people, dont sum childs if coming from freetour
    for (let pg of priceGroups)
      for (let pre of pg.prices)
        if(!childCheck(pre.name) && total === 0) people += pre.people
        else if(total !== 0) people += pre.people

    return people
  }

  return (
    <Container>
      <div className='main-content reserves'>
        <div className='page-content'>
          <div className='row'>
            <div className='col-12'>
              <div className='page-title-box d-flex align-items-center justify-content-between'>
                <h4 className='page-title mb-0 font-size-18'>Facturación</h4>
              </div>
            </div>
          </div>
          { error ? <Error code={500} type={'server error'} /> : loading ?
            <Spinner />          :
            (<div>
              <div className='date-filter row mb-3'>
                <div className='col-md-3'>
                  <input
                    className='form-control'
                    max={moment().format('yyyy-MM')}
                    min={moment().format('2020-07')}
                    onChange={({ target: { value } }) => _handleMonth(value)}
                    type='month'
                    value={month} />
                </div>
                <div className='col-md-8 text-right' />
              </div>
              <div className='row'>
                <div className='col-xl-3'>
                  <div className='card top-card'>
                    <div className='card-body'>
                      <div className='media'>
                        <div className='avatar-sm font-size-20 mr-3'>
                          <span className='avatar-title bg-soft-green text-primary rounded'>
                            <i className='fa fa-list text-soft-green' />
                          </span>
                        </div>
                        <div className='media-body'>
                          <div className='font-size-16 mt-2'><span>Total de comisiones para facturar</span></div>
                        </div>
                      </div>
                      <h4 className='mt-4 h4-c wh-sp-nw'>{ `${subTotal.toFixed(2)} ${affiliateCurrency}` }</h4>
                      <div className='row'>
                        <div>
                          <p className='mb-0'><span className='mr-2'> </span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3'>
                  <div className='card top-card'>
                    <div className='card-body'>
                      <div className='media'>
                        <div className='avatar-sm font-size-20 mr-3'>
                          <span className='avatar-title bg-soft-blue text-primary rounded'>
                            <i className='fa fa-money text-soft-blue' />
                          </span>
                        </div>
                        <div className='media-body'>
                          <div className='font-size-16 mt-2'><span>Último pago emitido</span></div>
                        </div>
                      </div>
                      <h4 className='mt-4 h4-c wh-sp-nw'>{ myLastPayment }</h4>
                      <div className='row'>
                        <div>
                          <p className='mb-0'><span className='mr-2'> </span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-xl-3 pb-0 c-pointer' id='col-modal'>
                  <div
                    className='card top-card bg-bluev text-white'
                    onClick={() => setShowModal(true)}>
                    <div className='card-body'>
                      <div className='media'>
                        <div className='avatar-sm font-size-20 mr-3'>
                          <span className='avatar-title bg-soft-gray text-primary rounded'>
                            <i aria-hidden='true' className='fa fa-question font-size-35 '></i>
                          </span>
                        </div>
                        <div className='media-body'>
                          <div className='font-size-16 mt-2'><h4 className='text-soft-gray'>¿Dudas con tus facturas?</h4></div>
                        </div>
                      </div>
                      <h6 className='mt-4 text-soft-gray'>¡Clickea aquí para resolverlas!</h6>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='card'>
                  <div className='card-body'>
                    <div className='table-responsive min-height-250'>
                      { tableLoading ? <Spinner /> : <>
                        <table className='table allow-order mb-0 min-height-250'>
                          <thead>
                            <tr>
                              <th className='no-wrap'>Fecha</th>
                              <th className='no-wrap'>Reserva</th>
                              <th className='no-wrap'>Estado</th>
                              <th className='no-wrap'>Personas</th>
                              <th className='no-wrap'>Actividad</th>
                              <th className='no-wrap order'>Asistió</th>
                              <th className='no-wrap order'>Cupón</th>
                              <th className='no-wrap order order-dsc'>Importe</th>
                              <th className='no-wrap order'>Comisión</th>
                              <th className='no-wrap order'>Opciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reservations.map((reservation) => {
                              const {
                                _id, affiliate: { _id: affiliateId }, affiliateBill,
                                details: { activity, priceGroups, serviceReservationId, userAttended },
                                createdAt, paidAffiliate, paidAffiliateFile, paymentInformation, total, currency, cancelled
                              } = reservation
                              let rTotal = total, cTotal = 0, activityPercent = 1, people = getPeople(priceGroups, total)
                              const { agency } = activity
                              if(agency && agency.contract && agency.contract.type === 'percent' && agency.contract.amount) activityPercent = agency.contract.amount
                              let currentPercent = activityPercent && activityPercent <= percent ? activityPercent : percent
                              const url = affiliateBill && affiliateId ? `${REACT_APP_API}/affiliates/billing/${affiliateBill}/${affiliateId}/pdf` : null
                              if(currencies && affiliateCurrency) rTotal = (total * getRate(currencies, currency, affiliateCurrency)).toFixed(2) || 0
                              cTotal = total !== 0 ? Number((rTotal) * currentPercent / 100).toFixed(2) : (userAttended ? defaultFreeTour * (freetour * (people ? people : 1)) : 0).toFixed(2)

                              return (
                                <tr className={cancelled ? 'text-red' : !serviceReservationId ? 'text-danger' : ''} key={_id}>
                                  <td>{ moment(createdAt).format('DD-MM-YYYY') }</td>
                                  <td>{serviceReservationId ? serviceReservationId : 'Pendiente' }</td>
                                  <td className={cancelled ? 'text-danger' : ''}>{ cancelled ? 'Cancelada' : paidAffiliate ? 'Pagada' : 'Pendiente'}</td>
                                  <td>{ Number(getPeoplePrice(priceGroups)[0]) }</td>
                                  <td>{ activity && activity.huno ? activity.huno : 'Pendiente...'}</td>
                                  <td className={!userAttended && total === 0 ? 'text-danger' : ''}>{ total !== 0 ? 'N/A' : userAttended ? 'Si' : 'No' }</td>
                                  <td>{ paymentInformation && paymentInformation.applyCoupon && paymentInformation.coupon ? paymentInformation.coupon.name : 'N/A' }</td>
                                  <td>{ rTotal + ` ${affiliateCurrency}`}</td>
                                  <td>{ cTotal + ` ${affiliateCurrency}`}</td>
                                  <td>
                                    {paidAffiliate && paidAffiliateFile ?
                                      <a
                                        className={paidAffiliate && paidAffiliateFile ? 'btn btn-primary' : 'btn btn-primary disabled'} href={paidAffiliateFile} rel='noopener noreferrer'
                                        role='button' target='_blank'><i className='fa fa-list-alt' /> Justificante</a> :
                                      null
                                    }
                                    <a
                                      className={`btn btn-primary ${cancelled || !url ? 'disabled' : ''}`} href={url} rel='noopener noreferrer'
                                      role='button' style={{ marginLeft: '5px' }} target='_blank'><i className='fa fa-list-alt' /> Autofactura</a>
                                  </td>
                                </tr>
                              )
                            })}
                          </tbody>
                          {/* <tfoot className='pt-fr'>
                            <tr className='table-red-light'>
                              <td className='font-weight-bolder' colSpan={2}>TOTAL</td>
                              <td className='font-weight-bolder'>{ totalPage }</td>
                              <td className='font-weight-bolder' colSpan={2}>{ totalCommissionPage }</td>
                              <td className='font-weight-bolder'></td>
                            </tr>
                          </tfoot> */}
                        </table>
                        { reservations && reservations.length ? <Pagination /> : null }
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
      <Modal
        aria-labelledby='example-modal-sizes-title-lg'
        onHide={() => setShowModal(false)}
        show={showModal}
        size='lg'>
        <Modal.Header closeButton>
          <Modal.Title id='example-modal-sizes-title-lg'>
            <h4 className='text-bluev text-center'>
              Resuelve tus dudas
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-bluev mb-3'>
            ¿Cuál es la forma en la que puedo cobrar mis comisiones y facturar?
          </h4>
          <p className='text-muted font-size-16'>
            Con la autofactura activa solo tienes que asegurarte de marcar la opción en el apartado <a className='link-appearance' href='/afiliados/datos-de-pago'>Mis datos de pago</a>.
            Revisa que estén perfectamente completados tanto los datos de facturación, como la forma de pago. Cuando este
            toda la información actualizada se generará una autofactura automática. Una vez terminado el proceso, te llegará una
            notificación al correo lo que te permitirá darle seguimiento a tu autofactura en la sección <a className='link-appearance' href='/afiliados/facturacion'>Facturación</a>:
          </p>
          <p className='text-muted font-size-16'>
            No olvides que el pago de las comisiones es mensual y llega bajo las siguientes condiciones:
          </p>
          <ul>
            <li>
              <p className='text-muted font-size-16'>
                La autofactura es automática y se genera el día 15 de cada mes.
              </p>
            </li>
            <li>
              <p className='text-muted font-size-16'>
                De forma independiente a la fecha de la reserva, se incluyen todas las comisiones de los servicios realizados el mes anterior
              </p>
            </li>
            <li>
              <p className='text-muted font-size-16'>
                Si no se alcanza un mínimo de 50€, no se genera autofactura y las comisiones se acumulan para el mes siguiente.
              </p>
            </li>
          </ul>
          <p className='text-muted font-size-16'>
            Si aún tienes dudas de la autofactura o simplemente quieres seguir enviando personalmente tus facturas
            contáctanos a <a className='link-appearance' href={`mailto:${process.env.REACT_APP_EMAIL_AFFFILIATE}?subject=Ayuda%20afiliado`}> afiliados@vipealo.com </a>.
          </p>
          <p className='text-muted font-size-16'>
             Nuestros datos de facturación son los siguientes:
          </p>
          <ul>
            <li>
              <p className='text-muted font-size-16'>
                Denominación o razón social: <span className='text-bluevip'> Vipealo S.L NIF:B88579404</span>
              </p>
            </li>
            <li>
              <p className='text-muted font-size-16'>
                Domicilio fiscal: <span className='text-bluevip'>Calle de José de Abascal 56 Segunda Planta despacho 200 Madrid - (MADRID)</span>
              </p>
            </li>
          </ul>
          <h4 className='text-muted font-size-20 mt-3 mb-3 text-bluev'>
            ¿Qué pasa si no tengo factura?
          </h4>
          <p className='text-muted font-size-16'>
            La factura es necesaria para declarar los pagos recibidos como empresa. Por eso es necesario que nos la envíes.
            Sabemos que los regímenes fiscales varían dependiendo del afiliado por lo que nuestro consejo es que te asesores para que puedas presentarnos un documento que refleje tu actividad fiscal.
          </p>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default BillingAffiliate
